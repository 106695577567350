<div class="modal-header">
  <h4 class="modal-title">Manage Qualifiers</h4>
  <button type="button" class="close" aria-label="Close" (click)="onCloseModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-6">
      <h6>Available Qualifiers</h6>
    </div>
    <div class="col-6">
      <h6>Added Qualifiers</h6>
    </div>
  </div>
  <div class="row p-2" style="height: 300px; overflow: scroll">
    <div class="col-6">
      <ul class="list-group" *ngFor="let qualifier of availableQualifiers()">
        <li (click)="onAddingQualifier(qualifier)"
            class="list-group-item p-1 mb-1 d-flex justify-content-between align-items-center cursor-pointer">
          {{ qualifier.code }} <i class="fas fa-chevron-right"></i>
        </li>
      </ul>
    </div>
    <div class="col-6">
      <ul class="list-group" *ngFor="let qualifier of inUsedQualifiers">
        <li
          (click)="onRemovingQualifier(qualifier)"
          class="list-group-item p-1 mb-1 d-flex justify-content-between align-items-center cursor-pointer">
          {{ qualifier }} <i class="fas fa-chevron-left"></i>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-att" (click)="onApplyChanges(inUsedQualifiers)">Apply Changes</button>
</div>
