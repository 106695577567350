/*
 * =============================================================================
 * Vrio Corp. PROPRIETARY Copyright© 2021 Vrio Corp. UNPUBLISHED WORK ALL RIGHTS
 * RESERVED
 *
 * This software is the confidential and proprietary information of Vrio Corp.
 * ("Proprietary Information"). Any use, reproduction, distribution or
 * disclosure of the software or Proprietary Information, in whole or in part,
 * must comply with the terms of the license agreement, nondisclosure agreement
 * or contract entered into with Vrio Corp. providing access to this software.
 *
 * ============================================================================
 */

import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import * as moment from 'moment'
import {ConfirmationModalComponent} from 'src/app/components/confirmation-modal/confirmation-modal.component'
import {ScheduleCopyService} from './schedules-copy.service'
import {AppConstants} from '../../app.constants'
import {OFFSET_OPTIONS} from "../../utils/contants";
import {Offset} from "../schedules/schedule";
import {ScheduleCopy} from "./schedule-copy";
import {environment} from "../../../environments/environment";
import {DateTimeAdapter, OWL_DATE_TIME_FORMATS} from 'ng-pick-datetime'
import { NATIVE_FORMATS_BULK_COPY } from 'src/app/services/calendar.service'

@Component({
  selector: 'app-schedules-copy',
  templateUrl: './schedules-copy.component.html',
  styleUrls: ['./schedules-copy.component.scss'],
  providers: [
    {provide: OWL_DATE_TIME_FORMATS, useValue: NATIVE_FORMATS_BULK_COPY},
],
})
export class SchedulesCopyComponent implements OnInit, OnChanges {
  channelList: any[]
  bulkCopy: ScheduleCopy
  showLTZ: boolean
  offsetOptions: Offset[]
  readonly CONSTANTS = AppConstants
  DEFAULT_TIMEZONE = environment.timezone ? OFFSET_OPTIONS.find(o => o.value === environment.timezone) : OFFSET_OPTIONS[0];

  constructor(
    private scheduleCopyService: ScheduleCopyService,
    private modalService: NgbModal,
    private dateTimeAdapter: DateTimeAdapter<any>
  ) {
    this.dateTimeAdapter.setLocale('en-IN');
  }

  /**
   * Init component.
   */
  ngOnInit(): void {
    this.channelList = []
    this.bulkCopy = this.scheduleCopyService.bulkCopyDefaults() as any
    this.getAllChannels()
    this.offsetOptions = OFFSET_OPTIONS;
    this.bulkCopy.timeZone = this.DEFAULT_TIMEZONE;
  }

  /**
   * Change component.
   */
  ngOnChanges(changes: SimpleChanges) {
    this.resetDefaults()
  }

  /**
   * Reset validation.
   */
  resetDefaults() {
    /* Reset form validation */
    const invalidInputs = document.querySelectorAll('.is-invalid')
    invalidInputs.forEach((input) => input.classList.remove(AppConstants.INVALID_FIELD_INPUT))
  }

  /**
   * Get all channels.
   */
  getAllChannels() {
    // call api get all channels
    this.scheduleCopyService.getAllChannels().subscribe((data) => {
      this.channelList = data.response.map(channel => {
        return {
          id: channel.id,
          channelName: channel.channelNames.find(c => c.type.toLowerCase() === 'short')?.value,
          channelNameFull: channel.channelNames
        }
      }).sort(function (a, b) {
        if (a.channelName < b.channelName) {
          return -1
        }
        if (a.channelName > b.channelName) {
          return 1
        }
        return 0
      })
    })
  }

  /**
   * Handle selected channel.
   *
   * @param event selected channel value
   * @param id original channel or destination channel
   */
  channelOnChange(event, id) {
    //handle original channel and destination channel selection
    if (event != undefined) {
      if (id == AppConstants.ORIGINAL_CHANEL_NAME) {
        this.bulkCopy[AppConstants.ORIGINAL_CHANEL_ID] = event.id
        this.bulkCopy[AppConstants.ORIGINAL_CHANEL_NAME] = event.channelNameFull
      } else {
        this.bulkCopy[AppConstants.DESTINATION_CHANEL_ID] = event.id
        this.bulkCopy[AppConstants.DESTINATION_CHANEL_NAME] = event.channelNameFull
      }
      document.getElementById(id).classList.remove(AppConstants.INVALID_FIELD_INPUT)
    } else {
      if (id == AppConstants.ORIGINAL_CHANEL_NAME) {
        this.bulkCopy[AppConstants.ORIGINAL_CHANEL_ID] = ''
        this.bulkCopy[AppConstants.ORIGINAL_CHANEL_NAME] = []
      } else {
        this.bulkCopy[AppConstants.DESTINATION_CHANEL_ID] = ''
        this.bulkCopy[AppConstants.DESTINATION_CHANEL_NAME] = []
      }
      document.getElementById(id).classList.add(AppConstants.INVALID_FIELD_INPUT)
    }
  }

  /**
   * Save schedule bulk copy.
   */
  submitCopySchedules() {
    if (this.isValid()) {
      const modal = this.modalService.open(ConfirmationModalComponent, {
        size: 'lg',
        centered: true,
        backdrop: 'static',
      })
      modal.componentInstance.title = AppConstants.SUBMIT_CHANGES
      modal.componentInstance.message = AppConstants.MESSAGE_CONFIRM
      modal.componentInstance.isAlert = false
      modal.result.then((result) => {
        if (result) {
          const bulkCopyDTO = {
            id: this.bulkCopy.id,
            origChannelId: this.bulkCopy.origChannelId,
            origChannelNames: this.bulkCopy.origChannelNames,
            destChannelId: this.bulkCopy.destChannelId,
            destChannelNames: this.bulkCopy.destChannelNames,
            startDate: moment(this.bulkCopy.startDate).utcOffset(0, true).toISOString(),
            endDate: moment(this.bulkCopy.endDate).utcOffset(0, true).toISOString(),
            destStartDate: moment(this.bulkCopy.destStartDate).utcOffset(0, true).toISOString(),
            numOfCopies: this.bulkCopy.numOfCopies,
            published: this.bulkCopy.published,
            userId: localStorage.getItem('authUserId')
          }
          // call api to handle bulk copy
          let timeZone = 'GMT'
          if (this.bulkCopy.timeZone.value) {
            timeZone = this.bulkCopy.timeZone.value.split(',')[0]
          }
          this.scheduleCopyService.saveBulkCopy(bulkCopyDTO, timeZone).subscribe(
            (response) => {
              this.showAlert('Success', AppConstants.MESSAGE_SUCCESS)
            },
            ({error}) => {
              const errMessage = error?.errors[0] ? error?.errors[0] : AppConstants.MESSAGE_ERROR
              this.showAlert(`Error`, `${errMessage}`)
            }
          )
        }
      })
    }
  }

  /**
   * Validate schedule bulk copy.
   */
  isValid() {
    this.resetDefaults()
    const inValid = []
    const fieldsToValidate = [AppConstants.ORIGINAL_CHANEL_NAME, AppConstants.DESTINATION_CHANEL_NAME, AppConstants.START_DATE, AppConstants.END_DATE, AppConstants.NUMBER_OF_COPY, AppConstants.DESTINATION_START_DATE, AppConstants.PUBLISHED]

    fieldsToValidate.forEach((field) => {
      let message = ''
      let fieldToValidate = this.bulkCopy[field]

      // get channel name
      if (typeof this.bulkCopy[field] === 'object' && field !== AppConstants.END_DATE && field !== AppConstants.START_DATE && field !== AppConstants.DESTINATION_START_DATE) {
        fieldToValidate = this.bulkCopy[field][0]?.value
      }

      if (!fieldToValidate) {
        // validate mandatory fields
        message = 'Required field'
      } else if (field === AppConstants.NUMBER_OF_COPY && (fieldToValidate < 1 || fieldToValidate > 30)) {
        // validate number of copies from 1 to 30
        message = 'The number of copies is from ' + this.CONSTANTS.MIN_OF_COPY + ' to ' + this.CONSTANTS.MAX_OF_COPY
      } else if (field === AppConstants.START_DATE || field === AppConstants.END_DATE) {
        // validate startDate and endDate
        if (moment(this.bulkCopy.startDate).isSameOrAfter(this.bulkCopy.endDate)) {
          message = field === AppConstants.START_DATE ? AppConstants.MESSAGE_INVALID_START_DATE : AppConstants.MESSAGE_INVALID_END_DATE
        }
      }

      if (message !== '') {
        const inputField = document.getElementById(field)
        if (inputField) {
          if (inputField.nextSibling) {
            inputField.parentNode.removeChild(inputField.nextSibling)
          }
          inValid.push(field)
          inputField.insertAdjacentHTML(
            'afterend',
            '<div class="invalid-feedback">' + message + '</div>'
          )
          inputField.classList.add(AppConstants.INVALID_FIELD_INPUT)
        }
      }
    })
    return inValid.length === 0
  }

  /**
   * Handle when input value is changed.
   */
  onFieldChange(event) {
    const {id, value} = event.target
    if (value) {
      document.getElementById(id).classList.remove(AppConstants.INVALID_FIELD_INPUT)
    }
  }

  /**
   * Display alert modal.
   */
  showAlert(type, message) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'sm',
      centered: true,
      backdrop: 'static',
    })
    modalRef.componentInstance.title = type
    modalRef.componentInstance.message = message
    modalRef.componentInstance.isAlert = true
    modalRef.result.then((result) => {
      if (result && type == 'Success') {
        window.location.reload();
      }
    })
  }

  /**
   * Toggle the local timezone display flag
   */
  toggleRenderTimezoneDropdown() {
    this.showLTZ = !this.showLTZ
  }

  /**
   * Handle the change of timezone
   * @param event
   */
  onChangeTimeZone(event) {
    this.bulkCopy.timeZone = OFFSET_OPTIONS.find(x => x.key === event.target.value);
  }

}
