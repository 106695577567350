import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class ScheduleReportService {
    channelApiUrlV2: string 

    constructor(private http: HttpClient) {
        this.channelApiUrlV2 = environment.apiUrl + "/v2/schedules";
    }

    defaultScheduleReportSorted(){
        return [
            {name: 'Date', fieldName:'scheduleDate', isDisplay: true, isAsc: false},
            {name: 'ChannelID', fieldName:'channelId', isDisplay: true, isAsc: null},
            {name: 'Source ChannelID', fieldName:'sourceId', isDisplay: true, isAsc: null}
          ]
    }

    searchUncompletedChannel(searchData){
        return this.http.post<VLSResponse>(
            `${this.channelApiUrlV2}/report`, (searchData)
        );
    }
}