<div class="container-fluid mt-3">
  <div class="page-title">
    <h4>Channel Management</h4>
  </div>
  <div class="row mt-2 p-1" [class.bg-white]="displayTab">
    <div class="col-lg-3">
      <app-channels-list
        targetComponent="service"
        componentName="Service Channel"
        (channel)="onChannelChange($event)"
        [refreshSourceId]="refreshSourceId"
        [refresh]="refresh"
        isChannel = true
      ></app-channels-list>
    </div>
    <div class="col-lg-6">
      <app-channel-panel
        [channel]="serviceChannel"
        [oldChannel]="oldServiceChannel"
        (refreshChannel)="onRefreshChannel($event)"
      ></app-channel-panel>
    </div>
    <div class="col-lg-3">
      <app-channels-list
        targetComponent="provider"
        componentName="Provider Channel"
      ></app-channels-list>
    </div>
  </div>
</div>
