<div class="mb-4" *ngFor="let schedule of schedulesSubject|async; let scheduleIndex = index">
  <div class="row schedule-drag-header">
    <div class="col-4 {{scheduleIndex == 0 ? 'square-schedule-0' : 'square-schedule-1'}}"></div>
    <span class="col-3">{{scheduleIndex == 0 ? 'First Schedule' : 'Second Schedule'}}</span>
    <div class="col-4 {{scheduleIndex == 0 ? 'square-schedule-0' : 'square-schedule-1'}}"></div>
  </div>
  <div class="panel-body schedule-wrapper {{schedulesSubject?.getValue()?.length == 1 ? 'schedule-wrapper-with-one' : 'schedule-wrapper-with-two'}} p-1" id="schedule-{{scheduleIndex}}" cdkDropList
       (cdkDropListDropped)="onDrop(schedule, $event, scheduleIndex)"
       [cdkDropListConnectedTo]="scheduleIndex===0?['schedule-1', 'programList']:['schedule-0', 'programList']">
    <div *ngFor="let event of schedule.events; let eventIndex = index">
      <app-schedule-event [event]="event"
                          [schedule]="schedule"
                          [index]="eventIndex"
                          [scheduleIndex]="scheduleIndex"
                          [nextEvent]="schedule.events[eventIndex + 1]"
                          [isFirstSchedule]="scheduleIndex===0"
                          [ratings]="ratings"
                          [regions]="regions"
                          [qualifiers]="qualifiers"
                          [genres]="genres"
                          [selectedDate]="request.date"
                          [selectedTimeZone]="request.timezone"
                          [onEventChecked]="checkEvent"
                          (onCorrectInvalidTiming)="correctInvalidTiming(schedule, event, $event)"
                          (onEventDeleted)="removeEvent(schedule, event, eventIndex)"
                          (onEventCloned)="cloneEvent(schedule, $event, eventIndex)">
      </app-schedule-event>
    </div>
    <div class="row drag-placeholder pt-2" *ngIf="schedule?.events?.length == 0">
      (Drag and drop {{scheduleIndex == 0 ? 'first' : 'second'}} schedule here)
    </div>
  </div>
</div>
