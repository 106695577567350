<div class="program-form">
    <div class="panel-header d-flex">
        <div class="d-flex justify-content-start">
            <h6 *ngIf="!isSky">
                {{ formatHeaderTitle('englishFullName', 'spanishFullName') || 'New Player' }}
            </h6>
            <h6 *ngIf="isSky">
                {{ formatHeaderTitle('englishFullName', 'portugueseFullName') || 'New Player' }}
            </h6>
        </div>
    </div>
    <div class="panel-body" id="playerForm">
        <div class="container-fluid">
            <ng-container *ngIf="isLoading">
                <div class="loading">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </ng-container>
            <div class="title-desc mt-2">
                <div class="row season-episode">
                    <div class="col">
                        <div class="form-group has-validation">
                            <label for="playerId">Player ID</label>
                            <input id="playerId" type="text" class="form-control" disabled [(ngModel)]="player.id" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group has-validation">
                            <label for="optaId">Opta ID</label>
                            <input id="optaId" type="text" [disabled] = "true" class="form-control" [(ngModel)]="player.externalId" />
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label for="gender">Gender</label>
                            <select class="form-control" id="gender" [(ngModel)]="player.gender">
                                <option *ngFor="let gender of CONSTANTS.GENDERS" [value]="gender">
                                    {{ gender }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label for="dob">Date of birth</label>
                            <input id="dob" type="date" class="form-control" [(ngModel)]="player.birthday" />
                        </div>
                    </div>
                </div>
                <div class="row seeInfoBtn mt-2">
                    <div class="col">
                        <div>
                            <div class="btn-ctrl" *ngIf="player?.otherLanguage">
                                <label>Other Name</label>
                                <label class="switch">
                                    <input type="checkbox" [checked]="player?.isOtherTitleHide"
                                        (click)="showHideInformation('isOtherTitleHide')">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <div class="btn-ctrl">
                                <label>{{isSky ? CONSTANTS.PORTUGUESE : CONSTANTS.SPANISH}} Name</label>
                                <label class="switch">
                                    <input type="checkbox" [checked]="player?.isPtEsTitleHide"
                                        (click)="showHideInformation('isPtEsTitleHide')">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                            <div class="btn-ctrl">
                                <label>English Name</label>
                                <label class="switch">
                                    <input type="checkbox" [checked]="player?.isEnTitleHide"
                                        (click)="showHideInformation('isEnTitleHide')">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row season-episode mt-2">
                    <div class="col" *ngIf="player?.isEnTitleHide">
                        <div class="form-group">
                            <label for="englishFirstName">English First Name</label>
                            <input id="englishFirstName" type="text" class="form-control"
                                [(ngModel)]="player.englishFirstName"
                                (keyup)="onFieldChange($event, 'englishFirstName', true)" />
                        </div>
                    </div>
                    <div class="col" *ngIf="player?.isPtEsTitleHide">
                        <div class="form-group">
                            <label for="spanishFirstName">
                                {{isSky ? CONSTANTS.PORTUGUESE : CONSTANTS.SPANISH}} First Name
                            </label>
                            <input [hidden]="isSky" id="spanishFirstName" type="text" class="form-control"
                                [(ngModel)]="player.spanishFirstName"
                                (keyup)="onFieldChange($event, 'spanishFirstName', true)" />
                            <input [hidden]="!isSky" id="portugueseFirstName" type="text" class="form-control"
                                [(ngModel)]="player.portugueseFirstName"
                                (keyup)="onFieldChange($event, 'portugueseFirstName', true)" />
                        </div>
                    </div>
                    <div class="col" *ngIf="player?.isOtherTitleHide">
                        <div class="form-group">
                            <label for="otherFirstName">Other First Name</label>
                            <input id="otherFirstName" type="text" class="form-control"
                                [(ngModel)]="player.otherFirstName"
                                (keyup)="onFieldChange($event, 'otherFirstName', true)" />
                        </div>
                    </div>
                </div>
                <div class="row season-episode mt-2">
                    <div class="col" *ngIf="player?.isEnTitleHide">
                        <div class="form-group">
                            <label for="englishLastName">English Last Name</label>
                            <input id="englishLastName" type="text" class="form-control"
                                [(ngModel)]="player.englishLastName"
                                (keyup)="onFieldChange($event, 'englishLastName')" />
                        </div>
                    </div>
                    <div class="col" *ngIf="player?.isPtEsTitleHide">
                        <div class="form-group">
                            <label for="spanishLastName">
                                {{isSky ? CONSTANTS.PORTUGUESE : CONSTANTS.SPANISH}} Last Name
                            </label>
                            <input [hidden]="isSky" id="spanishLastName" type="text" class="form-control"
                                [(ngModel)]="player.spanishLastName" (keyup)="onFieldChange($event, 'spanishLastName')">
                            <input [hidden]="!isSky" id="portugueseLastName" type="text" class="form-control"
                                [(ngModel)]="player.portugueseLastName"
                                (keyup)="onFieldChange($event, 'portugueseLastName')" />
                        </div>
                    </div>
                    <div class="col" *ngIf="player?.isOtherTitleHide">
                        <div class="form-group">
                            <label for="otherLastName">Other Last Name</label>
                            <input id="otherLastName" type="text" class="form-control"
                                [(ngModel)]="player.otherLastName" (keyup)="onFieldChange($event, 'otherLastName')" />
                        </div>
                    </div>
                </div>
                <div class="row season-episode mt-2">
                    <div class="col" *ngIf="player?.isEnTitleHide">
                        <div class="form-group">
                            <label for="englishFullName">English Full Name</label>
                            <input id="englishFullName" type="text" class="form-control"
                                [(ngModel)]="player.englishFullName" disabled />
                        </div>
                    </div>
                    <div class="col" *ngIf="player?.isPtEsTitleHide">
                        <div class="form-group">
                            <label for="spanishFullName">
                                {{isSky ? CONSTANTS.PORTUGUESE : CONSTANTS.SPANISH}} Full Name
                            </label>
                            <input [hidden]="isSky" id="spanishFullName" type="text" class="form-control"
                                [(ngModel)]="player.spanishFullName" disabled />
                            <input [hidden]="!isSky" id="portugueseFullName" type="text" class="form-control"
                                [(ngModel)]="player.portugueseFullName" disabled />
                        </div>
                    </div>
                    <div class="col" *ngIf="player?.isOtherTitleHide">
                        <div class="form-group">
                            <label for="otherFullName">Other Full Name</label>
                            <input id="otherFullName" type="text" class="form-control"
                                [(ngModel)]="player.otherFullName" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3 pl-3">
                <ul ngbNav #navMain="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>Images</a>
                        <ng-template ngbNavContent>
                            <div class="mt-2 program-pictures-list">
                                <div class="
                      row
                      no-gutters
                      row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5
                    ">
                                    <div *ngFor="let image of player.publishedImages; let i = index" class="col pb-2">
                                        <ng-container [ngTemplateOutlet]="playerImageCard" [ngTemplateOutletContext]="{
                          image: image,
                          i: i,
                          published: true
                        }"></ng-container>
                                    </div>
                                </div>
                            </div>
                            <ul ngbNav #navImages="ngbNav" [(activeId)]="navImage" class="nav-pills p-2 mt-1">
                                <li class="pr-5" [ngbNavItem]="i + 1"
                                    *ngFor="let size of player.imageSizes; let i = index">
                                    <a ngbNavLink>{{ size }}</a>
                                    <ng-template ngbNavContent>
                                        <div role="alert" class="alert alert-warning mt-2"
                                            *ngIf="!player.groupedImages[size]?.length">
                                            No {{ size }} images added
                                        </div>
                                        <div class="mt-4 program-pictures-list"
                                            *ngIf="player.groupedImages[size]?.length">
                                            <div class="
                            row
                            no-gutters
                            row-cols-sm-2
                            row-cols-md-3
                            row-cols-lg-4
                            row-cols-xl-5
                          ">
                                                <div *ngFor="
                              let image of player.groupedImages[size];
                              let i = index
                            " class="col pb-2">
                                                    <ng-container [ngTemplateOutlet]="playerImageCard"
                                                        [ngTemplateOutletContext]="{
                                image: image,
                                i: i,
                                published: false
                              }"></ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>
                            <ng-template #playerImageCard let-image="image" let-i="i" let-published="published">
                                <div class="card program-image mb-2">
                                    <div class="card-header d-flex justify-content-start">
                                        <input *ngIf="!published" [checked]="image.published"
                                            (click)="publishedImage(image)" type="radio"
                                            class="img-radio-btn cursor-pointer" name="image-selected"
                                            ngbTooltip="Click to Publish" />
                                        <span (click)="deleteImage(image, i)" *ngIf="image.imageFile && !published"
                                            class="
                          pub-span
                          badge badge-warning
                          delete-image
                          cursor-pointer
                        ">X</span>
                                        <span *ngIf="image.published" class="pub-span badge badge-success">PUB </span>
                                        <span container="body" class="badge badge-warning pub-span"
                                            *ngIf="!image.published">
                                            UNPUB
                                        </span>
                                    </div>
                                    <div class="card-body">
                                        <img class="card-img-top cast-crew-img" [src]="
                          image.uri
                            ? image.baseUrl + image.uri
                            : image.imageURL || previewUrl
                        " style="object-fit: contain; width: 100%" (error)="onImageError($event)" />
                                    </div>
                                    <div class="card-footer">
                                        <p style="
                          margin-bottom: 0px;
                          text-align: left;
                          display: flex;
                          flex-direction: column;
                        " class="ml-auto">
                                            <span>
                                                <a *ngIf="selectedProvider === 'gracenote'" ngbTooltip="{{ image.id }}"
                                                    target="_blank" href="http://tmsimg.com/{{ image.uri }}">
                                                    Category: {{ image.category }}
                                                </a>
                                                <a *ngIf="selectedProvider !== 'gracenote'" ngbTooltip="{{ image.id }}"
                                                    target="_blank" href="{{
                              image.uri ? image.baseUrl + image.uri : previewUrl
                            }}">
                                                    Category: {{ image.category }}
                                                </a>
                                            </span>
                                            <span class="font-weight-bold">
                                                Ratio: {{ image.ratio }}
                                            </span>
                                            <span class="font-weight-bold">
                                                Source: {{
                                                image.source === 'gracenote'
                                                ? 'GN'
                                                : image.source === 'VLS' || !image.source
                                                ? 'VLS'
                                                : 'DMS'
                                                }}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </ng-template>
                            <div class="d-flex justify-content-between mt-4 pl-2 pr-2">
                                <div class="btn-group d-flex w-100">
                                    <div class="btn-group" ngbDropdown>
                                        <button class="btn btn-att btn-upload" ngbDropdownToggle
                                            style="font-size: 14px">
                                            {{ selectedImageCategory }}
                                        </button>
                                        <div class="dropdown-menu" ngbDropdownMenu>
                                            <button class="custom-dropdown-item"
                                                *ngFor="let category of imageCategories"
                                                (click)="setImageCategory(category)" ngbDropdownItem>
                                                {{ category }}
                                            </button>
                                        </div>
                                    </div>
                                    <div class="custom-file program-image">
                                        <input id="imageUpload" type="file" accept="image/png, image/jpeg"
                                            (change)="onFileSelect($event)" />
                                        <label container="body" ngbTooltip="Click to select image" id="inputLabel"
                                            class="custom-file-label form-control" for="imageUpload">
                                        </label>
                                    </div>
                                </div>
                                <button class="btn btn-att btn-upload" [disabled]="!image.imageFile"
                                    (click)="addImage(image)">
                                    Add
                                </button>
                            </div>
                            <div [ngbNavOutlet]="navImages" class="pl-2 pr-2"></div>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>Sports Info</a>
                        <ng-template ngbNavContent>
                            <div class="d-flex justify-content-end mb-1">
                                <button class="btn btn-att btn-sm" (click)="openModal(sportsInfoModal)">
                                    Add Sport Info
                                </button>
                            </div>
                            <table class="table table-striped">
                                <tbody>
                                    <tr>
                                        <th rowspan="2">Team ID</th>
                                        <th rowspan="2">Sport type</th>
                                        <th rowspan="2">Type</th>
                                        <th rowspan="2">Status</th>
                                        <th colspan="2">Soccer</th>
                                        <th rowspan="2">Actions</th>
                                    </tr>
                                    <tr>
                                        <th colspan="1">Foot</th>
                                        <th colspan="1">Position</th>
                                    </tr>
                                    <tr class="cursor-pointer"
                                        *ngFor="let sportInfo of player.sportsInfo; let i = index">
                                        <td>{{ sportInfo.teamId || '--' }}</td>
                                        <td>{{ sportInfo.sportType || '--' }}</td>
                                        <td>{{ sportInfo.playerType || '--' }}</td>
                                        <td>{{ sportInfo.status || '--' }}</td>
                                        <td>{{ sportInfo?.soccer?.foot || '--' }}</td>
                                        <td>{{ sportInfo?.soccer?.position || '--' }}</td>
                                        <td>
                                            <ng-container [ngTemplateOutlet]="tableActions" [ngTemplateOutletContext]="{
                                      modalName: sportsInfoModal,
                                      entity: 'sportInfo',
                                      fieldName: 'sportsInfo',
                                      filterName: 'index',
                                      id: i
                                    }"></ng-container>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="navMain" class="mt-2 w-100 p-2"></div>
            </div>
        </div>
    </div>
    <div class="panel-footer mb-2 d-flex justify-content-between p-1">
        <button class="btn btn-att ml-2" (click)="cancel()">
            Cancel
        </button>
        <div class="ml-auto">
            <button class="btn btn-att mr-2" (click)="savePlayer()">
                Quick Save
            </button>
        </div>
    </div>

    <!-- Sports Info modal -->
    <ng-template #sportsInfoModal id="sportsInfoModal">
        <ng-container [ngTemplateOutlet]="modalHeader" [ngTemplateOutletContext]="{ headerTitle: 'Add Sport Info' }">
        </ng-container>
        <div class="modal-body">
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="teamId">Team ID</label>
                        <input type="text" name="teamId" class="form-control" [(ngModel)]="sportInfo.teamId"/>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="sportType">Sport Type</label>
                        <input type="text" name="sportType" class="form-control" [(ngModel)]="sportInfo.sportType"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <label for="playerType">Player Type</label>
                        <select class="form-control" id="playerType" [(ngModel)]="sportInfo.playerType">
                            <option *ngFor="let playerType of CONSTANTS.PLAYER_TYPES" [value] = "playerType">
                                {{ playerType }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <label for="status">Status</label>
                        <select class="form-control" id="status" [(ngModel)]="sportInfo.status">
                            <option *ngFor="let status of CONSTANTS.STATUS" [value] = "status">
                                {{ status }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <label>Soccer</label>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                    <div class="form-group">
                                        <label for="foot">Foot</label>
                                        <select class="form-control" id="foot" [(ngModel)]="sportInfo?.soccer.foot">
                                            <option *ngFor="let foot of CONSTANTS.FOOT" [value] = "foot">
                                                {{ foot }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-group">
                                        <label for="position">Position</label>
                                        <select class="form-control" id="status"
                                            [(ngModel)]="sportInfo.soccer.position">
                                            <option *ngFor="let position of CONSTANTS.POSITIONS" [value] = "position">
                                                {{ position }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-att mr-2" [disabled]="
            sportInfo.teamId != '' &&
            sportInfo.sportType != '' &&
            sportInfo.playerType != '' &&
            sportInfo.status != ''
            ? false
            : true
        " (click)="addItem('sportsInfo', sportInfo)">
                Add
            </button>
        </div>
    </ng-template>

    <!-- Common header modal template -->
    <ng-template #modalHeader id="modalHeader" let-header="headerTitle">
        <div class="modal-header">
            <h4 class="modal-title">{{ header }}</h4>
            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </ng-template>

    <!-- Common table actions template for releases, awards and keywords list  -->
    <ng-template #tableActions let-modalName="modalName" let-entity="entity" let-fieldName="fieldName"
        let-filterName="filterName" let-id="id">
        <span class="cursor-pointer" (click)="openModal(modalName, entity, fieldName, filterName, id)">
            <i class="fas fa-edit"></i>
        </span>
        <span class="cursor-pointer" (click)="openDeleteModal(fieldName, filterName, id)">
            <i class="far fa-window-close ml-2"></i>
        </span>
    </ng-template>
</div>