/*
 * =============================================================================
 * Vrio Corp. PROPRIETARY Copyright© 2021 Vrio Corp. UNPUBLISHED WORK ALL RIGHTS
 * RESERVED
 *
 * This software is the confidential and proprietary information of Vrio Corp.
 * ("Proprietary Information"). Any use, reproduction, distribution or
 * disclosure of the software or Proprietary Information, in whole or in part,
 * must comply with the terms of the license agreement, nondisclosure agreement
 * or contract entered into with Vrio Corp. providing access to this software.
 *
 * ============================================================================
 */

import { EventEmitter, Injectable, Output } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'

@Injectable({ providedIn: 'root' })
export class ScheduleCopyService {
  @Output() change: EventEmitter<string> = new EventEmitter()
  schedulesUrl: string
  channelsUrl: string

  constructor(private http: HttpClient) {
    this.schedulesUrl = environment.apiUrl + '/v2/schedules'
    this.channelsUrl = environment.apiUrl + '/channels'
  }

  /**
   * Call API to handle schedule bulk copy.
   *
   * @param data ScheduleCopyDTO
   * @returns ResponseDTO
   */
  saveBulkCopy(data, timeZone = 'GMT') {
    // send request to bulk copy api
    const submitUrl = `${this.schedulesUrl}/bulkCopy?timeZone=${timeZone}`
    return this.http.post<any>(submitUrl, data)
  }

  /**
   * Call API to get all channels.
   *
   * @returns ResponseDTO - list of channels
   */
  getAllChannels() {
    // send request to get all channels
    return this.http.get<any>(`${this.channelsUrl}/servicechannel`)
  }

  /**
   * Create schedule copy object default
   *
   * @returns ScheduleCopy
   */
  bulkCopyDefaults() {
    return {
      id: null,
      origChannelId: '',
      origChannelNames: [],
      destChannelId: '',
      destChannelNames: [],
      startDate: '',
      endDate: '',
      destStartDate: '',
      numOfCopies: 1,
      published: true,
      userId: null,
    }
  }
}
