<div class="teams-panel">
  <div class="panel-header teams-header">
    <div class="d-flex justify-content-start">
      <h6>Teams</h6>
    </div>
    <div class="teams-search input-group mt-1">
      <input type="search" 
      class="form-control" 
      placeholder="Search teams" 
      [(ngModel)]="searchString"         
      (ngModelChange)="searchChange($event)"
      (keyup)="searchKeyup($event)"/>
      <div class="input-group-append">
        <span class="input-group-text" (click)="searchTeams()">
          <i class="fa fa-search"></i>
        </span>
      </div>
    </div>
  </div>
  <div class="panel-body teams-wrapper" id="teamList" (scroll)="onScroll($event)">
    <ng-container *ngIf="showLoader">
      <div class="loading">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-container>
    <div *ngFor="let team of teams; let i = index" class="d-flex justify-content-between p-1 flex-column">
      <div id="teamItem-{{ i }}" class="teams-item d-block" (click)="viewTeamInfo(team.id)">
        <div class="team-name d-flex">
          <strong class="text-truncate" *ngIf="!isSky">
            {{ team.spanishOfficialName }}
          </strong>
          <strong class="text-truncate" *ngIf="isSky">
            {{ team.portugueseOfficialName }}
          </strong>
        </div>
        <div class="team-name d-flex" style="align-items: center">
          <div class="text-muted text-truncate mr-3">
            Code: {{ team.abbreviation || 'No code' }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-footer mb-2 d-flex justify-content-end">
    <div class="btn-group mr-2 p-1">
      <button class="btn btn-att py-0 px-2" (click)=createTeam()>
        Add Team
      </button>
    </div>
  </div>
</div>