<div class="schedule-panel">
  <div id="schedule-container">
    <app-schedule-header
      [checkAll]="allEventsChecked"
      [checkAllIndeterminate]="allEventsIndeterminate"
      [hasEvent]="hasEvent()"
      [isSchedulesLoading]="scheduleLoadingSubject|async"
      [updateScheduleDate]="updateScheduleDate"
      [updateTimezone]="updateTimezone"
      (onCheckAllEvents)="handleCheckingAllEvents($event)"
      (onDisplayProgramPanel)="handleDisplayingProgramPanel($event)"
    ></app-schedule-header>
    <div class="panel-body schedule-wrapper p-1" id="scheduleList">
      <div class="loading" *ngIf="scheduleLoadingSubject|async">
        <div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>
      </div>
      <app-schedule-body
        [request]="requestSubject|async"
        [regions]="(channelSubject|async)?.regions"
        [ratings]="ratings"
        [qualifiers]="qualifiers"
        [genres]="genres"
        [selectedScheduleVersions]="selectedScheduleVersionsSubject|async"
        [selectedScheduleEvents]="selectedScheduleEvents"
        [onSelectedEventsChanged]="handleSelectedEventsChanged"
        (onSchedulesChanged)="handleSchedulesChanged($event)"
      ></app-schedule-body>
    </div>
  </div>
  <app-schedule-footer
    [isSchedulesLoading]="scheduleLoadingSubject|async"
    [selectedScheduleEvents]="selectedScheduleEvents"
    [publishSchedules]="publishSchedules"
    [saveSchedules]="saveSchedules"
    (onManageQualifiers)="handleMangingQualifiers(qualifierModal)"
    (onManageBlackout)="manageBlackout(blackoutModal)"
    (onRemoveSelectedEvent)="handleRemovingEvents()"
  ></app-schedule-footer>
  <ng-template #blackoutModal>
    <app-blackout-modal
      [regions]="(channelSubject|async)?.regions"
      [events]="selectedScheduleEvents"
      [onCloseModal]="closeModal"
      [onApplyingBlackout]="applyBlackout"
    ></app-blackout-modal>
  </ng-template>
  <ng-template #qualifierModal>
    <app-qualifiers-modal
      [qualifiers]="qualifiers"
      [onCloseModal]="closeModal"
      [onApplyChanges]="applyQualifiers"
      [selectedScheduleEvents]="selectedScheduleEvents"
    ></app-qualifiers-modal>
  </ng-template>
</div>
