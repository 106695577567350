<div class="row">
    <div class="col">
        <div class="panel-body p-2">
            <ng-container *ngIf="showLoader">
                <div class="loading">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </ng-container>
            <div #scrollBIOFiles id="tableBIOFiles" class="table-content table-fixed" (scroll)="onScroll($event)">
                <table class="table table-striped">
                    <thead class="bg-dark">
                        <tr>
                            <th *ngFor="let header of sortByHeader; let j = index" [ngClass]="{'th-file-path': header.fieldName == 'filePath'}">
                                <div class="d-flex align-items-end justify-content-center">
                                    {{header.name}}
                                    <div *ngIf="header.isDisplay" class="sort-button ml-2">
                                        <a class="btn btn-xs btn-link p-0" *ngIf="header.isAsc != false" (click)="onChangeSorting(j, false)">
                                            <i class="fas fa-sort-up sort-icon"></i>
                                        </a>
                                        <a class="btn btn-xs btn-link p-0" *ngIf="header.isAsc != true" (click)="onChangeSorting(j, true)">
                                            <i class="fas fa-sort-down sort-icon"></i>
                                        </a>
                                    </div>
                                </div>
                            </th>
                            <th scope="col">Select</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let bioInfo of listBIOInfos; let i = index">
                            <td>{{ bioInfo.goHeader || '--' }}</td>
                            <td>{{ bioInfo.personId || '--' }}</td>
                            <td>{{ bioInfo.createdDateString || '--' }}</td>
                            <td>{{ bioInfo.sentDateString || '--' }}</td>
                            <td class="td-file-path">{{ bioInfo.filePath || '--' }}</td>
                            <td>{{ bioInfo.status || '--' }}</td>
                            <td class="td-reason">{{ bioInfo.reason || '' }}</td>
                            <td class="text-center">
                                <input type="checkbox" (click)="checked(i)" [disabled]="selectedIndex == null || selectedIndex == i ? false : true"
                                    [checked]="bioInfo.goHeader == selectedBIOInfo?.goHeader && bioInfo.goHeader ? true : false">
                            </td>
                        </tr>  
                    </tbody>
                </table>
            </div>
        </div>
        <div class="panel-footer justify-content-between d-flex mb-2 pl-2 pr-2">
            <button class="btn btn-att" [disabled]="selectedBIOInfo != null && selectedBIOInfo.status.toLowerCase() == 'failed' ? false : true" (click)="resend()">
                <i *ngIf="loaderResend" class="fas fa-spinner fa-spin mr-1"></i>Resend By Batch
            </button>
            <button class="btn btn-att" [disabled]="selectedIndex != null ? false : true" (click)="download()">
                <i *ngIf="loaderDownload" class="fas fa-spinner fa-spin mr-1"></i>Download By Batch
            </button>
        </div>
    </div>    
</div>