<div class="modal-header">
  <h4 class="modal-title">
    {{ isCreate ? 'Create' : 'Update' }} Service Channel
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label>Names</label>
        <input [disabled]="channel.channelSrc === 'DMS' || isCreate == false" type="text" class="form-control" [(ngModel)]="channel.name"
               id="name" (keyup)="onFieldChange($event)" />
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>Call Sign</label>
        <input
          [disabled]="channel.channelSrc !== 'VLS' && channel.channelSrc !== 'TCS' &&
        isCreate == false"
          type="text"
          class="form-control"
          [(ngModel)]="channel.callSign" id="callSign"/>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label>Language</label>
        <input [disabled]="channel.channelSrc === 'DMS' || isCreate == false" type="text" class="form-control" [(ngModel)]="channel.language"
               (keyup)="onFieldChange($event)" id="language" />
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>Platform</label>
        <ng-select [disabled]="channel.channelSrc === 'DMS' || isCreate == false" class="dropdown-channel" [items]="platformTypes"
                   placeholder="Choose a platform" [(ngModel)]="channel.channelTarget" id="channelTarget" (change)="onFieldChange($event, 'channelTarget')">
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label>Region</label>
        <textarea [(ngModel)]="channel.region" class="form-control" rows="1"></textarea>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>Channel Type</label>
        <select
          [disabled]="channel.channelSrc === 'DMS' || isCreate == false"
          class="form-control"
          (change)="onFieldChange($event)"
          id="channelType"
          [(ngModel)]="channel.channelType"
        >
          <option
            *ngFor="let chanl of channelTypes"
            [value]="chanl"
          >
            {{ chanl }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group" >
        <label>Source Id</label>
        <input
          [disabled]="channel.channelSrc === 'DMS' || isCreate == false"
          type="text"
          class="form-control"
          [(ngModel)]="channel.sourceId"
          id="sourceId"
          (keyup)="onFieldChange($event)"
        />
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>Category</label>
        <textarea [(ngModel)]="channel.category" class="form-control" rows="1"></textarea>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="channel.channelTarget.toLowerCase() === CONSTANTS.DTH">
    <div class="col">
      <div class="form-group">
        <label>Viewer Channel Number</label>
        <input type="number" onkeydown="return event.keyCode !== 69 && event.keyCode !== 190" class="form-control"
               [(ngModel)]="channel.dthInfo.viewerNumber" id="viewerNumber" (keyup)="onFieldChange($event)"/>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label>Channel Resolution</label>
        <select class="form-control" (change)="onFieldChange($event)"
                id="channelResolution" [(ngModel)]="channel.dthInfo.channelResolution">
          <option *ngFor="let resolutionType of resolutionTypes" [value]="resolutionType">
            {{ resolutionType }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6" *ngIf="channel.channelTarget.toLowerCase() === CONSTANTS.DTH && channel.dthInfo.isSlave != false && channel.hasSchedule != true">
      <div class="form-group">
        <label>Master Channel Name</label>
        <ng-select id="masterSource" [items]="masterSources" [hideSelected]="true" bindLabel="name"
                   placeholder="Choose a master source" (ngModelChange)="onFieldChange($event, 'masterSources')" [(ngModel)]="channel.masterChannelName">
          <ng-template ng-option-tmp let-item="item">
            <div container="body">
              <span>{{item.name}}</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label>Description</label>
        <input type="text" class="form-control" [(ngModel)]="channel.fullDescription"
               id="description" (keyup)="onFieldChange($event)" />
      </div>
    </div>
    <div class="col-6" *ngIf="!(channel.channelTarget.toLowerCase() === CONSTANTS.DTH && channel.dthInfo.isSlave != false && channel.hasSchedule != true)">
      <div class="form-group">
        <label>Assigned To</label>
        <select class="form-control" value="English">
          <option></option>
          <option>Donna</option>
          <option>Rexon</option>
          <option>Anne</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="form-group">
        <label for="startDate"> Start Date </label>
        <input [disabled]="channel.masterChannelName?.name || channel.channelSrc === 'DMS' || (isCreate == false && !isDthPlatform())" [(ngModel)]="channel.licenseStartDate"
               [owlDateTimeTrigger]="licenseStartDate" [owlDateTime]="licenseStartDate" class="form-control datetimepicker-filter"
               [max]="channel.licenseEndDate" required id="licenseStartDate" (ngModelChange)="onFieldChange($event, 'licenseStartDate')" />
        <owl-date-time #licenseStartDate [pickerType]="'calendar'"></owl-date-time>
      </div>
    </div>
    <div class="col">
      <div class="form-group">
        <label for="endDate"> End Date </label>
        <input [disabled]="channel.masterChannelName?.name || channel.channelSrc === 'DMS' || (isCreate == false && !isDthPlatform())" [(ngModel)]="channel.licenseEndDate"
               [owlDateTimeTrigger]="licenseEndDate" [owlDateTime]="licenseEndDate" class="form-control datetimepicker-filter"
               [min]="channel.licenseStartDate" required  id="licenseEndDate" (ngModelChange)="onFieldChange($event, 'licenseEndDate')"/>
        <owl-date-time #licenseEndDate [pickerType]="'calendar'"></owl-date-time>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-6" *ngIf="channel.channelTarget.toLowerCase() === CONSTANTS.DTH">
      <div class="form-group">
        <label>Processing Type</label>
        <select class="form-control" (change)="onFieldChange($event)"
                id="processingType" [(ngModel)]="channel.processingType">
          <option *ngFor="let processingType of processingTypes" [value]="processingType">
            {{ processingType }}
          </option>
        </select>
      </div>
    </div>
    <div class="col" *ngIf="channel.channelTarget.toLowerCase() === CONSTANTS.DTH && channel.dthInfo.isSlave != false && channel.hasSchedule != true">
      <div class="form-group">
        <label>Assigned To</label>
        <select class="form-control" value="English">
          <option></option>
          <option>Donna</option>
          <option>Rexon</option>
          <option>Anne</option>
        </select>
      </div>
    </div>
    <div class="col" *ngIf="!isCreate && channel.hasSchedule == true">
      <div class="row justify-content-end">
        <div class="col-4">
          <div class="form-group" *ngIf="channel.channelTarget.toLowerCase() === CONSTANTS.DTH">
            <label>Engineering</label>
            <label class="switch">
              <input type="checkbox" [checked]="channel.dthInfo?.isEngineering" (click)="onSwitch(false)">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label>Published</label>
            <label class="switch">
              <input type="checkbox" [checked]="channel.published" (click)="onSwitch(true)">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-end" *ngIf="isCreate || !channel.hasSchedule">
    <div class="col-2">
      <div class="form-group" *ngIf="channel.channelTarget.toLowerCase() === CONSTANTS.DTH">
        <label>Engineering</label>
        <label class="switch">
          <input type="checkbox" [checked]="channel.dthInfo?.isEngineering" (click)="onSwitch(false)">
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="col-2">
      <div class="form-group">
        <label>Published</label>
        <label class="switch">
          <input type="checkbox" [checked]="channel.published" (click)="onSwitch(true)">
          <span class="slider round"></span>
        </label>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-dark w-25" (click)="submit()">
    <i *ngIf="showLoader" class="fas fa-spinner fa-spin"></i> Submit
  </button>
</div>
