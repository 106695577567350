<div class="transmit-history-panel">
  <div class="panel-header header">
    <div class="d-flex justify-content-between">
      <h6>History</h6>
      <div class="date-selection-panel ml-auto d-flex">
        <div class="row no-gutters">
          <div class="col">
            <div class="form-group ml-1">
              <input
                [owlDateTimeTrigger]="startDate"
                [owlDateTime]="startDate"
                id="startDate"
                placeholder="From"
                class="form-control"
              />
              <owl-date-time
                #startDate
                (afterPickerOpen)="onCalendarOpen()"
              ></owl-date-time>
            </div>
          </div>
          <div class="col">
            <div class="form-group ml-1">
              <input
                [owlDateTimeTrigger]="endDate"
                [owlDateTime]="endDate"
                id="endDate"
                placeholder="To"
                class="form-control"
              />
              <owl-date-time
                #endDate
                (afterPickerOpen)="onCalendarOpen()"
              ></owl-date-time>
            </div>
          </div>
          <div class="col-xs ml-1">
            <button
              container="body"
              ngbTooltip="Search history"
              (click)="searchHistory()"
              class="btn btn-light btn-icon my-1"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="panel-body">
    <table class="table table-stripped">
      <thead>
        <th>Sent</th>
        <th>From</th>
        <th>To</th>
        <th>Message</th>
      </thead>
      <tbody>
        <tr>
          <td>05/01/2021T00:00:00</td>
          <td>05/01/2021T00:00:00</td>
          <td>05/12/2021T00:00:00</td>
          <td>Successfully transmitted schedules</td>
        </tr>
        <tr>
          <td>05/02/2021T00:00:00</td>
          <td>05/01/2021T00:00:00</td>
          <td>05/12/2021T00:00:00</td>
          <td>No schedules transmitted</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="panel-footer"></div>
</div>
