<div class="container-fluid mt-3">
    <div class="page-title">
        <h4>Uncompleted Schedule Report</h4>
    </div>
    <div class="header-schedule-report-container">
        <div>
            <div class="search input-group">
                <input type="search" class="form-control" placeholder="Search by channelID" [(ngModel)] ="searchString" (onChange) ="generateReport()"/>
                <div class="input-group-append">
                    <span class="input-group-text">
                        <i class="fa fa-search" (click) = "generateReport()"></i>
                    </span>
                </div>
            </div>
        </div>
        <div class="control-container">
            <label class="label-for-date">
                Start date &nbsp;
            </label>
            <div>
                <input class="form-control" id="startDate" [owlDateTimeTrigger]="sdt" [owlDateTime]="sdt"  placeholder="Select date" [(ngModel)]="startDate" [max] ="endDate" (focus) ="onDateChange(true)"/>
                <owl-date-time [pickerType]="'calendar'" #sdt="owlDateTime">
                </owl-date-time>
            </div>
        </div>
        <div class="control-container">
            <label class="label-for-date">
                End Date &nbsp;
            </label>
            <div>
                <input class="form-control" id="endDate" [owlDateTimeTrigger]="edt" [owlDateTime]="edt" placeholder="Select date" [(ngModel)]="endDate" [min] ="startDate" (focus) ="onDateChange(false)"/>
                <owl-date-time [pickerType]="'calendar'" #edt="owlDateTime"  >
                </owl-date-time>
            </div>
        </div>
        <div class="control-container">
            <button class="btn btn-sm btn-att ml-2" type="button" (click) = "generateReport()">Generate report</button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="panel-body p-3">
                <div class="table-content table-fixed" (scroll)="onScroll($event)">
                    <table class="table table-striped">
                        <thead class="bg-dark">
                            <tr>
                              <th *ngFor="let header of sortByScheduleReportHeaders; let j = index">
                                <div class="d-flex align-items-end justify-content-start">
                                    {{header.name}}
                                    <div *ngIf="header.isDisplay" class="sort-button ml-2">
                                        <a class="btn btn-xs btn-link p-0" *ngIf="header.isAsc != false" (click)="onChangeSorting(header.fieldName, j, false)">
                                            <i class="fas fa-sort-up sort-icon" ></i>
                                        </a>
                                        <a class="btn btn-xs btn-link p-0" *ngIf="header.isAsc != true" (click)="onChangeSorting(header.fieldName, j, true)">
                                            <i class="fas fa-sort-down sort-icon"></i>
                                        </a>
                                    </div>
                                </div>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="showLoader">
                                <div class="loading">
                                  <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                </div>
                            </ng-container>
                            <tr *ngFor="let schedule of uncompletedSchedules">
                                <td>{{schedule.scheduleDate || "--"}}</td>
                                <td>{{schedule.channelId}}</td>
                                <td>{{schedule.sourceId}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>