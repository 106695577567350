<div class="container-fluid mt-3">
  <div class="page-title">
    <h4>Players</h4>
  </div>

  <div class="row mt-2 p-1">
    <div class="col-md-3">
      <app-player-list
      [newPlayerId]="newPlayerId"
      (isCreateNew)="onCreateNewPlayer($event)"
      (playerId)="onPlayerIdChange($event)"
      >
      </app-player-list>
    </div>
    <div class="col-md-9">
      <app-player-form
      [player]="player"
      [oldPlayer]="oldPlayer"
	    (newPlayerCreated)="onNewPlayerCreated($event)"
      (playerId)="onPlayerIdChange($event)"
      ></app-player-form>
    </div>
  </div>
</div>