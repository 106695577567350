<div class="container-fluid mt-3">
  <div class="page-title">
    <h4>Channel Transmit</h4>
  </div>
  <div class="row mt-2 p-1">
    <div class="col-lg-3">
      <app-channels-list
        targetComponent="transmit"
        componentName="Service Channel"
        (channel)="onChannelChange($event)"
      ></app-channels-list>
    </div>
    <div class="col-lg-9">
      <app-transmit-history-panel></app-transmit-history-panel>
    </div>
  </div>
</div>
