<nav class="navbar navbar-expand-md bg-dark sticky-top">
  <div class="container-fluid">
    <a class="navbar-brand" href="#" routerLink="/schedules">
      <div class="toolbar" role="banner">
        <svg class="att-logo" aria-label="VLS home">
          <path
            d="M24.184 2.256A15.818 15.818 0 0 0 16 0c-3.023 0-5.89.847-8.298 2.323-.72.445-1.128.8-1.128 1.258 0 1.35 3.154 2.8 8.747 2.8 5.534 0 9.828-1.589 9.828-3.119 0-.365-.319-.62-.965-1.006m2.382 1.778c.067.104.1.218.1.369 0 1.777-5.438 4.922-14.096 4.922-6.36 0-7.551-2.36-7.551-3.862 0-.537.205-1.086.658-1.643.025-.034.004-.046-.025-.021-.826.7-1.585 1.488-2.252 2.34-.318.402-.516.758-.516.972 0 3.111 7.8 5.367 15.09 5.367 7.77 0 11.238-2.536 11.238-4.767 0-.797-.31-1.262-1.103-2.164a18.898 18.898 0 0 0-1.518-1.535c-.025-.02-.042-.004-.025.022M1.543 9.136c-.47.931-.99 2.504-1.145 3.317-.067.348-.038.515.084.775.981 2.084 5.946 5.418 17.526 5.418 7.065 0 12.554-1.736 13.443-4.906.163-.583.171-1.199-.038-2.03-.235-.926-.675-2.008-1.048-2.767-.013-.025-.034-.02-.03.009.139 4.155-11.446 6.834-17.291 6.834-6.331 0-11.614-2.524-11.614-5.71 0-.306.063-.613.142-.931.009-.03-.016-.034-.03-.009m11.242 13.28c-8.189 0-12.05-3.816-12.75-6.42C.025 15.958 0 15.966 0 16c0 .876.088 2.008.239 2.759.071.365.369.94.805 1.396 1.983 2.067 6.927 4.965 15.488 4.965 11.665 0 14.332-3.887 14.877-5.166.39-.914.591-2.566.591-3.954 0-.336-.008-.604-.021-.868 0-.042-.025-.046-.034-.004-.583 3.128-10.549 7.287-19.161 7.287m4.302 7.04c3.937 0 8.264-1.073 10.85-3.2.71-.578 1.385-1.35 1.988-2.385.348-.596.688-1.304.965-2 .013-.033-.009-.05-.034-.013-2.407 3.544-9.375 5.753-16.57 5.753-5.086 0-10.558-1.627-12.7-4.734-.021-.03-.042-.017-.03.017 1.996 4.243 8.05 6.562 15.531 6.562m-10.898-.818A15.983 15.983 0 0 0 16 32c4.042 0 7.727-1.501 10.54-3.966.034-.03.018-.05-.016-.03-1.262.843-4.86 2.684-10.524 2.684-4.922 0-8.034-1.1-9.79-2.08-.034-.017-.046.008-.022.03"
            fill="#009FDB"
            fill-rule="evenodd"
          ></path>
        </svg>
        <h5 class="mt-2 ml-2">VLS</h5>
      </div>
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item dropdown" ngbDropdown routerLinkActive="active">
          <a
            class="nav-link dropdown-toggle"
            id="schedulesDropdown"
            ngbDropdownToggle
          >
            {{ 'vls.schedules.headerTitle' | translate }}
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="schedulesDropdown"
            ngbDropdownMenu
          >
            <a
              class="dropdown-item nav-link"
              href="#"
              routerLink="/schedules"
              routerLinkActive="active"
            >
              {{ 'vls.schedules.subMenu.scheduleList' | translate }}
            </a>
            <a
              class="dropdown-item nav-link"
              href="#"
              routerLink="/schedules-copy"
              routerLinkActive="active"
            >
              {{ 'vls.schedules.subMenu.scheduleCopy' | translate }}
            </a>
            <a
            class="dropdown-item nav-link"
            href="#"
            routerLink="/schedules-report"
            routerLinkActive="active"
          >
            {{ 'vls.schedules.subMenu.scheduleReport' | translate }}
          </a>
          </div>
        </li>
        <li class="nav-item dropdown" ngbDropdown routerLinkActive="active">
          <a
            class="nav-link dropdown-toggle"
            id="programDropdown"
            ngbDropdownToggle
          >
            {{ 'vls.programs.headerTitle' | translate }}
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="programDropdown"
            ngbDropdownMenu
          >
            <a
              class="dropdown-item nav-link"
              href="#"
              routerLink="/programs"
              routerLinkActive="active"
            >
              Program Management
            </a>
            <a
              class="dropdown-item nav-link"
              routerLinkActive="active"
              (click)="isAccessDeniedToScreen()"
            >
              Program Mapping
            </a>
          </div>
        </li>
        <li class="nav-item dropdown" ngbDropdown routerLinkActive="active">
          <a
            class="nav-link dropdown-toggle"
            id="dropdownChannel"
            ngbDropdownToggle
          >
            {{ 'vls.channels.headerTitle' | translate }}
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="dropdownChannel"
            ngbDropdownMenu
          >
            <a
              class="dropdown-item nav-link"
              href="#"
              routerLink="/channel/management"
              routerLinkActive="active"
            >
              Management
            </a>
<!--            <a-->
<!--              class="dropdown-item nav-link"-->
<!--              href="#"-->
<!--              routerLink="/channel/transmit"-->
<!--              routerLinkActive="active"-->
<!--            >-->
<!--              Channel Transmit-->
<!--            </a>-->
          </div>
        </li>
        <li class="nav-item dropdown" ngbDropdown routerLinkActive="active">
          <a
            class="nav-link dropdown-toggle"
            id="soccerDropdown"
            ngbDropdownToggle
          >
            Soccer
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="soccerDropdown"
            ngbDropdownMenu
          >
            <a
              class="dropdown-item nav-link"
              href="#"
              routerLink="/venues"
              routerLinkActive="active"
            >
              Venue Management
            </a>
            <a
              class="dropdown-item nav-link"
              href="#"
              routerLink="/soccer/teams"
              routerLinkActive="active"
            >
              Teams Management
            </a>
            <a
              class="dropdown-item nav-link"
              href="#"
              routerLink="/players"
              routerLinkActive="active"
            >
              Player Management
            </a>
          </div>
        </li>
        <li class="nav-item dropdown" ngbDropdown routerLinkActive="active" *ngIf="!isDthPlatform()">
          <a
            class="nav-link dropdown-toggle"
            id="VODDropdown"
            ngbDropdownToggle
          >
            {{ 'vls.VOD-assets.headerTitle' | translate }}
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="VODDropdown"
            ngbDropdownMenu
          >
            <a
              class="dropdown-item nav-link"
              href="#"
              routerLink="/vod-assets-management"
              routerLinkActive="active"
            >
              Assets Management
            </a>
            <a
              class="dropdown-item nav-link"
              href="#"
              routerLink="/vod-asset-reports"
              routerLinkActive="active"
            >
              Asset Reports
            </a>
          </div>
          </li>
        <!-- VLS-4471: temporary disable user management UI -->
        <!-- <li class="nav-item">
          <a
            class="nav-link"
            routerLink="/users"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            User Management
          </a>
        </li> -->
        <li class="nav-item dropdown" ngbDropdown routerLinkActive="active" *ngIf="isDthPlatform()">
          <a
            class="nav-link dropdown-toggle"
            id="reportDropdown"
            ngbDropdownToggle
          >
            Reports
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="reportDropdown"
            ngbDropdownMenu
          >
            <a
              class="dropdown-item nav-link"
              href="#"
              routerLink="/reports/apg-report"
              routerLinkActive="active"
            >
              APG Report
            </a>
            <a
              class="dropdown-item nav-link"
              href="#"
              routerLink="/reports/ecad"
              routerLinkActive="active"
            >
              ECAD Report
            </a>
            <li class="nav-item dropright" ngbDropdown>
              <a
                class="nav-link dropdown-toggle"
                id="reportDropdown"
                ngbDropdownToggle
              >
                TCS Report
              </a>
              <div
                class="dropdown-menu sub-drop-menu"
                aria-labelledby="reportDropdown"
                ngbDropdownMenu
              >
                <a
                  class="dropdown-item nav-link"
                  href="#"
                  routerLink="/reports/tcs-report/publishing-ls-file"
                >
                  Publishing LS File
                </a>
                <a
                  class="dropdown-item nav-link"
                  href="#"
                  routerLink="/reports/tcs-report/files-info"
                >
                  File History
                </a>
              </div>
            </li>
          </div>
        </li>
      </ul>
      <div class="nav-item dropdown" ngbDropdown *ngIf="isLoggedIn">
        <a class="nav-link dropdown-toggle" id="userDropdown" ngbDropdownToggle>
          {{username}}
        </a>
        <div class="dropdown-menu" aria-labelledby="userDropdown" ngbDropdownMenu>
          <button class="dropdown-item" (click)="logout()">Logout</button>
        </div>
      </div>
      <select
        class="lang"
        #langSelect
        (change)="translate.use(langSelect.value)"
      >
        <option
          *ngFor="let lang of translate.getLangs()"
          [value]="lang"
          [selected]="lang === translate.currentLang"
        >
          {{ lang }}
        </option>
      </select>
    </div>
  </div>
</nav>
