import { Component, OnInit } from '@angular/core';
import { ScheduleReportService } from './schedules-report.service';
import { Schedule } from '../schedule';
import * as moment from 'moment';

@Component({
  selector: 'app-schedules-report',
  templateUrl: './schedules-report.component.html',
  styleUrls: ['./schedules-report.component.scss']
})
export class SchedulesReportComponent implements OnInit {

  sortByScheduleReportHeaders: any[]
  uncompletedSchedules: Schedule[]
  page: number = 0
  size: number = 20
  currentDate = moment(Date.now()).utcOffset(0, true)
  startDate: string = this.currentDate.format()
  endDate: string = this.currentDate.add(14, "days").format()
  searchString: string = ""
  showLoader: boolean = false
  constructor(
    private scheduleReportService: ScheduleReportService
  ) { }

  ngOnInit(): void {
    this.showLoader = false
    this.sortByScheduleReportHeaders = this.scheduleReportService.defaultScheduleReportSorted()
    this.searchUncompletedSchedules(false)
  }

  searchUncompletedSchedules(isScroll: boolean) {
    const searchData = {
      pageRequest: {
        pageNumber: this.page,
        pageSize: this.size,
      },
      startDate: this.startDate.split("T")[0],
      endDate: this.endDate.split("T")[0],
      channelId: this.searchString
    }
    Object.keys(searchData).forEach(key => searchData[key] === "" ? delete searchData[key] : "")
    this.scheduleReportService.searchUncompletedChannel(searchData).subscribe((data) => {
      if (data && data.response) {
        const schedules = data.response
        if(schedules.length){
          schedules.forEach(schedule => {
            schedule.scheduleDate = moment(schedule.scheduleDate).utcOffset(0,true).format().split("T")[0]
          })
        }
        if (isScroll) {
          this.uncompletedSchedules.push(...schedules)
          this.showLoader = false;
        } else {
          this.uncompletedSchedules = schedules
          this.showLoader = false;
        }
      } else {
        this.uncompletedSchedules = [];
        this.showLoader = false
      }
    })

  }

  onScroll(event) {
    if (this.showLoader === false) {
      event.preventDefault()
      const elem = event.target
      const limit = elem.scrollHeight - elem.clientHeight
      if (elem.scrollTop > 0 && elem.scrollTop + 1 >= limit) {
        elem.scrollTop -= this.uncompletedSchedules.length
        this.page++
        this.showLoader = true
        this.searchUncompletedSchedules(true)
      }
    }
  }

  generateReport() {
    this.showLoader = true
    this.sortByScheduleReportHeaders = this.scheduleReportService.defaultScheduleReportSorted()
    this.page = 0
    this.searchUncompletedSchedules(false)
  }

  onDateChange(isStartDate : boolean){
    isStartDate ? this.startDate = moment(this.startDate).utcOffset(0, false).format() : this.endDate = moment(this.endDate).utcOffset(0,false).format()
  }

  onChangeSorting(headerName: string,index: number, isAsc: boolean){
    const compare = (v1: string | Date, v2: string | Date) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);
    let oldSorting = this.sortByScheduleReportHeaders.findIndex(item => item.isAsc != null)
    if (oldSorting >= 0 && oldSorting != index) {
      this.sortByScheduleReportHeaders[oldSorting].isAsc = null
    }
    this.sortByScheduleReportHeaders[index].isAsc = this.sortByScheduleReportHeaders[index].isAsc != null ? !this.sortByScheduleReportHeaders[index].isAsc : isAsc
    this.uncompletedSchedules = this.uncompletedSchedules.sort((a,b) => {
      var res
      switch(headerName) {
        case 'scheduleDate':
          res = compare(new Date(a[headerName]), new Date(b[headerName]))
          break;
        default:
          res = compare(a[headerName].toLowerCase(), b[headerName].toLowerCase())
      }
      return isAsc === true ? res : -res
    }
    )
  }
}
