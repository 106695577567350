<div class="container-fluid mt-3">
    <div class="page-title">
      <h4>Teams Management</h4>
    </div>
  
    <div class="row mt-2 p-1">
      <div class="col-lg-3 d-none d-lg-block">
        <app-team-list
        pageName = 'team'
        (teamId) = "onTeamIdChange($event)"
        (isCreate) = "onCreateTeam()"
        [newTeamId] = 'newTeamId'
        >
        </app-team-list>
      </div>
      <div class="col-lg-9 pl-1">
        <app-team-form
        (newTeamCreated)="onNewTeamCreated($event)"
        (teamId)="onTeamIdChange($event)"
        (isCreate) = "onCreateTeam()"
        [team] = "selectedTeam"
        [isLoading] = 'isLoading'
        [oldTeam] = 'oldTeam'
        [venues] = 'venues'
        ></app-team-form>
      </div>
    </div>
  </div>
  