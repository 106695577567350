<div class="container-fluid reports-publications mt-3">
    <div class="page-title d-flex justify-content-start">
        <h4>File Generated</h4>
    </div>
    <div class="row">
        <div class="col">
            <div class="panel-header d-flex">
                <!-- <h6>Arquivos Criados</h6> -->
            </div>
            <div class="panel-body p-3">                
                <div class="table-content">
                    <table class="table table-striped">
                        <thead class="bg-dark">
                            <tr>
                                <th scope="col">ServiceId</th>
                                <th scope="col">System</th>
                                <th scope="col">Channel Name</th>
                                <th scope="col">File Name</th>
                                <th scope="col">Priority</th>
                                <th scope="col">Status</th>
                                <th scope="col">Download</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let reportsDetailsPublication of reportsDetailsPublicationsModel">
                                <td>{{ reportsDetailsPublication.sourceId || "--" }}</td>
                                <td>
                                  <div *ngIf="reportsDetailsPublication.system === true">True</div>
                                  <div *ngIf="reportsDetailsPublication.system === false">False</div>
                                </td>
                                <td>{{ reportsDetailsPublication.channelName }}</td>
                                <td>{{ splitLsFileName(reportsDetailsPublication.fileName) }}</td>
                                <td>
                                  <div *ngIf="reportsDetailsPublication.priority === 'IM'">Immediate</div>
                                  <div *ngIf="reportsDetailsPublication.priority === 'FT'">Future</div>
                                </td>
                                <td><div
                                    *ngIf="reportsDetailsPublication.status === '2'"
                                  >
                                    Done
                                  </div>
                                  <div
                                    *ngIf="reportsDetailsPublication.status === '3'"
                                  >
                                    Failed
                                  </div></td>

                                <td>
                                    <a class="nav-link" (click)="downloadLSFile(reportsDetailsPublication.fileName)">
                                        <i class="fa fa-download"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="panel-footer mb-2"></div>
        </div>
    </div>
</div>
