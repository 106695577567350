<div class="container-fluid reports-publications mt-3">
    <div class="page-title d-flex justify-content-start">
        <h4>TCS Report</h4>
    </div>
    <div class="parameters-content">
        <div class="panel-header d-flex">
            <h6>Filter</h6>
        </div>
        <div class="d-flex">
            <div class="mr-auto p-2">
                <div class="form-row align-items-center">
                    <div class="form-check mb-2">
                        <label class="form-check-label" for="displayAllMyRecords">
                            Status:
                        </label>
                    </div>
                    <div class="form-check mb-2">
                        <select class="form-control" id="exampleFormControlSelect1" (change)="filterByStatus($event.target.value)">
                            <option value="" hidden>Search by status</option>
                            <option class="nav-link" value="">All</option>
                            <option class="nav-link" value="2">Done</option>
                            <option class="nav-link" value="3">Failed</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="p-2">
                <div class="form-check mb-2 parameters-checkbox">
                    <input class="form-check-input" type="checkbox" id="displayAllMyRecords" (change)="onDisplayAllChange($event.target.checked)">
                    <label class="form-check-label" for="displayAllMyRecords">
                        Display all
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="panel-header d-flex">
                <h6>Summary</h6>
                <div class="col-3 search-input">
                  <div class="input-group d-flex">
                      <input type="search" class="input-search form-control form-control-sm" placeholder="Search Channel"
                        [(ngModel)]="searchString"         
                        (ngModelChange)="onSearchChange(this.searchString)"/>
                      <div class="input-group-append">
                          <button class="btn btn-light form-control-sm search-btn">
                              <i class="fa fa-search mx-2"></i>
                          </button>
                      </div>
                  </div>
              </div>
            </div>
            <div class="panel-body p-3">
                <ng-container *ngIf="showLoader">
                    <div class="loading">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </ng-container>
                <div class="table-content table-fixed">
                    <table class="table table-striped">
                        <thead class="bg-dark">
                            <tr>
                              <th *ngFor="let header of sortByHistoryHeader; let j = index">
                                <div class="d-flex align-items-end justify-content-start">
                                    {{header.name}}
                                    <div *ngIf="header.isDisplay" class="sort-button ml-2">
                                        <a class="btn btn-xs btn-link p-0" *ngIf="header.isAsc != false" (click)="onChangeSorting(header.fieldName,j, false)">
                                            <i class="fas fa-sort-up sort-icon"></i>
                                        </a>
                                        <a class="btn btn-xs btn-link p-0" *ngIf="header.isAsc != true" (click)="onChangeSorting(header.fieldName,j, true)">
                                            <i class="fas fa-sort-down sort-icon"></i>
                                        </a>
                                    </div>
                                </div>
                            </th>
                            <th>                
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let reportsPublication of reportsPublicationsModel">
                                <td>{{reportsPublication.sourceId || "--"}}</td>
                                <td>{{reportsPublication.channelName}}</td>
                                <td>{{reportsPublication.requestedDateString}}</td>
                                <td>{{reportsPublication.startDateString}}</td>
                                <td>{{reportsPublication.endDateString}}</td>
                                <td>{{reportsPublication.username}}</td>
                                <td><div
                                    *ngIf="reportsPublication.status === '2'"
                                  >
                                    Done
                                  </div>
                                  <div
                                    *ngIf="reportsPublication.status === '3'"
                                  >
                                    Failed
                                  </div></td>
                                <td>
                                    <a class="nav-link" routerLink="/reports/tcs-report/files-info/detail/{{reportsPublication.id}}"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                                        <i class="fa fa-eye"></i>
                                    </a>
                                </td>
                                <td>
                                  <div
                                    *ngIf="reportsPublication.status === '2'"
                                  >
                                    <input type="checkbox" [disabled]="true"/>
                                  </div>

                                  <div
                                    *ngIf="reportsPublication.status === '3'"
                                  >
                                    <input type="checkbox" (click)="updateListLsInfo(reportsPublication,$event.target.checked)"
                                           [checked]="reportsPublication.checked" />
                                  </div>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
          <div class="panel-footer justify-content-end mb-2">
            <div class="lsHistoryPagination">
              <ngb-pagination
              [(page)]="page"
              [pageSize]="pageSize"
              [maxSize]="10"
              [collectionSize]="collectionSize"
              (pageChange) = "onPageChange()"
            >
            </ngb-pagination>
            </div>
            <div class="float-right">
              <button class="btn btn-sm btn-att mr-2 mt-2"  [disabled]="disableResendButton()"  (click)="resendPublisher()">
                Resend
              </button>
            </div>
          </div>
        </div>
    </div>
</div>
