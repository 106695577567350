<div class="container-fluid mt-3">
    <div class="page-title">
        <h4>Generate ECAD Report</h4>
    </div>
    <div class="header-channel-managerment-container">
        <div>
            <div class="search input-group">
                <input type="search" class="form-control" placeholder="Search channels" [(ngModel)]="searchString" />
                <div class="input-group-append">
                    <span class="input-group-text" (click)="onSearch()">
                        <i class="fa fa-search"></i>
                    </span>
                </div>
            </div>
        </div>
        <div class="control-container">
            <label class="label-for-date">
                Start date &nbsp;
            </label>
            <div>
                <input  class="form-control" id="startDate" (ngModel) ="startDate" (input) = "onStartDateChange($event.target.value)" (change)="onFieldChange($event)" [owlDateTimeTrigger]="dt" [owlDateTime]="dt"  placeholder="Select date" />
                <owl-date-time    [pickerType]="'calendar'" (afterPickerClosed)="onDatePickerClose()" (afterPickerOpen)="onDatePickerOpen()"    
                    [startView]="'multi-years'"     (yearSelected)="chosenYearHandler($event)"    
                    (monthSelected)="chosenMonthHandler($event, dt)"     #dt="owlDateTime"  >
                </owl-date-time>
                <!-- <owl-date-time #dt1 [startView]="'year'"></owl-date-time> -->
            </div>
        </div>
        <div class="control-container">
            <label class="label-for-date">
                Months &nbsp;
            </label>
            <div>
                <input
                    oninput="this.value = !!this.value && Math.abs(this.value) >0 && Math.abs(this.value)<=12 ? Math.abs(this.value): 1"
                    type="number" [min]="1" [max]="12" onkeydown="return event.keyCode !== 69 && event.keyCode !== 190"
                    class="form-control" [(ngModel)]="monthPeriod" id="monthPeriod" (change)="onFieldChange($event)" />
            </div>
        </div>
        <div class="control-container">
            <button class="btn btn-sm btn-att ml-2" type="button" (click)="onGenerateReport()" [disabled]="isLoading">Generate report</button>
        </div>
    </div>
    <div class="margin-top-30">
        <div class="ecad-panel margin-top-20">
            <div class="panel-header header-channel-container">
                <div class="page-title">
                    <h5 class="h5-title">Channels</h5>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="selectAll" [checked]="selectAll"
                        (change)="onSelectAllChange($event)" />
                    <label class="form-check-label">Select all</label>
                </div>
            </div>
            <div class="panel-body p-2">
                <ng-container *ngIf="isLoading">
                    <div class="loading">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                </ng-container>
                <div class="checkbox-group">
                    <div style="width: 300px;" *ngFor="let referenceChannel of allReferenceChannels;let i = index">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" [checked]="referenceChannel.selected"
                                [(ngModel)]="allReferenceChannels[i].selected">
                            <label class="form-check-label"> {{referenceChannel.name}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>