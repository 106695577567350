<div class="modal-header">
  <h4 class="modal-title">Manage Blackout Regions</h4>
  <button type="button" class="close" aria-label="Close" (click)="onCloseModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-6">
      <h6>Available Channel Regions</h6>
      <ul class="list-group" *ngFor="let region of availableRegions()">
        <li (click)="onAddingRegion(region)"
            class="list-group-item p-1 mb-1 d-flex justify-content-between align-items-center pointer">
          {{ region.regionName }}
          <i class="fas fa-chevron-right"></i>
        </li>
      </ul>
    </div>
    <div class="col-6">
      <h6>Added Blackout Regions</h6>
      <ul class="list-group" *ngFor="let regionId of inUsedRegions">
        <li (click)="onRemovingRegion(regionId)"
            class="list-group-item p-1 mb-1 d-flex justify-content-between align-items-center pointer">
          {{ displayRegion(regionId) }}
          <i class="fas fa-chevron-left"></i>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="modal-footer d-flex justify-content-start">
  <div class="control-group">
    <div class="controls">
      <label>
        <input type="checkbox" [checked]="mobileBlackout" (change)="onCheckMobileBlackout($event)"/>
        Mobile Blackout
      </label>
    </div>
  </div>
  <button class="btn btn-att ml-auto" (click)="onApplyingBlackout(mobileBlackout, inUsedRegions)">
    Apply Changes
  </button>
</div>
