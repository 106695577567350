<div class="apg-report container-fluid mt-3">
    <div class="d-flex justify-content-between p2">
        <h4 class="page-title">APG Streamcopy Upload History</h4>
    </div>
    <div class="col mt-2">
        <ul ngbNav #navAsset="ngbNav" class="nav-tabs">
            <li [ngbNavItem]="CONSTANTS.GO" (click)="onTabChange(CONSTANTS.GO)">
                <a ngbNavLink>{{CONSTANTS.GO}}</a>
                <ng-template ngbNavContent>
                    <app-apg-go-report [searchString]="searchString"></app-apg-go-report>
                </ng-template>
            </li>
            <li [ngbNavItem]="CONSTANTS.EPO" (click)="onTabChange(CONSTANTS.EPO)">
                <a ngbNavLink>{{CONSTANTS.EPO}}</a>
                <ng-template ngbNavContent>
                    <app-apg-epo-report [searchString]="searchString"></app-apg-epo-report>
                </ng-template>
            </li>
            <li [ngbNavItem]="CONSTANTS.BIO" (click)="onTabChange(CONSTANTS.BIO)">
                <a ngbNavLink>{{CONSTANTS.BIO}}</a>
                <ng-template ngbNavContent>
                    <app-apg-bio-report [searchString]="searchString"></app-apg-bio-report>
                </ng-template>
            </li>
            <li class="nav-item ml-auto">
                <div class="input-group">
                    <input id="input-search" type="search" class="form-control" [placeholder]="currentTab == CONSTANTS.BIO ? 'Search by Person ID' : 'Search by Program ID'" [(ngModel)]="searchString"/>
                    <div class="input-group-append">
                        <span class="input-group-text">
                            <i class="fa fa-search"></i>
                        </span>
                    </div>
                </div>
            </li> 
        </ul>
        <div [ngbNavOutlet]="navAsset" class="bg-white p-1"></div>
    </div>
</div>

