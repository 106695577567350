<div class="container-fluid mt-3">
    <div class="page-title">
      <h4>Asset Management</h4>
    </div>
  
    <div class="row mt-2 p-1">
      <div class="col-lg-2 d-flex d-lg-none">
        <div class="col-lg-6">
          <app-assets-list
            pageName="assets"
            (assetId)="onAssetIdChange($event)"
          >
          </app-assets-list>
        </div>
      </div>
      <div class="col-lg-3 d-none d-lg-block">
        <app-assets-list
          pageName="assets"
          (assetId)="onAssetIdChange($event)"
        >
        </app-assets-list>
      </div>
      <div class="col-lg-9">
        <app-assets-form
          [asset]="asset"
          [program]="program"
          (assetId)="onAssetIdChange($event)"
          [isLoading] = "isLoading"
          [programId] ="programId"
          [wideVineInfo] ='wideVineInfo'
          [playReadyInfo] = 'playReadyInfo'
          [fairPlayInfo] = 'fairPlayInfo'
        ></app-assets-form>
      </div>
    </div>
  </div>