<div class="asset-form">
    <div class="panel-header d-flex">
      <div class="d-flex justify-content-start">
        <h6 >
          Detail | Status:
          <span [className]="'badge badge-' + getAssetTypeStyle(asset.published)">
            {{getAssetStatus(asset.published)}}
          </span>
        </h6>
      </div>
    </div>
    <div class="panel-body" id="programForm">
      <div class="container-fluid">
        <ng-container *ngIf="isLoading">
          <div class="loading">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </ng-container>
        <div class="title-desc mt-2">
          <div class="info-title">
            <h5>Asset Info</h5>
          </div>
          <div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group has-validation">
                    <label for="dmsId">{{ 'vls.VOD-assets.form.fields.dmsId' | translate }}</label>
                    <input id="dmsId" type="text" class="form-control" [(ngModel)]="asset.dmsId" disabled />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="programMapperId">{{ 'vls.VOD-assets.form.fields.programMapperId' | translate }}</label>
                    <input id="programMapperId" type="text" class="form-control" [(ngModel)]="asset.programMapperId" disabled/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="assetId">{{ 'vls.VOD-assets.form.fields.assetId' | translate }}</label>
                    <input id="assetId" type="text" class="form-control"[(ngModel)]="asset.assetId" disabled  />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="programId">{{ 'vls.VOD-assets.form.fields.programId' | translate }}</label>
                    <input id="programId" type="text" class="form-control" [(ngModel)]="asset.programId" disabled/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="vrioId">{{ 'vls.VOD-assets.form.fields.vrioId' | translate }}</label>
                    <input id="vrioId" type="text" class="form-control" [(ngModel)]="asset.vrioId" disabled/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="serviceProvider">{{ 'vls.VOD-assets.form.fields.serviceProvider' | translate }}</label>
                    <input id="serviceProvider" type="text" class="form-control" [(ngModel)]="asset.serviceProviderInfo.serviceProvider.name" disabled/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="serviceCategory">{{ 'vls.VOD-assets.form.fields.serviceCategory' | translate }}</label>
                    <input id="serviceCategory" type="text" class="form-control" [(ngModel)]="asset.serviceProviderInfo.serviceProviderCategory.name" disabled/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group has-validation">
                    <label for="assetTitle">{{ 'vls.VOD-assets.form.fields.assetTitle' | translate }}</label>
                    <input id="assetTitle" type="text" class="form-control" [(ngModel)]="asset.title" disabled/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="channels">{{ 'vls.VOD-assets.form.fields.channels' | translate }}</label>
                    <input id="channels" type="text" class="form-control" [(ngModel)]="asset.channelsString" disabled/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group has-validation">
                    <label for="displaySeasonNumber">{{ 'vls.VOD-assets.form.fields.displaySeasonNumber' | translate }}</label>
                    <input id="displaySeasonNumber" type="text" class="form-control" [(ngModel)]="asset.displaySeasonNumber" disabled/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="displayEpisodeNumber">{{ 'vls.VOD-assets.form.fields.displayEpisodeNumber' | translate }}</label>
                    <input id="displayEpisodeNumber" type="text" class="form-control" [(ngModel)]="asset.displayEpisodeNumber" disabled/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="avaiStartDate">{{ 'vls.VOD-assets.form.fields.avaiStartDate' | translate }}</label>
                    <input id="avaiStartDate" type="datetime" class="form-control" [(ngModel)]="asset.availabilityStartsAt" disabled/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="avaiEndDate">{{ 'vls.VOD-assets.form.fields.avaiEndDate' | translate }}</label>
                    <input id="avaiEndDate" type="datetime" class="form-control" [(ngModel)]="asset.availabilityEndsAt" disabled/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="firstReceivedDate">{{ 'vls.VOD-assets.form.fields.firstReceivedDate' | translate }}</label>
                    <input id="firstReceivedDate" type="datetime" class="form-control" [(ngModel)]="asset.createdDate" disabled/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="updatedDate">{{ 'vls.VOD-assets.form.fields.updatedDate' | translate }}</label>
                    <input id="updatedDate" type="datetime" class="form-control" [(ngModel)]="asset.updatedDate"disabled/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="title-desc mt-2">
          <div class="info-title">
            <h5>Program Info</h5>
          </div>
          <div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group has-validation">
                    <label for="englishTitle">{{ 'vls.VOD-assets.form.fields.englishTitle' | translate }}</label>
                    <input id="englishTitle" type="text" class="form-control" [(ngModel)] ="program.englishTitle.value" disabled/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="spanishTirle">{{ 'vls.VOD-assets.form.fields.spanishTitle' | translate }}</label>
                    <input id="spanishTitle" type="text" class="form-control" [(ngModel)] ="program.spanishTitle.value" disabled/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="originalTitle">{{ 'vls.VOD-assets.form.fields.originalTitle' | translate }}</label>
                    <input id="originalTitle" type="text" class="form-control" [(ngModel)] ="program.originalTitle.value" disabled/>
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label for="programType">{{ 'vls.VOD-assets.form.fields.programType' | translate }}</label>
                    <input id="programType" type="text" class="form-control" [(ngModel)]="program.programType" disabled/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="tmsId">{{ 'vls.VOD-assets.form.fields.tmsId' | translate }}</label>
                    <input id="tmsId" type="text" class="form-control" [(ngModel)]="program.tmsId.id" disabled/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="season">{{ 'vls.VOD-assets.form.fields.season' | translate }}</label>
                    <input id="season" type="text" class="form-control" [(ngModel)] ="program.seasonNumber" disabled
                    />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="episode">{{ 'vls.VOD-assets.form.fields.episode' | translate }}</label>
                    <input id="episode" type="text" class="form-control" [(ngModel)] ="program.episodeNumber" disabled />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="alternateSeason">{{ 'vls.VOD-assets.form.fields.alternateSeason' | translate }}</label>
                    <input id="alternateSeason" type="text" class="form-control" [(ngModel)] ="episodeAltMappingComputed.seasonNumber" disabled/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="alternateEpisode">{{ 'vls.VOD-assets.form.fields.alternateEpisode' | translate }}</label>
                    <input id="alternateEpisode" type="text" class="form-control" [(ngModel)] ="episodeAltMappingComputed.episodeNumber" disabled/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="seasonYear">{{ 'vls.VOD-assets.form.fields.seasonYear' | translate }}</label>
                    <input id="seasonYear" type="text" class="form-control" [(ngModel)] ="program.releaseYear" disabled/>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="originalAirDate">{{ 'vls.VOD-assets.form.fields.originalAirDate' | translate }}</label>
                    <input id="origAirDate" type="date" class="form-control" [(ngModel)] ="program.origAirDate" disabled />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="englishDescription">{{ 'vls.VOD-assets.form.fields.englishDescription' | translate }}</label>
                    <textarea id="englishDescription" class="form-control" [(ngModel)] ="program.englishDescription.value" disabled >
                    </textarea>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="spanishDescription">{{ 'vls.VOD-assets.form.fields.spanishDescription' | translate }}</label>
                    <textarea id="spanishDescription" class="form-control" [(ngModel)] ="program.spanishDescription.value" disabled>
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="col">
              <div class="row">
                <div class="col-2">
                  <div class="form-group has-validation">
                    <label for="runTime">{{ 'vls.VOD-assets.form.fields.runTime' | translate }}</label>
                    <input id="runTime" type="text" class="form-control" [(ngModel)] ="program.runTime" disabled/>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label for="rating">{{ 'vls.VOD-assets.form.fields.rating' | translate }}</label>
                    <input id="rating" type="text" class="form-control" [(ngModel)] = "program.ratingString" disabled/>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label for="ratingDescriptor">{{ 'vls.VOD-assets.form.fields.ratingDescriptor' | translate }}</label>
                    <input id="ratingDescriptor" type="text" class="form-control" [(ngModel)] = "program.ratingContentDescriptor" disabled/>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label for="genres">{{ 'vls.VOD-assets.form.fields.genres' | translate }}</label>
                    <input id="genres" type="text" class="form-control" [(ngModel)] = "program.genres" disabled/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3 pl-3">
          <ul ngbNav #navMain="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs">
            <li [ngbNavItem]="1" [destroyOnHide]="true">
              <a ngbNavLink>Cast and Crew</a>
              <ng-template ngbNavContent>
                <div class="row mt-4 mx-1" *ngIf="program.credits?.length > 0">
                  <div class="col" *ngIf="program.groupedCast?.length > 0">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th class="col-5">Cast Name</th>
                          <th class="col-5">Role</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="cursor-pointer"
                          *ngFor="let cast of program.groupedCast; let i = index"
                        >
                          <td>
                            {{ cast.firstName || cast.name?.firstName || '' }}
                            {{ cast.lastName || cast.name?.lastName || '' }}
                          </td>
                          <td>
                            {{ cast.role?.value || cast.role || 'None' }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col" *ngIf="program.groupedCrew?.length > 0">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th class="col-5">Crew Name</th>
                          <th class="col-5">Role</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="cursor-pointer"
                          *ngFor="let crew of program.groupedCrew; let i = index"
                        >
                          <td>
                            {{ crew.firstName || crew.name?.firstName || '' }}
                            {{ crew.lastName || crew.name?.lastName || '' }}
                          </td>
                          <td>
                            {{ crew.role?.value || crew.role || 'None' }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  class="alert alert-warning mt-4"
                  role="alert"
                  *ngIf="!program.credits?.length || program.credits?.length == 0"
                  style="margin-left: 10px; margin-right: 10px"
                >
                  No cast and crew added
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="2" [destroyOnHide]="true">
              <a ngbNavLink>Provider Images</a>
              <ng-template ngbNavContent>
                <div
                  class="mt-2 program-pictures-list"
                  *ngIf="asset.images?.length"
                >
                  <div
                    class="
                      row
                      no-gutters
                      row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5
                    "
                  >
                    <div
                      *ngFor="let image of asset.images; let i = index"
                      class="col pb-2"
                    >
                      <ng-container
                        [ngTemplateOutlet]="programImageCard"
                        [ngTemplateOutletContext]="{
                          image: image,
                          i: i,
                          published: true
                        }"
                      ></ng-container>
                    </div>
                  </div>
                </div>
                <ul
                  ngbNav
                  #navImages="ngbNav"
                  [(activeId)]="navImage"
                  class="nav-pills p-2 mt-1"
                >
                </ul>
                <ng-template
                  #programImageCard
                  let-image="image"
                  let-i="i"
                  let-published="published"
                >
                  <div class="card program-image mb-2">
                    <div
                      class="card-header d-flex justify-content-start"
                      *ngIf="selectedProvider !== CONSTANTS.GRACENOTE_PROVIDER"
                    >
                    </div>
                    <div class="card-body">
                      <img
                        class="card-img-top cast-crew-img"
                        [src]="
                          image.uri
                            ? 'http://tmsimg.com/' + image.uri
                            : previewUrl
                        "
                        style="object-fit: contain; width: 100%"
                        (error)="onImageError($event)"
                      />
                    </div>
                    <div class="card-footer">
                      <p
                        style="
                          margin-bottom: 0px;
                          text-align: left;
                          display: flex;
                          flex-direction: column;
                        "
                        class="ml-auto"
                      >
                        <span>
                          <a
                            ngbTooltip="{{ image.id }}"
                            target="_blank"
                            href="http://tmsimg.com/{{ image.uri }}"
                          >
                            Category: {{ image.category }}
                          </a>
                          <a
                            ngbTooltip="{{ image.id }}"
                            target="_blank"
                            href="{{
                              image.uri ? image.baseUrl + image.uri : previewUrl
                            }}"
                          >
                            Category: {{ image.category }}
                          </a>
                        </span>
                        <span class="font-weight-bold">
                          Ratio: {{ image.ratio }}
                        </span>
                        <span class="font-weight-bold">
                          Source: {{
                            image.source === CONSTANTS.GRACENOTE_PROVIDER
                              ? CONSTANTS.GN
                              : image.source === CONSTANTS.VLS || !image.source
                              ? CONSTANTS.VLS
                              : CONSTANTS.VLS
                          }}
                        </span>
                        <span
                          class="font-weight-bold"
                          *ngIf="image.expiredDate !== undefined && image.expiredDate !== null"
                          >Expired date: {{ image.expiredDate | date: CONSTANTS.DATETIME_FORMAT }} 
                        </span>
                      </p>
                    </div>
                  </div>
                </ng-template>
                <div [ngbNavOutlet]="navImages" class="pl-2 pr-2"></div>
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>Program Images</a>
              <ng-template ngbNavContent>
                <div
                  class="mt-2 program-pictures-list"
                  *ngIf="
                    program.publishedImages?.length &&
                    selectedProvider !== CONSTANTS.GRACENOTE_PROVIDER
                  "
                >
                  <div
                    class="
                      row
                      no-gutters
                      row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5
                    "
                  >
                    <div
                      *ngFor="let image of program.publishedImages; let i = index"
                      class="col pb-2"
                    >
                      <ng-container
                        [ngTemplateOutlet]="programImageCard"
                        [ngTemplateOutletContext]="{
                          image: image,
                          i: i,
                          published: true
                        }"
                      ></ng-container>
                    </div>
                  </div>
                </div>
                <ul
                  ngbNav
                  #navImages="ngbNav"
                  [(activeId)]="navImage"
                  class="nav-pills p-2 mt-1"
                >
                </ul>
                <ng-template
                  #programImageCard
                  let-image="image"
                  let-i="i"
                  let-published="published"
                >
                  <div class="card program-image mb-2">
                    <div
                      class="card-header d-flex justify-content-start"
                      *ngIf="selectedProvider !== CONSTANTS.GRACENOTE_PROVIDER"
                    >
                      <span
                        *ngIf="image.published"
                        class="pub-span badge badge-success"
                        >PUB </span
                      >
                      <span
                        container="body"
                        class="badge badge-warning pub-span"
                        *ngIf="!image.published"
                      >
                        UNPUB
                      </span>
                    </div>
                    <div class="card-body">
                      <img
                        class="card-img-top cast-crew-img"
                        [src]="
                          image.uri
                            ? image.baseUrl + image.uri
                            : image.imageURL || previewUrl
                        "
                        style="object-fit: contain; width: 100%"
                        (error)="onImageError($event)"
                        *ngIf="selectedProvider !== CONSTANTS.GRACENOTE_PROVIDER"
                      />
                      <img
                        class="card-img-top cast-crew-img"
                        [src]="
                          image.uri
                            ? 'http://tmsimg.com/' + image.uri
                            : previewUrl
                        "
                        style="object-fit: contain; width: 100%"
                        (error)="onImageError($event)"
                        *ngIf="selectedProvider === CONSTANTS.GRACENOTE_PROVIDER"
                      />
                    </div>
                    <div class="card-footer">
                      <p
                        style="
                          margin-bottom: 0px;
                          text-align: left;
                          display: flex;
                          flex-direction: column;
                        "
                        class="ml-auto"
                      >
                        <span>
                          <a
                            *ngIf="selectedProvider === CONSTANTS.GRACENOTE_PROVIDER"
                            ngbTooltip="{{ image.id }}"
                            target="_blank"
                            href="http://tmsimg.com/{{ image.uri }}"
                          >
                            Category: {{ image.category }}
                          </a>
                          <a
                            *ngIf="selectedProvider !== CONSTANTS.GRACENOTE_PROVIDER"
                            ngbTooltip="{{ image.id }}"
                            target="_blank"
                            href="{{
                              image.uri ? image.baseUrl + image.uri : previewUrl
                            }}"
                          >
                            Category: {{ image.category }}
                          </a>
                        </span>
                        <span class="font-weight-bold">
                          Ratio: {{ image.ratio }}
                        </span>
                        <span class="font-weight-bold">
                          Source: {{
                            image.source === CONSTANTS.GRACENOTE_PROVIDER
                              ? CONSTANTS.GN
                              : image.source === CONSTANTS.VLS || !image.source
                              ? CONSTANTS.VLS
                              : CONSTANTS>DMS
                          }}
                        </span>
                        <span
                          class="font-weight-bold"
                          *ngIf="image.expiredDate !== undefined && image.expiredDate !== null"
                          >Expired date: {{ image.expiredDate | date: CONSTANTS.DATETIME_FORMAT }} 
                        </span>
                      </p>
                    </div>
                  </div>
                </ng-template>
                <div [ngbNavOutlet]="navImages" class="pl-2 pr-2"></div>
              </ng-template>
            </li>
            <li [ngbNavItem]="4" >
              <a ngbNavLink>Asset URL Info</a>
              <ng-template ngbNavContent *ngIf ="wideVineInfo.drmInfo.drm">
                <br>
                <ul ngbNav #nav="ngbNav" class="nav-tabs">
                  <li [ngbNavItem]="5">
                    <a ngbNavLink>Widevine Info</a>
                    <ng-template ngbNavContent >
                      <container class="drm-info-container" >
                        <div class="info-block">
                        <div>
                          <strong>
                            DRM Info
                          </strong>
                        </div>
                        <div class="url-info-block" >                       
                          <label>
                            Name: {{wideVineInfo.drmInfo.drm}}
                          </label><br>
                          <label>
                            Vendor ID: {{wideVineInfo.drmInfo.vendorId}}
                          </label><br>
                          <label>
                            Vendor Name: {{wideVineInfo.drmInfo.vendorName}}
                          </label><br>
                          <label>
                            DRM ID: {{asset.drmId}}
                          </label><br>
                          <label>
                            Certificate URL: {{wideVineInfo.drmInfo.certificateUrl}}
                          </label><br>
                          <label>
                            License URL: {{wideVineInfo.drmInfo.licenseUrl}}
                          </label><br>
                        </div> 
                      </div>                  
                      </container>
                      <container class="cdn-info-container" style="white-space: nowrap;">
                        <div class="info-block">
                        <div>
                          <strong>
                            CDN Info
                          </strong>
                        </div>
                        <div class="url-info-block">
                          <label>
                            Vendor ID: {{wideVineInfo.cdnInfo.vendorId}}
                          </label><br>
                          <label>
                            Vendor Name: {{wideVineInfo.cdnInfo.vendorName}}
                          </label><br>
                          <label>
                            Country: {{wideVineInfo.cdnInfo.cdnCountry}}
                          </label><br>
                          <label>
                            Base URL: {{wideVineInfo.cdnInfo.baseUrl}}
                          </label><br>
                          <label>
                            Channel ID: {{wideVineInfo.cdnInfo.cdnChannelId}}
                          </label><br>
                        </div>         
                      </div>          
                      </container>                     
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="6">
                    <a ngbNavLink>Playready Info</a>
                    <ng-template ngbNavContent *ngIf ="playReadyInfo.drmInfo.drm">
                      <container class="drm-info-container">
                        <div class="info-block">
                        <div >
                          <strong>
                            DRM Info
                          </strong>
                        </div>
                        <div class="url-info-block">
                          <label>
                            Name: {{playReadyInfo.drmInfo.drm}}
                          </label><br>
                          <label>
                            Vendor ID: {{playReadyInfo.drmInfo.vendorId}}
                          </label><br>
                          <label>
                            Vendor Name: {{playReadyInfo.drmInfo.vendorName}}
                          </label><br>
                          <label>
                            DRM ID: {{asset.drmId}}
                          </label><br>
                          <label>
                            Certificate URL: {{playReadyInfo.drmInfo.certificateUrl}}
                          </label><br>
                          <label>
                            License URL: {{playReadyInfo.drmInfo.licenseUrl}}
                          </label><br>
                        </div>     
                      </div>              
                      </container>
                      <container class="cdn-info-container">
                        <div class="info-block">
                        <div>
                          <strong>
                            CDN Info
                          </strong>
                        </div>
                        <div class="url-info-block">
                          <label>
                            Vendor ID: {{playReadyInfo.cdnInfo.vendorId}}
                          </label><br>
                          <label>
                            Vendor Name: {{playReadyInfo.cdnInfo.vendorName}}
                          </label><br>
                          <label>
                            Country: {{playReadyInfo.cdnInfo.cdnCountry}}
                          </label><br>
                          <label>
                            Base URL: {{playReadyInfo.cdnInfo.baseUrl}}
                          </label><br>
                          <label>
                            Channel ID: {{playReadyInfo.cdnInfo.cdnChannelId}}
                          </label><br>
                        </div>    
                      </div>               
                      </container>
                    </ng-template>
                  </li>
                  <li [ngbNavItem]="7">
                    <a ngbNavLink>Fairplay Info</a>
                    <ng-template ngbNavContent *ngIf ="fairPlayInfo.drmInfo.drm">
                      <container class="drm-info-container">
                        <div class="info-block">
                        <div>
                          <strong>
                            DRM Info
                          </strong>
                        </div>
                        <div class="url-info-block">
                          <label>
                            Name: {{fairPlayInfo.drmInfo.drm}}
                          </label><br>
                          <label>
                            Vendor ID: {{fairPlayInfo.drmInfo.vendorId}}
                          </label><br>
                          <label>
                            Vendor Name: {{fairPlayInfo.drmInfo.vendorName}}
                          </label><br>
                          <label>
                            DRM ID: {{asset.drmId}}
                          </label><br>
                          <label>
                            Certificate URL: {{fairPlayInfo.drmInfo.certificateUrl}}
                          </label><br>
                          <label>
                            License URL: {{fairPlayInfo.drmInfo.licenseUrl}}
                          </label><br>
                        </div>               
                      </div>    
                      </container>
                      <container class="cdn-info-container">
                        <div class="info-block">
                        <div>
                          <strong>
                            CDN Info
                          </strong>
                        </div>
                        <div class="url-info-block">
                          <label>
                            Vendor ID: {{fairPlayInfo.cdnInfo.vendorId}}
                          </label><br>
                          <label>
                            Vendor Name: {{fairPlayInfo.cdnInfo.vendorName}}
                          </label><br>
                          <label>
                            Country: {{fairPlayInfo.cdnInfo.cdnCountry}}
                          </label><br>
                          <label>
                            Base URL: {{fairPlayInfo.cdnInfo.baseUrl}}
                          </label><br>
                          <label>
                            Channel ID: {{fairPlayInfo.cdnInfo.cdnChannelId}}
                          </label><br>
                        </div>                
                      </div>   
                      </container>
                    </ng-template>
                  </li>               
                </ul> 
                <div 
                  *ngIf = "!wideVineInfo.drmInfo.drm && !playReadyInfo.drmInfo.drm && !fairPlayInfo.drm"
                  class="alert alert-warning mt-4"
                  role="alert"
                  style="margin-left: 10px; margin-right: 10px"
                  >
                No URL Information
                </div> 
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
              </ng-template>                       
            </li>
          </ul>
          <div [ngbNavOutlet]="navMain" class="mt-2 w-100 p-2"></div>
        </div>
      </div>
    </div>
    <div class="panel-footer mb-2 d-flex justify-content-between p-1">
    </div>
  
    <!-- Common header modal template -->
    <ng-template #modalHeader id="modalHeader" let-header="headerTitle">
      <div class="modal-header">
        <h4 class="modal-title">{{ header }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </ng-template>
  
    <!-- Common table actions template for releases, awards and keywords list  -->
    <ng-template #tableActions let-modalName="modalName" let-entity="entity" let-fieldName="fieldName"
      let-filterName="filterName" let-id="id">
      <span class="cursor-pointer" (click)="openModal(modalName, entity, fieldName, filterName, id)">
        <i class="fas fa-edit"></i>
      </span>
      <span class="cursor-pointer" (click)="openDeleteModal(fieldName, filterName, id)">
        <i class="far fa-window-close ml-2"></i>
      </span>
    </ng-template>
  </div>