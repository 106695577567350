<div class="panel-footer">
  <div class="schedules-footer-actions float-left ml-2">
    <button [disabled]="isSchedulesLoading" class="btn btn-sm btn-att ml-2"
                (click)="saveSchedules()"
              >
      <i *ngIf="isSchedulesLoading" class="fas fa-spinner fa-spin"></i>
      {{ 'vls.schedules.form.buttons.quickSave' | translate }}
    </button>
  </div>
  <div class="schedules-footer-actions float-right">
    <button id="submitSchedule" class="btn btn-sm btn-att ml-2" *ngIf="this.selectedScheduleEvents.length===0"
            [disabled]="isSchedulesLoading" (click)="publishSchedules()">
      <i *ngIf="isSchedulesLoading" class="fas fa-spinner fa-spin"></i>
      {{ 'vls.schedules.form.buttons.publish' | translate }}
    </button>
    <button *ngIf="selectedScheduleEvents.length>0" class="btn btn-sm btn-att ml-2"
                    (click)="onManageQualifiersClicked()">
      Manage Qualifiers
    </button>
    <button *ngIf="selectedScheduleEvents.length>0" class="btn btn-sm btn-att ml-2"
                      (click)="onManageBlackoutClicked()">
      Manage Blackout
    </button>
    <button *ngIf="selectedScheduleEvents.length>0" class="btn btn-sm btn-att ml-2"
            (click)="onRemoveSelectedEventsClicked()">
      {{ 'vls.schedules.form.buttons.remove' | translate }}
    </button>
  </div>
</div>

