import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { LoginComponent } from './pages/login/login.component'
import { SchedulesComponent } from './pages/schedules/schedules.component'
import { ProgramsComponent } from './pages/programs/programs.component'
import { SchedulesCopyComponent } from './pages/schedules-copy/schedules-copy.component'
import { ChannelManagementComponent } from './pages/channel-management/channel-management.component'
import { ChannelTransmitComponent } from './pages/channel-transmit/channel-transmit.component'
import { UsersComponent } from './pages/users/users.component'
import {ProgramMappingComponent} from "./pages/program-mapping/program-mapping.component";
import { AuthGuard } from "./services/auth-guard.service";
import { PlayersComponent } from './pages/players/players.component'
import { VenuesComponent } from './pages/venues/venues.component'
import { TeamManagementComponent } from './pages/world-cup-team/team-management/team-management.component'
import { EcadComponent } from './pages/ecad/ecad.component';
import { ReportsPublicationScheduleComponent } from './pages/reports-publication-schedule/reports-publication-schedule.component'
import { ReportsPublicationsComponent } from './pages/reports-publications/reports-publications.component'
import { ReportsPublicationsDetailsComponent } from './pages/reports-publications/reports-publications-details/reports-publications-details.component'
import { APGReportComponent } from './pages/apg-report/apg-report.component'
import { DTHPermission } from './services/dth-permission.service'
import { AssetsManagementComponent } from './pages/assets-management/assets-management.component'
import { AssetReportsComponent } from './pages/asset-reports/asset-reports.component'
import { SchedulesReportComponent } from './pages/schedules/schedules-report/schedules-report.component'

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  {
    path: 'schedules',
    component: SchedulesComponent,
    data: { title: 'Schedules' },
    canActivate: [AuthGuard],
  },
  {
    path: 'schedules-copy',
    component: SchedulesCopyComponent,
    data: { title: 'Schedules' },
    canActivate: [AuthGuard],
  },
  {
    path: 'schedules-report',
    component: SchedulesReportComponent,
    data: { title: 'Schedules Report' },
    canActivate: [AuthGuard],
  },
  {
    path: 'programs',
    component: ProgramsComponent,
    data: { title: 'Programs' },
    canActivate: [AuthGuard],
  },
  {
    path: 'programs/:id',
    component: ProgramsComponent,
    data: { title: 'Programs' },
    canActivate: [AuthGuard],
  },
  {
    path: 'channel/management',
    component: ChannelManagementComponent,
    data: { title: 'Channels' },
    canActivate: [AuthGuard],
  },
  {
    path: 'channel/transmit',
    component: ChannelTransmitComponent,
    data: { title: 'Channels' },
    canActivate: [AuthGuard],
  },
  {
    path: 'users',
    component: UsersComponent,
    data: { title: 'User' },
    canActivate: [AuthGuard],
  },
  {
    path: 'program-mapping',
    component: ProgramMappingComponent,
    data: { title: 'Program Mapping' },
    canActivate: [AuthGuard],
  },
  {
    path: 'players',
    component: PlayersComponent,
    data: { title: 'Players' },
    canActivate: [AuthGuard],
  },
  {
    path: 'venues',
    component: VenuesComponent,
    data: { title: 'Venues' },
    canActivate: [AuthGuard],
  },
  {
    path: 'soccer/teams',
    component: TeamManagementComponent,
    data: { title: 'Teams' },
    canActivate: [AuthGuard],
  },
  {
    path: 'reports/ecad',
    component: EcadComponent,
    data: { title: 'Generate ECAD Report' },
    canActivate: [AuthGuard, DTHPermission],
  },
  {
    path: 'reports/tcs-report/publishing-ls-file',
    component: ReportsPublicationScheduleComponent,
    data: { title: 'TCS Reports' },
    canActivate: [AuthGuard, DTHPermission],
  },
  {
    path: 'reports/tcs-report/files-info',
    component: ReportsPublicationsComponent,
    data: { title: 'TCS Reports' },
    canActivate: [AuthGuard, DTHPermission],
  },
  {
    path: 'reports/tcs-report/files-info/detail/:id',
    component: ReportsPublicationsDetailsComponent,
    data: { title: 'TCS Reports' },
    canActivate: [AuthGuard, DTHPermission],
  },
  {
    path: 'reports/apg-report',
    component: APGReportComponent,
    data: { title: 'APG Streamcopy Upload History' },
    canActivate: [AuthGuard, DTHPermission],
  },
  {
    path: 'vod-assets-management',
    component: AssetsManagementComponent,
    data: {title: 'VOD Assets'},
    canActivate: [AuthGuard],
  },
  {
    path: 'vod-asset-reports',
    component: AssetReportsComponent,
    data: {title: 'VOD Assets Reports'},
    canActivate: [AuthGuard],
    }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
