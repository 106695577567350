<div class="container-fluid mt-3">
  <div class="page-title">
    <h4>Program Mapping</h4>
  </div>
  <div class="row mt-2 p-1">
    <div class="col-lg-8">
      <div class="program-mapping-panel">
        <div class="panel-header programs-header">
          <div class="row">
            <div class="col-6">
              <div class="d-flex justify-content-start">
                <h6>VOD / PPV Titles</h6>
                <div class="ml-auto d-flex">
                  <div class="program-filters" *ngFor="let filter of programTypeFilters">
                    <div class="form-check form-check-inline">
                      <input
                      class="form-check-input"
                      type="checkbox"
                      id="{{ filter.name }}"
                      [checked]="filter.value"
                      (change)="filter.value = !filter.value; page = 1; getProgramMappings()"
                    />
                    <label class="form-check-label" for="{{ filter.name }}">{{
                      filter.abbrev
                    }}</label>
                    </div>
                  </div>
                </div>
                <div
                class="availability-filter mt-1"
                ngbDropdown
                placement="bottom-right"
                display="dynamic"
              >
                <button class="btn btn-default btn-sm" ngbDropdownToggle>
                  <i class="fa fa-filter text-white"></i>
                </button>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <div class="mx-2" *ngFor="let status of mappingStatusFilters">
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="checkbox" id="mappingStatus" [checked]="status.value"
                        (change)="status.value = !status.value; page = 1; getProgramMappings()" />
                      <label class="form-check-label" for="mappingStatus">
                        {{ status.name }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <div class="programs-search input-group mt-1">
                <input
                  type="search"
                  class="form-control"
                  placeholder="Search mappings"
                  [(ngModel)]="searchString"
                  (ngModelChange)="page = 1; searchChange($event, false)"
                  (keyup)="page = 1; searchChange($event, true)"
                />
                <div class="input-group-append">
                  <span class="input-group-text" (click)="getProgramMappings()">
                    <i class="fa fa-search"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="d-flex justify-content-center">
                <h6>VLS</h6>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex">
          <div class="col-12">
            <div class="panel-body programs-wrapper" id="programMappingList">
              <ng-container *ngIf="showLoader">
                <div class="loading">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </ng-container>
              <div
                class="mt-1 alert alert-warning"
                *ngIf="!programMappings.length"
              >
                No Program mapping records found
              </div>
              <div
                *ngFor="let program of programMappings; let i = index"
                class="d-flex justify-content-between row program-mapping-list"
                [class.active]="program.id === selectedProgram.id"
                (click)="selectedProgram = program"
              >
                <div
                  id="programItem-{{ i }}"
                  class="program-item col-6 {{ program.status }}"
                >
                  <div class="program-name d-flex">
                    <strong class="text-truncate">
                      <span *ngIf="formatProgramType(program.type) == 'EP' || formatProgramType(program.programType) == 'EP'"
                      >{{ getTitleByLang(program, 'episodeTitles', isSky ? 'pt' : 'es') || CONSTANTS.NO_EPISODE_TITLE }} | </span
                      >
                      {{ getTitleByLang(program, 'titles', isSky ? 'pt' : 'es') }}
                      <span *ngIf="program.releaseYear"
                      >({{ program.releaseYear }})</span
                      >
                    </strong>
                    <div class="ml-auto">
                      <span class="badge badge-info mr-1">
                        {{getSystemInfo(program)}}
                      </span>
                    </div>
                  </div>
                  <div class="text-muted text-truncate">
                    <span
                      [className]="
                        'mr-1 badge badge-' +
                        getResourceTypeStyle(
                          program.type ? program.type : program.programType
                        )
                      "
                    >
                      {{
                      program.type
                        ? formatProgramType(program.type)
                        : formatProgramType(program.programType)
                      }}
                    </span>
                    <span
                      *ngIf="program.seasonNumber"
                      class="mr-1 badge badge-light"
                    >sN: {{ program.seasonNumber }}</span
                    >
                    <span
                      *ngIf="program.episodeNumber"
                      class="mr-1 badge badge-light"
                    >eN: {{ program.episodeNumber }}</span
                    >
                  </div>
                  <div class="text-muted text-truncate program-name mt-1">
                    <span>{{ getTitleDesc(program, 'descriptions') }}</span>
                  </div>
                  <div class="program-id d-flex mt-1">
                    <div class="d-flex event-desc">
                      <div class="d-flex">
                        <span class="font-weight-bold text-muted">
                          {{
                          program.mapperId
                            ? 'MapperId: ' + program.mapperId
                            : program.id
                              ? 'MapperId: ' + program.id
                              : ''
                          }}
                          {{
                          program.dmsId && (program.mapperId || program.id)
                            ? ' | '
                            : ''
                          }}
                          {{ program.dmsId ? 'DmsId: ' + program.dmsId : '' }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="dropList-{{ i }}"
                  class="program-item right-panel ml-auto col-6 {{
                    program.status
                  }}"
                  cdkDropList
                  (cdkDropListDropped)="onDrop($event, i)"
                  cdkDropListConnectedTo="programList"
                >
                  <div *ngIf="program.mappedProgram && !program.published">
                    <div class="program-name d-flex">
                      <strong class="text-truncate">
                        <span *ngIf="formatProgramType(program.mappedProgram.type) != 'EP'"
                        >{{ getTitleByLang(program.mappedProgram, 'titles', isSky ? 'pt' : 'es') }}</span
                        >
                        <span *ngIf="formatProgramType(program.mappedProgram.type) == 'EP'"
                        >{{ (program.mappedProgram.episodeTitleAvailable == true || program.mappedProgram.episodeTitleAvailable == "true")
                          ? getTitleByLang(program.mappedProgram, 'titles', isSky ? 'pt' : 'es') + ' | ' + getTitleByLang(program.mappedProgram, 'parentTitle', isSky ? 'pt' : 'es')
                          : CONSTANTS.NO_EPISODE_TITLE + ' | ' + getTitleByLang(program.mappedProgram, 'titles', isSky ? 'pt' : 'es') }}</span
                        >
                        <span *ngIf="program.mappedProgram.releaseYear"
                        > ({{ program.mappedProgram.releaseYear }})</span
                        >
                      </strong>
                      <div class="ml-auto d-flex">
                        <span
                          class="badge badge-secondary-outlined mt-1 btn-more"
                          (click)="openModal(compareModal, program.id)"
                        >MORE</span
                        >
                        <input
                          class="ml-1 mr-2 mt-1"
                          type="checkbox"
                          [checked]="program.checked"
                          (change)="program.checked = !program.checked"
                        />
                      </div>
                    </div>
                    <div class="text-muted text-truncate">
                      <span
                        [className]="
                          'mr-1 badge badge-' +
                          getResourceTypeStyle(program.mappedProgram.type ? program.mappedProgram.type : program.mappedProgram.programType)
                        "
                      >
                        {{
                        program.mappedProgram.type
                          ? formatProgramType(program.mappedProgram.type)
                          : formatProgramType(program.mappedProgram.programType)
                        }}
                      </span>
                      <span
                        *ngIf="program.mappedProgram.seasonNumber"
                        class="mr-1 badge badge-light"
                      >
                        sN: {{ program.mappedProgram.seasonNumber }}
                      </span>
                      <span
                        *ngIf="program.mappedProgram.episodeNumber"
                        class="mr-1 badge badge-light"
                      >
                        eN: {{ program.mappedProgram.episodeNumber }}
                      </span>
                      <span
                        *ngIf="program.mappedProgram.episodeAltMappings"
                        class="mr-1 badge badge-light"
                      >
                        AltSn: {{ program.mappedProgram.episodeAltMappings[0].seasonNumber }}
                      </span>
                      <span
                        *ngIf="program.mappedProgram.episodeAltMappings"
                        class="mr-1 badge badge-light"
                      >
                        AltEn: {{ program.mappedProgram.episodeAltMappings[0].episodeNumber }}
                      </span>
                    </div>
                    <div class="text-muted text-truncate program-name mt-1">
                      <span>{{
                        getTitleDesc(program.mappedProgram, 'descriptions')
                        }}</span>
                    </div>
                    <div class="program-id d-flex mt-1">
                      <div class="d-flex event-desc">
                        <div class="d-flex">
                          <span class="font-weight-bold text-muted" *ngIf="program.programId">
                            Program Id: {{ program.programId }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="skipMapping(program.providerInfo)">
                    <span class="d-flex justify-content-center mt-2 text-muted">
                      <strong>Gracenote metadata enhancement restricted</strong>
                    </span>
                  </div>
                  <div
                    *ngIf="program.status === 'unmapped' && !program.programId"
                  >
                    <span class="d-flex justify-content-end">
                      <button
                        class="btn btn-sm btn-att mt-4 mr-1"
                        (click)="requestProviderInfo(program.id)"
                      >
                        Request Provider Info
                      </button>
                    </span>
                  </div>
                  <div *ngIf="program.status === 'unmappable'">
                    <span class="d-flex justify-content-center mt-2">
                      <strong>Unmappable from Gracenote</strong>
                    </span>
                    <span class="d-flex justify-content-center mt-2">
                      <strong>{{getReason(program.providerInfo)}}</strong>
                    </span>
                  </div>
                  <div *ngIf="program.status === 'incomplete'">
                    <span class="d-flex justify-content-center mt-2 text-muted">
                      <strong>Awaiting Provider Information</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-footer mb-2 d-flex justify-content-between">
          <div class="schedules-footer-actions float-left ml-2">
            <button
              class="btn btn-sm btn-att ml-2 mt-1"
              (click)="openModal(createPPVProgram)"
            >
              <i *ngIf="showLoader" class="fas fa-spinner fa-spin"></i>
              Create PPV Program
            </button>
          </div>
          <div class="program-mapping-pagination justify-content-end">
            <ngb-pagination
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="10"
            [collectionSize]="collectionSize"
            (pageChange) = "getProgramMappings()"
          >
          </ngb-pagination>
          </div>
          <div class="schedules-footer-actions float-right">
            <button
              id="submitSchedule"
              class="btn btn-sm btn-att mr-2 mt-1"
              (click)="submitMapping()"
            >
              <i *ngIf="showLoader" class="fas fa-spinner fa-spin"></i>
              Save and Next
            </button>
          </div>
        </div>
        <ng-template #compareModal id="compareModal">
          <div class="modal-header">
            <h4 class="modal-title">Program Details</h4>
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="height: auto; max-height: 500px; overflow-y: scroll">
            <table class="table table-striped table-bordered border-top-0">
              <tr>
                <th width="30px"></th>
                <th width="35px">VOD Titles ({{compareMapping.id}})</th>
                <th width="35px">VLS ({{compareMapping.programId}})</th>
              </tr>
              <tbody>
              <tr
                *ngIf="getTitleDescByLang(compareMapping, CONSTANTS.TITLES, CONSTANTS.EN) ||
                        getTitleDescByLang(compareMapping.mappedProgram, CONSTANTS.TITLES, CONSTANTS.EN)"
              >
                <td>English Title</td>
                <td>{{getTitleDescByLang(compareMapping, CONSTANTS.TITLES, CONSTANTS.EN)}}</td>
                <td>{{ (compareMapping.mappedProgram.episodeTitleAvailable == true || compareMapping.mappedProgram.episodeTitleAvailable == "true")
                  ? getTitleDescByLang(compareMapping.mappedProgram, CONSTANTS.PARENT_TITLES, CONSTANTS.EN)
                  : getTitleDescByLang(compareMapping.mappedProgram, CONSTANTS.TITLES, CONSTANTS.EN) }}</td>
              </tr>
              <tr
                *ngIf="getTitleDescByLang(compareMapping, CONSTANTS.TITLES, isSky ? CONSTANTS.PT : CONSTANTS.ES) ||
                        getTitleDescByLang(compareMapping.mappedProgram, CONSTANTS.TITLES, isSky ? CONSTANTS.PT : CONSTANTS.ES)"
              >
                <td>{{isSky ? CONSTANTS.PORTUGUESE : CONSTANTS.SPANISH}} Title</td>
                <td>{{getTitleDescByLang(compareMapping, CONSTANTS.TITLES, isSky ? CONSTANTS.PT : CONSTANTS.ES)}}</td>
                <td>{{ (compareMapping.mappedProgram.episodeTitleAvailable == true || compareMapping.mappedProgram.episodeTitleAvailable == "true")
                  ? getTitleDescByLang(compareMapping.mappedProgram, CONSTANTS.PARENT_TITLES, isSky ? CONSTANTS.PT : CONSTANTS.ES)
                  : getTitleDescByLang(compareMapping.mappedProgram, CONSTANTS.TITLES, isSky ? CONSTANTS.PT : CONSTANTS.ES) }}</td>
              </tr>
              <tr
                *ngIf="getTitleDescByLang(compareMapping, CONSTANTS.EPISODE_TITLES, CONSTANTS.EN) ||
                        (compareMapping.mappedProgram.programType === CONSTANTS.FULL_EPISODE &&
                        getTitleDescByLang(compareMapping.mappedProgram, CONSTANTS.TITLES, CONSTANTS.EN))"
              >
                <td>English Episode Title</td>
                <td>{{getTitleDescByLang(compareMapping, CONSTANTS.EPISODE_TITLES, CONSTANTS.EN)}}</td>
                <td>{{ (compareMapping.mappedProgram.programType !== CONSTANTS.FULL_EPISODE)
                  ? ''
                  : ((compareMapping.mappedProgram.episodeTitleAvailable == true || compareMapping.mappedProgram.episodeTitleAvailable == "true")
                    ? getTitleDescByLang(compareMapping.mappedProgram, CONSTANTS.TITLES, CONSTANTS.EN)
                    : CONSTANTS.NO_EPISODE_TITLE) }}</td>
              </tr>
              <tr
                *ngIf="getTitleDescByLang(compareMapping, CONSTANTS.EPISODE_TITLES, isSky ? CONSTANTS.PT : CONSTANTS.ES) ||
                        (compareMapping.mappedProgram.programType === CONSTANTS.FULL_EPISODE &&
                        getTitleDescByLang(compareMapping.mappedProgram, CONSTANTS.TITLES, isSky ? CONSTANTS.PT : CONSTANTS.ES))"
              >
                <td>{{isSky ? CONSTANTS.PORTUGUESE : CONSTANTS.SPANISH}} Episode Title</td>
                <td>{{getTitleDescByLang(compareMapping, CONSTANTS.EPISODE_TITLES, isSky ? CONSTANTS.PT : CONSTANTS.ES)}}</td>
                <td>{{ (compareMapping.mappedProgram.programType !== CONSTANTS.FULL_EPISODE)
                  ? ''
                  : ((compareMapping.mappedProgram.episodeTitleAvailable == true || compareMapping.mappedProgram.episodeTitleAvailable == "true")
                    ? getTitleDescByLang(compareMapping.mappedProgram, CONSTANTS.TITLES, isSky ? CONSTANTS.PT : CONSTANTS.ES)
                    : CONSTANTS.NO_EPISODE_TITLE) }}</td>
              </tr>
              <tr *ngIf="compareMapping.type || compareMapping.mappedProgram.type || compareMapping.mappedProgram.programType">
                <td>Program Type</td>
                <td>{{compareMapping.type}}</td>
                <td>{{compareMapping.mappedProgram.type || compareMapping.mappedProgram.programType}}</td>
              </tr>
              <tr *ngIf="compareMapping.releaseYear || compareMapping.mappedProgram.releaseYear">
                <td>Year</td>
                <td>{{compareMapping.releaseYear}}</td>
                <td>
                  {{compareMapping.mappedProgram.releaseYear}}
                </td>
              </tr>
              <tr *ngIf="compareMapping.seasonNumber || compareMapping.mappedProgram.seasonNumber">
                <td>Season Number</td>
                <td>{{compareMapping.seasonNumber}}</td>
                <td>
                  {{compareMapping.mappedProgram.seasonNumber}}
                </td>
              </tr>
              <tr *ngIf="compareMapping.episodeNumber || compareMapping.mappedProgram.episodeNumber">
                <td>Episode Number</td>
                <td>{{compareMapping.episodeNumber}}</td>
                <td>
                  {{compareMapping.mappedProgram.episodeNumber}}
                </td>
              </tr>
              <tr *ngIf="compareMapping.mappedProgram.episodeAltMappings">
                <td>Alternate Season Number</td>
                <td></td>
                <td>
                  {{compareMapping.mappedProgram.episodeAltMappings[0].seasonNumber}}
                </td>
              </tr>
              <tr *ngIf="compareMapping.mappedProgram.episodeAltMappings">
                <td>Alternate Episode Number</td>
                <td></td>
                <td>
                  {{compareMapping.mappedProgram.episodeAltMappings[0].episodeNumber}}
                </td>
              </tr>
              <tr
                *ngIf="formatRatings(compareMapping.ratings) ||
                        formatRatings(compareMapping.mappedProgram.ratings)"
              >
                <td>Ratings</td>
                <td>{{formatRatings(compareMapping.ratings)}}</td>
                <td>{{formatRatings(compareMapping.mappedProgram.ratings)}}</td>
              </tr>
              <tr
                *ngIf="formatGenres(compareMapping.genres) ||
                        formatGenres(compareMapping.mappedProgram.genres)"
              >
                <td>Genres</td>
                <td>{{formatGenres(compareMapping.genres)}}</td>
                <td>{{formatGenres(compareMapping.mappedProgram.genres)}}</td>
              </tr>
              <tr
                *ngIf="formatCreditsByCast(compareMapping.credits) ||
                        formatCreditsByCast(compareMapping.mappedProgram.credits)"
              >
                <td>Cast</td>
                <td>{{formatCreditsByCast(compareMapping.credits)}}</td>
                <td>{{formatCreditsByCast(compareMapping.mappedProgram.credits)}}</td>
              </tr>
              <tr
                *ngIf="formatCreditsByCrew(compareMapping.credits) ||
                        formatCreditsByCrew(compareMapping.mappedProgram.credits)"
              >
                <td>Crew</td>
                <td>{{formatCreditsByCrew(compareMapping.credits)}}</td>
                <td>{{formatCreditsByCrew(compareMapping.mappedProgram.credits)}}</td>
              </tr>
              <tr
                *ngIf="getTitleDescByLang(compareMapping, 'descriptions', 'en') ||
                        getTitleDescByLang(compareMapping.mappedProgram, 'descriptions', 'en')"
              >
                <td>English Description</td>
                <td>{{getTitleDescByLang(compareMapping, 'descriptions', 'en')}}</td>
                <td>{{getTitleDescByLang(compareMapping.mappedProgram, 'descriptions', 'en')}}</td>
              </tr>
              <tr
                *ngIf="getTitleDescByLang(compareMapping, 'descriptions', isSky ? 'pt' : 'es') ||
                        getTitleDescByLang(compareMapping.mappedProgram, 'descriptions', isSky ? 'pt' : 'es')"
              >
                <td>{{isSky ? 'Portuguese' : 'Spanish'}} Description</td>
                <td>{{getTitleDescByLang(compareMapping, 'descriptions', isSky ? 'pt' : 'es')}}</td>
                <td>{{getTitleDescByLang(compareMapping.mappedProgram, 'descriptions', isSky ? 'pt' : 'es')}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-att mr-2"
              (click)="closeModal()"
              ngbTooltip="Cancel"
              container="body"
            >
              <i class="fa fa-times"></i>
            </button>
            <button
              class="btn btn-att mr-2"
              (click)="compareMapping.checked = true; closeModal()"
              ngbTooltip="This is match"
              container="body"
            >
              <i class="fa fa-check"></i>
            </button>
          </div>
        </ng-template>
        <ng-template #createPPVProgram id="createPPVProgram">
          <div class="modal-header">
            <h4 class="modal-title">Create PPV Program</h4>
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="programType">Program Type</label>
                      <select
                        class="form-control"
                        id="programType"
                        [(ngModel)]="program.type"
                        (change)="selectedProgramType = program.type"
                      >
                        <option
                          *ngFor="let type of programTypes"
                          [value]="type"
                        >
                          {{ type }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col" *ngIf="selectedProgramType === 'MOVIE'">
                    <div class="form-group">
                      <label for="releaseYear">Release Year</label>
                      <input
                        id="releaseYear"
                        type="text"
                        class="form-control"
                        [(ngModel)]="program.releaseYear"
                        (keyup)="onInputKeyup($event)"
                      />
                    </div>
                  </div>
                  <div class="col" *ngIf="selectedProgramType !== 'MOVIE'">
                    <div class="form-group">
                      <label for="origAirDate">Original Air Date</label>
                      <input
                        id="origAirDate"
                        type="date"
                        class="form-control"
                        [(ngModel)]="program.origAirDate"
                        (change)="onInputKeyup($event)"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="seasonNumber">Season Number</label>
                      <input
                        [disabled]="selectedProgramType === 'MOVIE'"
                        id="seasonNumber"
                        type="text"
                        class="form-control"
                        [(ngModel)]="program.seasonNumber"
                      />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label for="episodeNumber">Episode Number</label>
                      <input
                        [disabled]="selectedProgramType === 'MOVIE'"
                        id="episodeNumber"
                        type="text"
                        class="form-control"
                        [(ngModel)]="program.episodeNumber"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <label>Genres</label>
                <ng-select
                  [items]="genres"
                  [multiple]="true"
                  [hideSelected]="true"
                  [closeOnSelect]="true"
                  [searchable]="true"
                  [(ngModel)]="program.genres"
                  (change)="genreOnChange()"
                  bindLabel="name"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div container="body" ngbTooltip="{{ item.id }}">
                      {{ item.name }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="col">
                <label>Ratings</label>
                <ng-select
                  [items]="filteredRatings"
                  [multiple]="true"
                  [hideSelected]="true"
                  [closeOnSelect]="true"
                  [searchable]="true"
                  [(ngModel)]="program.ratings"
                  bindLabel="name"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div container="body" ngbTooltip="{{ item.org }}">
                      {{ item.name }}
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="englishTitle">English Title</label>
                  <input
                    id="englishTitle"
                    type="text"
                    class="form-control"
                    [(ngModel)]="program.englishTitle"
                    (keyup)="onInputKeyup($event)"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="spanishTitle"
                  >{{ isSky ? 'Portuguese' : 'Spanish' }} Title</label
                  >
                  <input
                    id="spanishTitle"
                    type="text"
                    class="form-control"
                    [(ngModel)]="program.spanishTitle"
                    (keyup)="onInputKeyup($event)"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="englishDescription">English Description</label>
                  <textarea
                    id="englishDescription"
                    class="form-control"
                    rows="2"
                    spellcheck="false"
                    [(ngModel)]="program.englishDescription"
                  ></textarea>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="spanishDescription"
                  >{{ isSky ? 'Portuguese' : 'Spanish' }} Description</label
                  >
                  <textarea
                    id="spanishDescription"
                    class="form-control"
                    rows="2"
                    spellcheck="false"
                    [(ngModel)]="program.spanishDescription"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <label for="credits">Credits</label>
                <div class="input-group d-flex">
                  <div class="input-group-prepend" ngbDropdown>
                    <button
                      type="button"
                      class="btn btn-att dropdown-toggle"
                      data-toggle="dropdown"
                      ngbDropdownToggle
                    >
                      {{ selectedRole }}
                    </button>
                    <div class="dropdown-menu" role="menu" ngbDropdownMenu>
                      <span
                        class="dropdown-item"
                        (click)="selectedRole = 'Actor'"
                      >Actor</span
                      >
                      <span
                        class="dropdown-item"
                        (click)="selectedRole = 'Director'"
                      >Director</span
                      >
                    </div>
                  </div>
                  <input
                    id="credits"
                    type="text"
                    class="form-control"
                    spellcheck="false"
                    #inputSearch
                    [resultFormatter]="formatter"
                    [ngbTypeahead]="searchCredits"
                    (selectItem)="onSearchSelect($event, inputSearch)"
                    [inputFormatter]="inputFormatBandListValue"
                    placeholder="Search from existing celebrities"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <table
                  class="table table-striped"
                  style="
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
                      0 1px 2px rgba(0, 0, 0, 0.24);
                    font-size: 12px;
                  "
                >
                  <thead>
                  <tr
                    style="
                        background-image: linear-gradient(#0057b8, #263e58);
                        color: white !important;
                      "
                  >
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Role</th>
                    <th scope="col"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngIf="!program.credits.length">
                    <td colspan="4">No Credits added</td>
                  </tr>
                  <tr
                    class="cursor-pointer"
                    *ngFor="let credit of program.credits"
                  >
                    <td>{{ credit.firstName }}</td>
                    <td>{{ credit.lastName }}</td>
                    <td>{{ credit.role }}</td>
                    <td>
                      <i
                        container="body"
                        ngbTooltip="Remove added credits"
                        class="far fa-window-close ml-3"
                        (click)="removeCredits(credit.personId)"
                      ></i>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-att mr-2" (click)="createMapping()">
              Submit
            </button>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="col-lg-4">
      <app-program-list
        pageName="program-mapping"
        (programId)="onProgramIdChange($event)"
        [dropList]="dropList"
      ></app-program-list>
    </div>
  </div>
</div>
