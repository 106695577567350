<div class="container-fluid mt-3">
    <div class="d-flex justify-content-between p2">
      <h3>Asset Reports</h3>
    </div>
    <div class="col mt-2">
        <ul ngbNav #navAsset="ngbNav" class="nav-tabs" (navChange)="onTabChange($event)">
          <li [ngbNavItem]="'asset-stats'">
            <a ngbNavLink>Asset Stats</a>
            <ng-template ngbNavContent>
              <app-asset-stats></app-asset-stats>
            </ng-template>
          </li>
          <li *ngFor ="let tab of allAssetsTypes " [ngbNavItem]="tab.id">
            <a ngbNavLink>{{tab.label}}</a>
            <ng-template ngbNavContent>
              <app-all-assets [assetType] = 'assetType'>
              </app-all-assets>
            </ng-template>
          </li>
          <button class="ml-1 btn btn-light" (click)="exportToExcel()">
              <i class="fa fa-file-download"></i>
          </button>
        </ul>
        <div [ngbNavOutlet]="navAsset" class="bg-white p-1"></div>
    </div>
  </div>