<div class="channel-panel">
  <div class="panel-header">
    <div class="d-flex justify-content-between">
      <h6>Service Channel Management</h6>
      <div>
        <button
          [disabled]="showLoader"
          container="body"
          ngbTooltip="Create channel"
          (click)="openChannelFormModal(true)"
          class="channel-action-btn ml-2 btn btn-light btn-icon my-1"
        >
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="panel-body p-1">
    <div class="container">
      <div class="row">
        <div class="col-3 p-2">
          <img
            class="img-thumbnail rounded"
            src="{{ channel.channelImage }}"
            style="background-color: #081A2B;;"
            alt=""
            (error)="onImageError($event)"
          />
          <div class="row justify-content-end m-0 mt-2">
            <button class="add-image-btn" (click)="openImageManager()">
              <em class="fa fa-plus"></em>
            </button>
          </div>
          
        </div>
        <div class="col-9 p-0">
          <div class="container">
            <div class="row mb-1">
              <div class="col">
                <div class="form-group">
                  <label>Name</label>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    [(ngModel)]="channel.name"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Call Sign</label>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    [(ngModel)]="channel.callSign"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Source Id</label>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    [(ngModel)]="channel.sourceId"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Platform</label>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    [(ngModel)]="channel.channelTarget"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-1" *ngIf="channel.channelTarget === 'DTH'">
              <div class="col" *ngIf="channel.dthInfo.isSlave == true">
                <div class="form-group">
                  <label>Viewer Channel Number</label>
                  <input disabled type="text" class="form-control" [(ngModel)]="channel.dthInfo.viewerNumber" />
                </div>
              </div>
              <div class="col" *ngIf="channel.dthInfo.isSlave == null || channel.dthInfo.isSlave == false">
                <div class="form-group">
                  <label>Viewer Channel Number</label>
                  <input disabled type="text" class="form-control" [(ngModel)]="channel.dthInfo.viewerNumber" />
                </div>
              </div>
              <div class="col-3 pl-1 pt-0 pb-0 pr-2" *ngIf="channel.dthInfo?.isSlave == true">
                <div class="form-group">
                  <label style="width: 40%">Channel Resolution</label>
                  <input disabled type="text" class="form-control" [(ngModel)]="channel.dthInfo.channelResolution" />
                </div>
              </div>
              <div class="col-3 pl-1 pt-0 pb-0 pr-2" *ngIf="channel.dthInfo?.isSlave == null || channel.dthInfo.isSlave == false">
                <div class="form-group">
                  <label>Channel Resolution</label>
                  <input disabled type="text" class="form-control" [(ngModel)]="channel.dthInfo.channelResolution" />
                </div>
              </div>
              <div class="col-3 pl-1 pt-0 pb-0 pr-2" *ngIf="isDthPlatform()">
                <div class="form-group">
                  <label>Processing Type</label>
                  <input disabled type="text" class="form-control" [(ngModel)]="channel.processingType" />
                </div>
              </div>
              <div class="col-6" *ngIf="channel.dthInfo.isSlave == true">
                <div class="form-group">
                  <label style="width: 40%">Master Channel Name</label>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    [(ngModel)]="channel.masterChannelName.name"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col">
                <div class="form-group">
                  <label>Channel Type</label>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    [(ngModel)]="channel.channelType"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Language</label>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    [(ngModel)]="channel.language"
                  />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Region(s)</label>
                  <textarea
                    [(ngModel)]="channel.region"
                    disabled
                    class="form-control"
                    rows="1"
                  ></textarea>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Category</label>
                  <textarea
                    [(ngModel)]="channel.category"
                    disabled
                    class="form-control"
                    rows="1"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-6 pl-1 pt-0 pb-0 pr-2">
                <div class="form-group">
                  <label>Description</label>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    [(ngModel)]="channel.fullDescription"
                  />
                </div>
              </div>
              <div class="col-3 pl-1 pt-0 pb-0 pr-2">
                <div class="form-group">
                  <label>Owner</label>
                  <input
                    disabled
                    type="text"
                    class="form-control"
                    [(ngModel)]="channel.channelSrc"
                  />
                </div>
              </div>
              <div class="col-3 pl-1 pt-0 pb-0 pr-2">
                <div class="form-group">
                  <label>Assigned To</label>
                  <div class="input-group">
                    <input
                      disabled
                      type="text"
                      class="form-control rounded"
                      [(ngModel)]="channel.assignedTo"
                    />
                    <button
                      [disabled]="showLoader"
                      container="body"
                      ngbTooltip="Update channel"
                      (click)="openChannelFormModal(false)"
                      class="
                        channel-action-btn
                        ml-2
                        btn btn-light btn-icon
                        mb-1
                      "
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-3">
        <div class="col text-center" *ngIf="isDthPlatform()" >
          <button
            [disabled]="showLoader"
            container="body"
            class="channel-action-btn ml-2 btn btn-light btn-icon my-1"
            [class.bg-active]="!!channel.dthInfo?.isEngineering"
            (click)="updateField('engineering', !channel.dthInfo?.isEngineering)"
          >
            <i class="fas fa-podcast" [class.text-white]="!!channel.dthInfo?.isEngineering"></i>
          </button>
          <label class="pl-2 form-check-label">Engineering</label>
        </div>
        <div class="col text-center">
          <button
            [disabled]="showLoader || channel.channelSrc == 'DMS'"
            container="body"
            class="channel-action-btn ml-2 btn btn-light btn-icon my-1"
            [class.bg-active]="!!channel.published"
            (click)="updateField('status', !channel.published)"
          >
            <i class="fa fa-check" [class.text-white]="!!channel.published"></i>
          </button>
          <label class="pl-2 form-check-label">Status</label>
        </div>
        <div class="col text-center">
          <button
            [disabled]="showLoader || channel.channelType === 'VOD' || channel.channelSrc == 'DMS'"
            container="body"
            class="channel-action-btn ml-2 btn btn-light btn-icon my-1"
            [class.bg-active]="!!channel.adult"
            (click)="updateField('adult', !channel.adult)"
          >
            <i class="fa fa-male" [class.text-white]="!!channel.adult"></i>
          </button>
          <label class="pl-2 form-check-label">Adult Channel</label>
        </div>
        <div class="col text-center" *ngIf="!isDthPlatform()">
          <button
            [disabled]="showLoader || channel.channelType === 'VOD' || channel.channelSrc == 'DMS' || channel.processingType == 'auto'"
            container="body"
            class="channel-action-btn ml-2 btn btn-light btn-icon my-1"
            [class.bg-active]="channel.processingType =='auto'"
            (click)="updateField('auto', !(channel.processingType == 'auto'))"
          >
            <i class="fas fa-sync" [class.text-white]="channel.processingType == 'auto'"></i>
          </button>
          <label class="pl-2 form-check-label">Auto Channel</label>
        </div>
        <div class="bubble-processing-type col text-center" *ngIf="!isDthPlatform()">
          <button
            [disabled]="showLoader || channel.channelType === 'VOD' || channel.channelSrc == 'DMS' || channel.processingType == 'bubble'"
            container="body"
            class="channel-action-btn ml-2 btn btn-light btn-icon my-1"
            [class.bg-active]="channel.processingType == 'bubble'"
            (click)="updateField('bubble', !(channel.processingType == 'bubble'))"
          >
            <i
              class="fab fa-cloudsmith"
              [class.text-white]="channel.processingType == 'bubble'"
            ></i>
          </button>
          <label class="pl-2 form-check-label">Bubble</label>
        </div>
      </div>
      <div class="row seeInfoBtn">
        <div class="col mb-3" >
          <label>See more information</label>
          <label class="switch ml-1">
            <input
              type="checkbox"
              [checked] = "!isInfoHiden || channel.channelType === 'VOD'"
              (click)="showHideInformation()">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
      <div *ngIf="!isInfoHiden || channel.channelType === 'VOD'">
        <div class="row p-2 mt-0 infoCont">
          <ul
            ngbNav
            #navInfos="ngbNav"
            [(activeId)]="navInfo"
            class="nav-tabs"
          >
            <li [ngbNavItem]="i+1" *ngFor="let url of channel.urls; let i = index">
              <a ngbNavLink>{{ url.drmsName }} Info</a>
              <ng-template ngbNavContent>
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label>Type</label>
                        <input
                          disabled
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label>Resolution</label>
                        <input
                          disabled
                          type="text"
                          class="form-control"
                          [(ngModel)]="url.resolution"
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label>Language</label>
                        <input
                          disabled
                          type="text"
                          class="form-control"
                          [(ngModel)]="url.lang"
                        />
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label>Content Type</label>
                        <input
                          disabled
                          type="text"
                          class="form-control"
                          [(ngModel)]="url.contentType"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 drm-cdn-cont">
                      <label>DRM Info</label>
                      <div class="col" *ngFor="let drm of url?.drms">
                        <table style="font-size: 12px;">
                          <tr>
                            <td style="min-width: 150px;"><label>Name:  </label> {{ drm.drm }}</td>
                            <td><label>DRM ID:  </label> {{ drm.drmId }}</td>
                          </tr>
                          <tr>
                            <td><label>VendorID:  </label> {{ drm.vendorId }}</td>
                            <td><label>Certificate URL:  </label> {{ drm.certificateUrl }}</td>
                          </tr>
                          <tr>
                            <td><label>Vendor Name:  </label> {{ drm.vendorName }}</td>
                            <td><label>License URL:  </label> {{ drm.licenseUrl }}</td>
                          </tr>
                          <tr>
                            
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div class="col-12 drm-cdn-cont">
                      <label>CDN Info</label>
                      <div class="col" *ngFor="let cdn of url?.cdns">
                        <table style="font-size: 12px;">
                          <tr>
                            <td style="min-width: 150px;"><label>VendorID:  </label> {{ cdn.vendorId }}</td>
                            <td><label>Base URL:  </label> {{ cdn.baseUrl }}</td>
                          </tr>
                          <tr>
                            <td><label>Vendor Name:  </label> {{ cdn.vendorId }}</td>
                            <td><label>Channel ID:  </label> {{ cdn.cdnChannelId }}</td>
                          </tr>
                          <tr>
                            <td><label>Country:  </label> {{ cdn.cdnCountry }}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="channel.urls?.length + 1" *ngIf="channel?.serviceProviderInfo">
              <a ngbNavLink>Provider Info</a>
              <ng-template ngbNavContent>
                <div class="container-fluid">
                  <div class="row">
                    <div class="col providerInfoTable">
                      <label>Service Provider</label>
                      <table style="font-size: 12px;">
                        <tr>
                          <th>Name</th>
                          <th>Provider ID</th>
                        </tr>
                        <tr>
                          <td>{{ channel?.serviceProviderInfo?.serviceProvider?.name }}</td>
                          <td>{{ channel?.serviceProviderInfo?.serviceProvider?.providerID }}</td>
                        </tr>
                      </table>
                    </div>
                    <div class="col providerInfoTable">
                      <label>Service Provider Categories</label>
                      <table style="font-size: 12px;">
                        <tr>
                          <th>Name</th>
                          <th>Provider ID</th>
                        </tr>
                        <tr *ngFor="let info of channel?.serviceProviderInfo?.serviceProviderCategories">
                          <td > {{ info.name }} </td>
                          <td > {{ info.providerID }} </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="navInfos" class="p-1"></div>
        </div>
      </div>
      <div class="row d-flex justify-content-between align-items-baseline" *ngIf="channel.channelType !== 'VOD'">
        <h6>Provider mapping</h6>
        <div>
          <button
            *ngIf="channel.channelSrc == 'VLS'"
            container="body"
            ngbTooltip="Create daypart"
            (click)="openDaypart()"
            class="channel-action-btn ml-2 btn btn-light btn-icon my-1"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
      </div>
      <div class="row d-flex justify-content-center" *ngIf="channel.channelType !== 'VOD'">
        <div
          [class.provider-mapping-disabled] = "channel.dthInfo.isSlave == true"
          id="mappingList2"
          class="channel-mapping-wrapper border container-fluid"
          [class.ott-container]="channel.channelTarget === 'OTT'"
        >
          <button 
            *ngIf="!isBubbleChannel"
            class="btn btn-att btn-guide" 
            [disabled] = "!this.channel.dayParts || !this.channel.dayParts[daypartIndex]?.mappings || !this.channel.dayParts[daypartIndex]?.mappings?.length"
            (click)="requestGuide()"
          >
            Request guide
          </button>
          <button
            *ngIf="!isBubbleChannel"
            class="btn btn-att btn-mapping w-25"
            (click)="saveProviderMapping()"
          >
            Save Mapping
          </button>
          <!-- DayParts Component -->
          <div
            class="row no-gutters"
            *ngIf="channel?.dayParts?.length > 0"
          >
            <div class="col">
              <div
                class="row ml-2 mr-2 mt-2"
                *ngFor="let daypart of channel.dayParts; let x = index"
              >
                <div class="col">
                  <div class="row d-flex justify-content-center">
                    <b>DayPart </b> &nbsp;   
                    {{ daypart.startDate | date: 'ddMMMyy' }} -
                    {{ daypart.endDate | date: 'ddMMMyy' }}
                    <i
                      *ngIf="channel.channelSrc == 'VLS'"
                      container="body"
                      ngbTooltip="Delete daypart"
                      class="fas fa-times mx-2 mt-1"
                      (click)="deleteDaypart(x)"
                    ></i>
                  </div>
                  <div
                    id="mappingList"
                    *ngIf="(!daypart.mappings || !daypart.mappings.length) && !isBubbleChannel"
                    cdkDropList
                    (cdkDropListDropped)="onDrop($event, x)"
                    cdkDropListConnectedTo="providerList"
                    class="channel-mapping-wrapper border container-fluid"
                    [class.ott-container]="channel.channelTarget === 'OTT'"
                  >
                    <h6 class="mapping-note">Drag and drop provider(s) heres</h6>
                    <div class="custom-placeholder" *cdkDragPlaceholder></div>
                  </div>
                  <div class="daypart-management" 
                  *ngIf="daypart?.mappings?.length > 0 && !isBubbleChannel">
                    <ul
                      ngbNav
                      #navMain="ngbNav"
                      (activeId)="active"
                      [destroyOnHide]="true"
                      class="nav-tabs mt-2"
                    >
                      <li
                        [ngbNavItem]="i + 1"
                        [destroyOnHide]="true"
                        *ngFor="let schedule of daypart.mappings; let i = index"
                      >
                        <a ngbNavLink (click)="getDaypartIndex(x,i)">
                          <label>Mapping {{i+1}}</label> <br/>
                          {{ schedule.startDate | date: 'ddMMMyy' }}-{{schedule.endDate | date: 'ddMMMyy'}}
                          <i
                            ngbTooltip="Update schedule"
                            class="fas fa-pen-square mx-1 mt-1"
                            (click)="openScheduler(x, schedule, false, i)"
                          ></i>
                          <i
                            ngbTooltip="Delete schedule"
                            class="fas fa-times mx-1 mt-1"
                            (click)="deleteMapping($event, x, i)"
                          ></i>
                        </a>
                        <ng-template ngbNavContent>
                          <ng-container
                            [ngTemplateOutlet]="providerMapping"
                            [ngTemplateOutletContext]="{
                              schedule: schedule,
                              index: i,
                              indexDaypart: x
                            }"
                          ></ng-container>
                        </ng-template>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link add-link" (click)="openScheduler(x)">+</a>
                      </li>
                    </ul>
                    <div [ngbNavOutlet]="navMain" class="w-100"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-footer d-flex justify-content-between"></div>
</div>

<ng-template #imageManager>
  <div class="modal-header">
    <h4 class="modal-title">
      Manage Channel Images
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body imageManager">
    <div
      class="d-flex justify-content-between mt-4 pl-2 pr-2"
    >
      <div class="btn-group d-flex w-100">
        <div class="btn-group" ngbDropdown>
          <button
            class="btn btn-att btn-upload"
            ngbDropdownToggle
            style="font-size: 14px"
          >
            Icon
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button class="custom-dropdown-item" >
              Icon
            </button>
          </div>
        </div>
        <div class="custom-file channel-image">
          <input
            id="imageUpload"
            type="file"
            accept="image/png, image/jpeg"
            (change)="onFileSelect($event)"
          />
          <label
            container="body"
            ngbTooltip="Click to select image"
            id="inputLabel"
            class="custom-file-label form-control"
            for="imageUpload"
          >
          </label>
        </div>
      </div>
      <button
        [disabled]="this.image.imageFile == null"
        class="btn btn-att btn-upload"
        (click)="addImage()"
      >
        Add
      </button>
    </div>
    <br />
    <div class="row">
      <div
        role="alert"
        class="col alert alert-warning m-2"
        *ngIf="channelImages.length == 0"
      >
        No images added on this channel
      </div>
    </div>
    <div
      class="
        row
        no-gutters
        row-cols-sm-2
        row-cols-md-3
        row-cols-lg-4
        row-cols-xl-5
        img-main-cont
      "
    >
      <div *ngFor="let channelImage of channelImages; let i = index" class="col pb-2 mr-2 ml-2" >
        <div class="card channel-image-cont mb-2" >
          <div 
            class="card-header d-flex justify-content-start"
          > 
            <input
              [checked]="channelImage.published" 
              type="radio" 
              class="img-radio-btn cursor-pointer" 
              name="image-selected"
              ngbTooltip="Click to Publish"
              (click)="onPublishedChange(channelImage)"
            />
            <span
              (click)="deleteImage(channelImage, i)"  
              *ngIf="channelImage.imageFile" 
              class="pub-span badge badge-warning delete-image cursor-pointer">X</span>
            <span
              *ngIf="channelImage.published"
              class="badge badge-success pub-span"
            >
            PUB
            </span>
            <span
              *ngIf="!channelImage.published"
              class="badge badge-warning pub-span"
            >
              UNPUB
            </span>
          </div>
          
          <div class="card-body" style="min-height: 185px !important;  background-color: #081A2B;">
            <img
              class="card-img-top"
              src="{{ channelImage.imageURL }}"
              style="object-fit: contain; width: 100%;"
              (error)="onImageError($event)"
              alt=""
            />
          </div>
          <div class="card-footer d-flex justify-content-start">
            <span class="ml-auto">
              <a
                ngbTooltip="View image full size"
                target="_blank"
                href="{{ channelImage.imageURL }}"
              >
                {{channelImage.id}}
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark w-25" (click)="submitImages()">
       Submit
    </button>
  </div>
</ng-template>

<ng-template #providerMapping
  let-schedule="schedule"
  let-index="index"
  let-indexDaypart="indexDaypart"
>
  <div
    *ngIf="!!schedule.prgSvcId"
    class="provider-item mt-1 d-flex col-6"
    style="padding-left: 0px; padding-right: 0px"
  >
    <div class="col-2 channel-logo">
      <svg class="att-logo mt-1" aria-label="VLS home">
        <path
          d="M24.184 2.256A15.818 15.818 0 0 0 16 0c-3.023 0-5.89.847-8.298 2.323-.72.445-1.128.8-1.128 1.258 0 1.35 3.154 2.8 8.747 2.8 5.534 0 9.828-1.589 9.828-3.119 0-.365-.319-.62-.965-1.006m2.382 1.778c.067.104.1.218.1.369 0 1.777-5.438 4.922-14.096 4.922-6.36 0-7.551-2.36-7.551-3.862 0-.537.205-1.086.658-1.643.025-.034.004-.046-.025-.021-.826.7-1.585 1.488-2.252 2.34-.318.402-.516.758-.516.972 0 3.111 7.8 5.367 15.09 5.367 7.77 0 11.238-2.536 11.238-4.767 0-.797-.31-1.262-1.103-2.164a18.898 18.898 0 0 0-1.518-1.535c-.025-.02-.042-.004-.025.022M1.543 9.136c-.47.931-.99 2.504-1.145 3.317-.067.348-.038.515.084.775.981 2.084 5.946 5.418 17.526 5.418 7.065 0 12.554-1.736 13.443-4.906.163-.583.171-1.199-.038-2.03-.235-.926-.675-2.008-1.048-2.767-.013-.025-.034-.02-.03.009.139 4.155-11.446 6.834-17.291 6.834-6.331 0-11.614-2.524-11.614-5.71 0-.306.063-.613.142-.931.009-.03-.016-.034-.03-.009m11.242 13.28c-8.189 0-12.05-3.816-12.75-6.42C.025 15.958 0 15.966 0 16c0 .876.088 2.008.239 2.759.071.365.369.94.805 1.396 1.983 2.067 6.927 4.965 15.488 4.965 11.665 0 14.332-3.887 14.877-5.166.39-.914.591-2.566.591-3.954 0-.336-.008-.604-.021-.868 0-.042-.025-.046-.034-.004-.583 3.128-10.549 7.287-19.161 7.287m4.302 7.04c3.937 0 8.264-1.073 10.85-3.2.71-.578 1.385-1.35 1.988-2.385.348-.596.688-1.304.965-2 .013-.033-.009-.05-.034-.013-2.407 3.544-9.375 5.753-16.57 5.753-5.086 0-10.558-1.627-12.7-4.734-.021-.03-.042-.017-.03.017 1.996 4.243 8.05 6.562 15.531 6.562m-10.898-.818A15.983 15.983 0 0 0 16 32c4.042 0 7.727-1.501 10.54-3.966.034-.03.018-.05-.016-.03-1.262.843-4.86 2.684-10.524 2.684-4.922 0-8.034-1.1-9.79-2.08-.034-.017-.046.008-.022.03"
          fill="#009FDB"
          fill-rule="evenodd"
        ></path>
      </svg>
    </div>
    <div class="col-10 provider-info">
      <div class="row">
        <div class="col ml-1 provider-name">
          <div class="float-left">
            <strong
              class="mt-1 ml-2"
              container="body"
              ngbTooltip="{{ schedule.name }}"
            >
              {{ schedule.name | uppercase }}
            </strong>
            <label class="mt-1 ml-2 mb-0" style="font-weight: normal"
              >(PrgSvc Id: {{ schedule.prgSvcId }})</label
            >
          </div>
          <div class="float-right">
            <i
              container="body"
              ngbTooltip="Delete Mapped Service"
              class="fas fa-times ml-auto"
              (click)="deleteMappedService(indexDaypart, index)"
            ></i>
          </div>
          <div class="switcher ml-auto">
            <div
              class="badge badge-info mr-1"
              *ngIf="
                schedule.platform === 'Gracenote' ||
                schedule.platform === 'GRACENOTE'
              "
            >
              Gracenote
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col ml-1 provider-id">
          <div class="float-left">
            <div
              class="badge badge-secondary mr-1"
              *ngIf="schedule.sid !== undefined"
            >
              SID: {{ schedule?.sourceId }}
            </div>
          </div>
          <div class="float-right">
            <div
              class="badge badge-info mr-1"
              *ngIf="schedule.platform == 'OTT'"
            >
              OTT
            </div>
            <div
              class="badge badge-info mr-1"
              *ngIf="schedule.platform == 'DTH'"
            >
              DTH
            </div>
            <div class="badge badge-danger mr-1" *ngIf="schedule?.isMapped">
              Mapped
            </div>
            <div
              class="badge badge-success mr-1"
              *ngIf="
                schedule?.lang || schedule?.audioLang || schedule?.metadataLang
              "
            >
              {{
                schedule?.lang ||
                  schedule?.audioLang[0] ||
                  schedule?.metadataLang[0] ||
                  'None'
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    id="mappingList"
    cdkDropList
    (cdkDropListDropped)="onDrop($event, indexDaypart, index)"
    cdkDropListConnectedTo="providerList"
    class="channel-mapping-wrapper container-fluid"
    [class.ott-container]="channel.channelTarget === 'OTT'"
  >
    <h6 class="mapping-note">Drag and drop provider(s) here</h6>
    <div class="custom-placeholder" *cdkDragPlaceholder></div>
  </div>
</ng-template>

<ng-template #dateRangeModal>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ !!scheduler.prgSvcId ? 'Provider Mapping' : 'Request guide' }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row" *ngIf="!!scheduler.name">
      <div class="col">
        <div class="form-group">
          <label>Name</label>
          <input
            disabled
            type="text"
            class="form-control"
            [(ngModel)]="scheduler.name"
          />
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!!scheduler.prgSvcId">
      <div class="col">
        <div class="form-group">
          <label>Provider Id</label>
          <input
            disabled
            type="text"
            class="form-control"
            [(ngModel)]="scheduler.prgSvcId"
          />
        </div>
      </div>
      <div class="col" *ngIf="!!scheduler.lang">
        <div class="form-group">
          <label>Language</label>
          <input
            disabled
            type="text"
            class="form-control"
            [(ngModel)]="scheduler.lang"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="startDate"> Start Date </label>
          <input
            [(ngModel)]="scheduler.startDate"
            [owlDateTimeTrigger]="startDate"
            [owlDateTime]="startDate"
            [min]="minDate"
            placeholder="Start Date"
            class="form-control datetimepicker-filter"
          />
          <owl-date-time #startDate [pickerType]="'calendar'"></owl-date-time>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="endDate"> End Date </label>
          <input
            [(ngModel)]="scheduler.endDate"
            [owlDateTimeTrigger]="endDate"
            [owlDateTime]="endDate"
            [min]="minDate"
            placeholder="End Date"
            class="form-control datetimepicker-filter"
          />
          <owl-date-time #endDate [pickerType]="'calendar'"></owl-date-time>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark" (click)="closeModal()">Cancel</button>
    <button
      *ngIf="!!scheduler.prgSvcId"
      [disabled]="showLoader"
      class="btn btn-dark"
      (click)="confirmMappings(false)"
    >
      {{ isCreate ? 'Add' : 'Update' }}
    </button>
    <button
      *ngIf="!scheduler.prgSvcId"
      [disabled]="showLoader"
      class="btn btn-dark"
      (click)="requestChannelGuide()"
    >
      Request
    </button>
  </div>
</ng-template>

<ng-template #mappingModal>
  <div class="modal-header">
    <h4 class="modal-title">Provider Mapping</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row" *ngIf="!!scheduler.name">
      <div class="col">
        <div class="form-group">
          <label>Name</label>
          <input
            disabled
            type="text"
            class="form-control"
            [(ngModel)]="scheduler.name"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col" *ngIf="!!scheduler.prgSvcId">
        <div class="form-group">
          <label>Provider Id</label>
          <input
            disabled
            type="text"
            class="form-control"
            [(ngModel)]="scheduler.prgSvcId"
          />
        </div>
      </div>
      <div class="col" *ngIf="!!scheduler.lang">
        <div class="form-group">
          <label>Language</label>
          <input
            disabled
            type="text"
            class="form-control"
            [(ngModel)]="scheduler.lang"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="startDate"> Start Date </label>
          <input
            [disabled]="(isCreate && !(channel.dayParts[currentDaypart].mappings.length > 0)) || (!isCreate && scheduler?.index == 0)"
            [(ngModel)]="scheduler.startDate"
            [owlDateTimeTrigger]="startDate"
            [owlDateTime]="startDate"
            [min]="minDate"
            placeholder="Start Date"
            class="form-control datetimepicker-filter {{ !!startDateValidation ? 'is-invalid' : '' }}"
          />
          <div class="invalid-feedback">{{startDateValidation}}</div>
          <owl-date-time #startDate [pickerType]="'calendar'"></owl-date-time>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="endDate"> End Date </label>
          <input
            [disabled]="(!isCreate && (scheduler?.index == channel.dayParts[currentDaypart].mappings?.length - 1))"
            [(ngModel)]="scheduler.endDate"
            [owlDateTimeTrigger]="endDate"
            [owlDateTime]="endDate"
            [min]="minDate"
            placeholder="End Date"
            class="form-control datetimepicker-filter {{ !!endDateValidation ? 'is-invalid' : '' }}"
          />
          <div class="invalid-feedback">{{endDateValidation}}</div>
          <owl-date-time #endDate [pickerType]="'calendar'"></owl-date-time>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark" (click)="closeModal()">Cancel</button>
    <button
      [disabled]="showLoader || !!endDateValidation || !!startDateValidation"
      class="btn btn-dark"
      (click)="confirmMappings(false)"
    >
      {{ isCreate ? 'Add' : 'Update' }}
    </button>
  </div>
</ng-template>

<ng-template #daypartModal>
  <div class="modal-header">
    <h4 class="modal-title">
      {{ isAddDaypart ? 'Create Daypart' : 'Update Daypart' }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label for="startDate"> Start Date </label>
          <input
            [disabled]="checkDateAfter(minDate, daypart.startDate)"
            [(ngModel)]="daypart.startDate"
            [owlDateTimeTrigger]="startDate"
            [owlDateTime]="startDate"
            [min]="minDate"
            placeholder="Start Date"
            class="form-control datetimepicker-filter"
          />
          <owl-date-time #startDate [pickerType]="'calendar'"></owl-date-time>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label for="endDate"> End Date </label>
          <input
            [disabled]="checkDateAfter(minDate, daypart.startDate)"
            [(ngModel)]="daypart.endDate"
            [owlDateTimeTrigger]="endDate"
            [owlDateTime]="endDate"
            [min]="minDate"
            placeholder="End Date"
            class="form-control datetimepicker-filter"
          />
          <owl-date-time #endDate [pickerType]="'calendar'"></owl-date-time>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark" (click)="closeModal()">Cancel</button>
    <button
      [disabled]="showLoader"
      class="btn btn-dark"
      (click)="confirmMappings(true)"
    >
      {{ isAddDaypart ? 'Add' : 'Update' }}
    </button>
  </div>
</ng-template>
