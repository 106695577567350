<div class="container-fluid mt-3">
  <div class="page-title">
    <h4>Venues</h4>
  </div>

  <div class="row mt-2 p-1">
    <div class="col-md-3">
      <app-venue-list
      [newVenueId]="newVenueId"
      (isCreateNew)="onCreateNewVenue($event)"
      (venueId)="onVenueIdChange($event)"
      >
      </app-venue-list>
    </div>
    <div class="col-md-9">
      <app-venue-form
      [venue]="venue"
      [oldVenue]="oldVenue"
      (newVenueCreated)="onNewVenueCreated($event)"
      (venueId)="onVenueIdChange($event)"
      ></app-venue-form>
    </div>
  </div>
</div>
