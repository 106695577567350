<div style="overflow-y: scroll; max-height: 90vh;" (scroll)="onScroll($event)">
    <table>
        <thead class="bg-dark text-light">
            <tr>
                <th *ngFor="let field of headerFields">
                    <mat-form-field>
                        <mat-label>{{field.label}}</mat-label>
                        <input type="search" matInput id="{{field.name}}" (keyup)="filterChange($event)">
                    </mat-form-field>
                </th>
            </tr>
        </thead>
        <tbody>
            <ng-container>
                <tr class="cursor-pointer" *ngFor="let content of assets">
                    <td>{{content.program.programType}}</td>
                    <td>{{content.asset.title}}</td>
                    <td>{{content.program.seriesTitle}}</td>
                    <td>{{content.asset.displaySeasonNumber}}</td>
                    <td>{{content.asset.displayEpisodeNumber}}</td>
                    <td>{{content.program.genres}}</td>
                    <td>{{content.asset.provider}}</td>
                    <td>{{content.asset.categories}}</td>
                    <td>{{content.asset.channel}}</td>
                    <td>{{content.asset.programMapperId}}</td>
                    <td>{{content.asset.vrioId}}</td>
                    <td>{{content.asset.programId}}</td>
                    <td>{{content.asset.dmsId}}</td>
                    <td>{{content.program.tmsId}}</td>
                    <td>{{content.program.seriesTmsId}}</td>
                    <td>{{content.asset.assetId}}</td>
                    <td>{{content.asset.regions}}</td>
                    <td>{{content.asset.pubStatus}}</td>
                    <td>{{formatDateTime(content.asset.createdDate)}}</td>
                    <td>{{formatDateTime(content.asset.availabilityStartsAt)}}</td>
                    <td>{{formatDateTime(content.asset.availabilityEndsAt)}}</td>
                </tr>
            </ng-container>
            <ng-container *ngIf="isLoading">
                <div class="loading">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </ng-container>
        </tbody>
    </table>
</div>