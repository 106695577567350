<div class="container-fluid schedules-copy mt-3">
  <div class="page-title d-flex justify-content-start">
    <h4>Bulk Schedules Copy</h4>
  </div>
  <div class="row">
    <div class="col">
      <div class="gmt panel-header d-flex">
        <h6>Origin: {{bulkCopy.timeZone?.key}}</h6>
        <div class="ltz d-flex justify-content-start">
          <div class="input-group">
            <select class="offset" *ngIf="showLTZ" (change)="onChangeTimeZone($event)">
              <option value="null" disabled>Offset</option>
              <option *ngFor="let x of offsetOptions" [value]="x.key"
                      [title]="x.tooltip + ' ' + (bulkCopy.timeZone.key === x.key)"
                      [selected]="bulkCopy.timeZone.key === x.key">
                {{ x.key }}
              </option>
            </select>
          </div>
          <i container="body" class="fa fa-clock"
             ngbTooltip="{{ showLTZ ? 'Hide' : 'Change' }} local timezone."
             (click)="toggleRenderTimezoneDropdown()">
          </i>
        </div>
      </div>
      <div class="panel-body p-3">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="origChannelNames" class="select text-input">Original Channel</label>
              <ng-select [items]="channelList"
                         [hideSelected]="true"
                         [closeOnSelect]="true"
                         [searchable]="true"
                         placeholder="Select channel"
                         id="origChannelNames"
                         bindLabel="channelName"
                         [(ngModel)]="bulkCopy.origChannel"
                         (change)="channelOnChange($event, 'origChannelNames')">
                <ng-template ng-option-tmp let-item="item">
                  <div container="body" ngbTooltip="{{ item.id }}">
                    {{ item.channelName }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="startDate" class="select text-input">Start Date</label>
              <input id = "startDate" [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="dd/mmm/yyyy"
              [(ngModel)]="bulkCopy.startDate" class="form-control" (focus)="onFieldChange($event)"/>  
              <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="endDate" class="select text-input">End Date</label>
              <input id = "endDate" [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" placeholder="dd/mmm/yyyy"
              [(ngModel)]="bulkCopy.endDate" class="form-control" (focus)="onFieldChange($event)"/>  
              <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="numOfCopies" class="select text-input">Copy</label>
              <span class="copy-limit">
                (Max-30)
              </span>
              <input type="text" id="numOfCopies" class="form-control" [(ngModel)]="bulkCopy.numOfCopies"
                     (change)="onFieldChange($event)"/>
            </div>
          </div>
        </div>
      </div>
      <div class="panel-footer mb-2"></div>
    </div>
    <div class="col">
      <div class="panel-header d-flex">
        <h6>Destination: {{bulkCopy.timeZone?.key}}</h6>
      </div>
      <div class="panel-body p-3">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="destChannelNames" class="select text-input">Destination Channel</label>
              <ng-select [items]="channelList"
                         [hideSelected]="true"
                         [closeOnSelect]="true"
                         [searchable]="true"
                         placeholder="Select channel"
                         id="destChannelNames"
                         bindLabel="channelName"
                         [(ngModel)]="bulkCopy.destChannel"
                         (change)="channelOnChange($event, 'destChannelNames')">
                <ng-template ng-option-tmp let-item="item">
                  <div container="body" ngbTooltip="{{ item.id }}">
                    {{ item.channelName }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="destStartDate" class="select text-input">Destination Start Date</label>
              <input id = "destStartDate" [owlDateTime]="dt3" [owlDateTimeTrigger]="dt3" placeholder="dd/mmm/yyyy"
              [(ngModel)]="bulkCopy.destStartDate" class="form-control" (focus)="onFieldChange($event)"/>  
              <owl-date-time [pickerType]="'calendar'" #dt3></owl-date-time>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="published" class="select text-input">Published</label>
              <select class="form-control" id="published" [(ngModel)]="bulkCopy.published">
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="panel-footer mb-2">
        <div class="float-right">
          <button class="btn btn-sm btn-att mr-3 mt-2" (click)="submitCopySchedules()"> Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>
