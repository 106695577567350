<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
</div>
<div class="modal-body">
  <span *ngIf="message">{{ message }}</span>
  <br *ngIf="message && errorMessages && errorMessages?.length" />
  <span *ngIf="errorMessages && errorMessages?.length">Errors found:</span>
  <ul>
    <li *ngFor="let error of errorMessages">{{ error }}</li>
  </ul>
</div>
<div class="modal-footer">
  <button
    *ngIf="!isAlert"
    class="btn btn-att"
    (click)="activeModal.close(false)"
  >
    {{ isConfirm ? 'No' : 'Cancel' }}
  </button>
  <button class="btn btn-att" (click)="activeModal.close(true)">
    {{ isConfirm ? 'Yes' : isAlert ? 'Ok' : 'Confirm' }}
  </button>
</div>
