<div class="container-fluid mt-3">
  <div class="page-title">
    <h4>{{ 'vls.programs.pageTitle' | translate }}</h4>
  </div>

  <div class="row mt-2 p-1">
    <div class="col-lg-2 d-flex d-lg-none">
      <div class="col-lg-6">
        <app-program-list
          pageName="programs"
          [newProgramId]="newProgramId"
          (programId)="onProgramIdChange($event)"
          (versionId)="onVersionIdChange($event)"
        >
        </app-program-list>
      </div>
      <div class="col-lg-6">
        <app-versions
          [versionData]="versionData"
          [selectedVersion]="selectedVersion"
          (versionId)="onVersionIdChange($event)"
          (versionLock)="onLockVersionChange($event)"
        >
        </app-versions>
      </div>
    </div>
    <div class="col-lg-3 d-none d-lg-block">
      <app-program-list
        pageName="programs"
        [newProgramId]="newProgramId"
        (programId)="onProgramIdChange($event)"
        (versionId)="onVersionIdChange($event)"
      >
      </app-program-list>
    </div>
    <div class="col-lg-2 d-none d-lg-block">
      <app-versions
        [versionData]="versionData"
        [selectedVersion]="selectedVersion"
        (versionId)="onVersionIdChange($event)"
        (versionLock)="onLockVersionChange($event)"
      >
      </app-versions>
    </div>
    <div class="col-lg-7">
      <app-program-form
        [selectedProvider]="selectedProvider"
        [program]="program"
        [oldProgram]="oldProgram"
        [seasonsAndEpisodes]="seasonsAndEpisodes"
        [genres]="genres"
        [ratings]="ratings"
        [mpaaList]="mpaaList"
        [usaParental]="usaParental"
        [ratingsAdvisories]="ratingsAdvisories"
        [brazilRatings]="brazilRatings"
        [publishVersion]="publishVersion"
        (programId)="onProgramIdChange($event)"
        (isCopyToMaster)="onCopyToMasterChange($event)"
        (newProgramCreated)="onNewProgramCreated($event)"
        [seasonTeams] = 'seasonTeams'
        [stages] = 'stages'
      ></app-program-form>
    </div>
  </div>
</div>
