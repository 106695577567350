<div class="modal-header">
    <h4 class="modal-title">
        Slave Source Selection
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row ml-3 mr-3">
        <div class="form-group">
            <label>Service Channel: </label>
        </div>
    </div>
    <div class="row ml-3 mr-3 mb-2 justify-content-center" *ngIf="isLoading">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="row ml-3 mr-3" *ngIf="!isLoading">
        <div class="form-group">
            <ng-select
              id = "selectedSlaves"
              [items]="slaveChannels"
              [multiple]="true" 
              [hideSelected]="true"
              bindLabel="name"
              placeholder="Choose slave channels"
              [(ngModel)] = "selectedSlaves"
            >
              <ng-template ng-option-tmp let-item="item">
                <div container="body" ngbTooltip="{{ item.id }}">
                  <span>{{ item.name }}</span>
                </div>
              </ng-template>
            </ng-select>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-dark w-25" (click)="submit()">
        <i *ngIf="showLoader" class="fas fa-spinner fa-spin"></i> Submit
    </button>
</div>