<div class="program-form">
  <div class="panel-header d-flex">
    <div class="d-flex justify-content-start" *ngIf ="!isSky">
      <h6 *ngIf="!(program.type === 'EP' || program.type === 'SE')">
        {{ formatHeaderTitle('englishTitle', 'spanishTitle') || 'New Program' }}
      </h6>
      <h6 *ngIf="program.type === 'EP' || program.type === 'SE'">
        <a href="/programs" class="series-title-link">
          {{ formatHeaderTitle('englishTitle', 'spanishTitle') }}
        </a>
        :
        {{
        formatHeaderTitle('englishEpisodeTitle', 'spanishEpisodeTitle') ||
        'No episode title'
        }}
      </h6>
    </div>
    <div class="d-flex justify-content-start" *ngIf ="isSky">
      <h6 *ngIf="!(program.type === 'EP' || program.type === 'SE')">
        {{ formatHeaderTitle('englishTitle', 'portugueseTitle') || 'New Program' }}
      </h6>
      <h6 *ngIf="program.type === 'EP' || program.type === 'SE'">
        <a href="/programs" class="series-title-link">
          {{ formatHeaderTitle('englishTitle', 'portugueseTitle') }}
        </a>
        :
        {{
        formatHeaderTitle('englishEpisodeTitle', 'portugueseEpisodeTitle') ||
        'No episode title'
        }}
      </h6>
    </div>
  </div>
  <div class="panel-body" id="programForm">
    <div class="container-fluid">
      <ng-container *ngIf="isLoading">
        <div class="loading">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </ng-container>
      <div class="row poster-default mt-2 p-1">
        <div class="col-lg-3">
          <img class="card-img-top" src="{{ program.defaultImage || previewUrl }}" alt="Card image cap"
            (error)="onImageError($event)" />
        </div>
        <div class="col-lg-9">
          <div class="row season-episode" *ngIf="program.provider == 'masterentity'">
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Program ID</label>
                    <input type="text" class="form-control" [(ngModel)]="program.programId" disabled />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>VRIO ID</label>
                    <input type="text" class="form-control" [(ngModel)]="program.vrioId" disabled />
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Root ID</label>
                    <input type="text" class="form-control" [(ngModel)]="program.graceNoteExRef?.rootId.id" disabled />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Program Type</label>
                    <input type="text" class="form-control" [(ngModel)]="program.programType" disabled />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Gracenote ID's -->
          <div class="row season-episode" *ngIf="program.provider == 'gracenote'">
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>TMS ID</label>
                    <input type="text" class="form-control" [(ngModel)]="program.graceNoteExRef?.tmsId.id" disabled />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Connector ID</label>
                    <input type="text" class="form-control" [(ngModel)]="program.graceNoteExRef?.connectorId.id"
                      disabled />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Root ID</label>
                    <input type="text" class="form-control" [(ngModel)]="program.graceNoteExRef?.rootId.id" disabled />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row season-episode" *ngIf="program.provider == 'gracenote'">
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label>Program ID</label>
                    <input type="text" class="form-control" [(ngModel)]="program.programId" disabled />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>Program Type</label>
                    <input type="text" class="form-control" [(ngModel)]="
                        program.gracenoteProviderInfo.providerInfo.progType
                      " disabled />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label>SubType</label>
                    <input type="text" class="form-control" [(ngModel)]="
                        program.gracenoteProviderInfo.providerInfo.subType
                      " disabled />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row season-episode">
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="runTime">Runtime (sec)</label>
                    <input [disabled]="selectedProvider === 'gracenote'" id="runTime" type="text" class="form-control"
                      [(ngModel)]="program.runTime" />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group" *ngIf="program.type === 'MV'">
                    <label for="releaseYear">Release Year</label>
                    <input [disabled]="selectedProvider === 'gracenote'" id="releaseYear" type="number"
                      class="form-control" [(ngModel)]="program.releaseYear" (keyup)="onFieldChange($event)" />
                  </div>
                  <div class="form-group" *ngIf="program.type !== 'MV'">
                    <label for="seasonYear">Season Year</label>
                    <input [disabled]="selectedProvider === 'gracenote'" id="releaseYear" type="number"
                      class="form-control" [(ngModel)]="program.releaseYear"
                      (keyup)="program.type === 'SH' || program.type === 'SV' || program.type === 'SP' ? onFieldChange($event, 'SERIES') : onFieldChange($event)" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="countriesOfOrigin">Countries</label>
                    <input [disabled]="selectedProvider === 'gracenote'" id="countriesOfOrigin" type="text"
                      class="form-control" [(ngModel)]="program.countriesOfOrigin"
                      (change)="stringToArray('countries', 'countriesOfOrigin')" />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="colorCode">Series Program ID</label>
                    <input [disabled]="true" id="seriesProgramId" type="text" class="form-control"
                      [(ngModel)]="program.seriesId" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row season-episode">
            <div class="col">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="seasonNumber">Season</label>
                    <input type="text" id="seasonNumber" class="form-control" [(ngModel)]="program.seasonNumber"
                      (keyup)="onFieldChange($event)" [disabled]="
                        !(program.type === CONSTANTS.EPISODE || program.type === CONSTANTS.SPORT_EPISODE) ||
                        selectedProvider === 'gracenote'
                      " />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="episodeNumber">Episode</label>
                    <input type="text" id="episodeNumber" class="form-control" [(ngModel)]="program.episodeNumber"
                      (keyup)="onFieldChange($event)" [disabled]="
                        !(program.type === CONSTANTS.EPISODE || program.type === CONSTANTS.SPORT_EPISODE) ||
                        selectedProvider === 'gracenote'
                      " />
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <!-- alternateEpisode -->
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label for="alternateSeason">Alternate Season</label>
                    <input type="text" class="form-control" [ngModel]="episodeAltMappingComputed.seasonNumber"
                      [disabled]="true" />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="alternateEpisode">Alternate Episode</label>
                    <input type="text" class="form-control" [ngModel]="episodeAltMappingComputed.episodeNumber"
                      [disabled]="true" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row ratings">
            <div class="col form-group">
              <label for="ratings">Ratings</label>
              <div class="input-group">
                <input id="ratings" type="search" class="form-control input-ratings" [(ngModel)]="program.ratingString"
                  [disabled]="selectedProvider === 'gracenote'" />
                <div class="input-group-append">
                  <span class="input-group-text" (click)="openRatingModal(ratingModal)">
                    <i class="fa fa-edit"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col form-group">
              <label for="ratingContentDescriptor">Rating Content Descriptor</label>
              <input id="ratingContentDescriptor" type="text" class="form-control input-ratings"
                [(ngModel)]="program.ratingContentDescriptor" [readonly]="true" />
            </div>
          </div>
          <div class="row masterEntityGenre" *ngIf="selectedProvider === 'masterentity'">
            <div class="col form-group">
              <label>Genres</label>
              <ng-select [items]="genres" [multiple]="true" [hideSelected]="true" [closeOnSelect]="true"
                [searchable]="true" [(ngModel)]="program.genres" (change)="genreOnChange()" bindLabel="name"
                name="genreNames">
                <ng-template ng-option-tmp let-item="item">
                  <div container="body" ngbTooltip="{{ item.id }}">
                    {{ item.name }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 provider-view" *ngIf="selectedProvider === 'gracenote'">
        <div class="col">
          <div class="row gracenoteGenre">
            <div class="col form-group">
              <label>Genres</label>
              <ng-select disabled [items]="genres" [multiple]="true" [hideSelected]="true" [closeOnSelect]="true"
                [searchable]="true" [(ngModel)]="program.genres" (change)="genreOnChange()" bindLabel="name"
                name="genreNames">
                <ng-template ng-option-tmp let-item="item">
                  <div container="body" ngbTooltip="{{ item.id }}">
                    {{ item.name }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col">
              <div class="row origAirAudio">
                <div class="col">
                  <div class="form-group">
                    <label for="originalAudiolang">Original Audio</label>
                    <input disabled id="originalAudiolang" type="text" class="form-control"
                      [(ngModel)]="program.originalAudiolang" (keyup)="onFieldChange($event)" />
                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <label for="origAirDate">Original Air Date</label>
                    <input disabled id="origAirDate" type="text" class="form-control" [(ngModel)]="program.origAirDate"
                      (keyup)="program.type === 'SH' || program.type === 'SV' || program.type === 'SP' ? onFieldChange($event, 'SERIES') : onFieldChange($event)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Gracenote Episode Title List -->
        <div id="titleInfo" class="row pl-3" *ngIf="program.extraInfos?.episodeInfo?.title">
          <div class="col">
            <label>Episode Title</label>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Value</th>
                  <th scope="col">Language</th>
                  <th scope="col">Type</th>
                  <th scope="col">length</th>
                </tr>
              </thead>
              <tbody>
                <tr class="cursor-pointer">
                  <td>{{ program.extraInfos?.episodeInfo?.title?.value }}</td>
                  <td>{{ program.extraInfos?.episodeInfo?.title?.lang }}</td>
                  <td>{{ program.extraInfos?.episodeInfo?.title?.type }}</td>
                  <td>{{ program.extraInfos?.episodeInfo?.title?.length }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Gracenote Title List -->
        <div id="titleInfo" class="row pl-3" *ngIf="program.titles && program.titles.length">
          <div class="col">
            <label>Titles</label>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">&nbsp;&nbsp;&nbsp;</th>
                  <th scope="col" *ngFor="let header of getHeaders(program.titles)">
                    {{ header.charAt(0).toUpperCase() + header.slice(1) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="cursor-pointer" *ngFor="let title of program.titles; let i = index">
                  <td>
                    <input type="checkbox" id="selectedTitle-{{ i }}" name="selectedTitle"
                      value="{{ title.length || title.size }}" [checked]="selectedTitle.includes(i)"
                      (change)="onTitleDescChange($event)" />
                  </td>
                  <td>{{ title.value }}</td>
                  <td>{{ title.lang }}</td>
                  <td>{{ title.type }}</td>
                  <td>{{ title.length || title.size }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div id="descriptionInfo" class="row pl-3" *ngIf="program.descriptions && program.descriptions.length">
          <div class="col">
            <label>Descriptions</label>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">&nbsp;&nbsp;&nbsp;</th>
                  <th scope="col" *ngFor="let header of getHeaders(program.descriptions)">
                    {{ header.charAt(0).toUpperCase() + header.slice(1) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="cursor-pointer" *ngFor="let desc of program.descriptions; let i = index">
                  <td>
                    <input type="checkbox" name="selectedDesc" id="selectedDesc-{{ i }}"
                      value="{{ desc.length || desc.size }}" [checked]="selectedDesc.includes(i)"
                      (change)="onTitleDescChange($event)" />
                  </td>
                  <td>{{ desc.value }}</td>
                  <td>{{ desc.lang }}</td>
                  <td>{{ desc.type }}</td>
                  <td>{{ desc.length || desc.size }}</td>
                  <td>{{ desc.subType || '' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="title-desc mt-1" *ngIf="selectedProvider === 'masterentity'">
        <div class="row season-episode">
          <div class="col">
            <div class="form-group has-validation">
              <label for="originalTitle">Original Title</label>
              <span class="title-desc-len" *ngIf="program.originalTitle !== null">
                (Max-{{ program.originalTitle.length }})
              </span>
              <input id="originalTitle" type="text" class="form-control" [disabled]="program?.episodeTitleAvailable"
                [(ngModel)]="program?.originalTitle.value" (keyup)="onFieldChange($event)" />
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label for="originalAudiolang">Original Audio</label>
                  <select class="form-control" (change)="onFieldChange($event)" id="originalAudiolang"
                    [disabled]="program?.episodeTitleAvailable" [(ngModel)]="program.originalAudiolang">
                    <option *ngFor="let lang of languageList" [value]="lang.value">
                      {{ lang.language }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="origAirDate">Original Air Date</label>
                  <input id="origAirDate" type="date" class="form-control" [(ngModel)]="program.origAirDate"
                    (change)="program.type === 'SH' || program.type === 'SV' || program.type === 'SP' ? onFieldChange($event, 'SERIES') : onFieldChange($event)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row seeInfoBtn">
          <div class="col">
            <div>
              <div class="btn-ctrl" *ngIf="program?.otherLanguage">
                <label>Other Titles</label>
                <label class="switch">
                  <input type="checkbox" [checked]="program?.isOtherTitleHide"
                    (click)="showHideInformation('isOtherTitleHide')">
                  <span class="slider round"></span>
                </label>
              </div>
              <div class="btn-ctrl">
                <label>{{isSky ? CONSTANTS.PORTUGUESE : CONSTANTS.SPANISH}} Title</label>
                <label class="switch">
                  <input type="checkbox" [checked]="program?.isPtEsTitleHide"
                    (click)="showHideInformation('isPtEsTitleHide')">
                  <span class="slider round"></span>
                </label>
              </div>
              <div class="btn-ctrl">
                <label>English Title</label>
                <label class="switch">
                  <input type="checkbox" [checked]="program?.isEnTitleHide"
                    (click)="showHideInformation('isEnTitleHide', true)">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="program.type === 'EP' || program.type === 'SE'">
          <div class="col" *ngIf="program?.isEnTitleHide">
            <div class="form-group">
              <label for="englishEpisodeTitle">English Episode Title</label>
              <span class="title-desc-len" *ngIf="program.englishEpisodeTitle?.length">
                (Max-{{ program.englishEpisodeTitle.length }})
              </span>
              <input id="englishEpisodeTitle" type="text" class="form-control"
                [(ngModel)]="program.englishEpisodeTitle.value" (keyup)="onFieldChange($event, 'EpisodeTitle', true)" />
            </div>
          </div>
          <div class="col" *ngIf="program?.isPtEsTitleHide">
            <div class="form-group">
              <label for="spanishEpisodeTitle">
                {{isSky ? 'Portuguese' : 'Spanish'}} Episode Title
              </label>
              <span class="title-desc-len" *ngIf="program.spanishEpisodeTitle?.length">
                (Max-{{ program.spanishEpisodeTitle.length }})
              </span>
              <input [hidden]="isSky" id="spanishEpisodeTitle" type="text" class="form-control"
                (keyup)="onFieldChange($event, 'EpisodeTitle')" [(ngModel)]="program.spanishEpisodeTitle.value" />

              <input [hidden]="!isSky" id="portugueseEpisodeTitle" type="text" class="form-control"
                (keyup)="onFieldChange($event, 'EpisodeTitle')" [(ngModel)]="program.portugueseEpisodeTitle.value" />
            </div>
          </div>
          <div class="col" *ngIf="program?.isOtherTitleHide">
            <div class="form-group">
              <label for="otherEpisodeTitle">{{program.otherLanguage?.toUpperCase()}} Episode Title</label>
              <span class="title-desc-len" *ngIf="program.otherEpisodeTitle?.length">
                (Max-{{ program.otherTitle.length }})
              </span>
              <input id="otherEpisodeTitle" type="text" class="form-control"
                [(ngModel)]="program.otherEpisodeTitle.value" (keyup)="onFieldChange($event, 'EpisodeTitle', true)" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col" *ngIf="program?.isEnTitleHide">
            <div class="form-group">
              <label for="englishTitle">English Title</label>
              <span class="title-desc-len" *ngIf="program.englishTitle?.length">
                (Max-{{ program.englishTitle.length }})
              </span>
              <input id="englishTitle" type="text" class="form-control" [disabled]="program.episodeTitleAvailable"
                (keyup)="onFieldChange($event, 'Title', true)" [(ngModel)]="program.englishTitle.value" />
            </div>
          </div>
          <div class="col" *ngIf="program?.isPtEsTitleHide">
            <div class="form-group">
              <label for="spanishTitle">
                {{isSky ? 'Portuguese' : 'Spanish'}} Title
              </label>
              <span class="title-desc-len" *ngIf="program.spanishTitle?.length">
                (Max-{{ program.spanishTitle.length }})
              </span>
              <input [hidden]="isSky" id="spanishTitle" type="text" class="form-control"
                [disabled]="program.episodeTitleAvailable" (keyup)="onFieldChange($event, 'Title')"
                [(ngModel)]="program.spanishTitle.value" />
              <input [hidden]="!isSky" id="portugueseTitle" type="text" class="form-control"
                [disabled]="program.episodeTitleAvailable" (keyup)="onFieldChange($event, 'Title')"
                [(ngModel)]="program.portugueseTitle.value" />
            </div>
          </div>
          <div class="col" *ngIf="program?.isOtherTitleHide">
            <div class="form-group">
              <label for="englishTitle">{{program.otherLanguage?.toUpperCase()}} Title</label>
              <span class="title-desc-len" *ngIf="program.otherTitle?.length">
                (Max-{{ program.otherTitle.length }})
              </span>
              <input id="englishTitle" type="text" class="form-control" [disabled]="program.episodeTitleAvailable"
                (keyup)="onFieldChange($event, 'Title', true)" [(ngModel)]="program.otherTitle.value" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col" *ngIf="program?.isEnTitleHide">
            <div class="form-group">
              <label for="englishShortTitle">English Short Title</label>
              <span class="title-desc-len" *ngIf="program.englishShortTitle?.length">
                ({{ program.englishShortTitle.length }})
              </span>
              <input id="englishShortTitle" type="text" class="form-control" [disabled]="program.episodeTitleAvailable"
                [(ngModel)]="program.englishShortTitle.value" />
            </div>
          </div>
          <div class="col" *ngIf="program?.isPtEsTitleHide">
            <div class="form-group">
              <label for="spanishShortTitle">
                {{isSky ? 'Portuguese' : 'Spanish'}} Short Title
              </label>
              <span class="title-desc-len" *ngIf="program.spanishShortTitle?.length">
                ({{ program.spanishShortTitle.length }})
              </span>
              <input [hidden]="isSky" id="portugueseShortTitle" type="text" class="form-control"
                [disabled]="program.episodeTitleAvailable" [(ngModel)]="program.spanishShortTitle.value" />
              <input [hidden]="!isSky" id="spanishShortTitle" type="text" class="form-control"
                [disabled]="program.episodeTitleAvailable" [(ngModel)]="program.portugueseShortTitle.value" />
            </div>
          </div>
          <div class="col" *ngIf="program?.isOtherTitleHide">
            <div class="form-group">
              <label for="englishShortTitle">{{program.otherLanguage?.toUpperCase()}} Short Title</label>
              <span class="title-desc-len" *ngIf="program.otherShortTitle?.length">
                ({{ program.otherShortTitle.length }})
              </span>
              <input id="englishShortTitle" type="text" class="form-control" [disabled]="program.episodeTitleAvailable"
                [(ngModel)]="program.otherShortTitle.value" />
            </div>
          </div>
        </div>
        <div class="row seeInfoBtn">
          <div class="col">
            <div>
              <div class="btn-ctrl" *ngIf="program?.otherLanguage">
                <label>Other Desc</label>
                <label class="switch">
                  <input type="checkbox" [checked]="program?.isOtherDescHide"
                    (click)="showHideInformation('isOtherDescHide')">
                  <span class="slider round"></span>
                </label>
              </div>
              <div class="btn-ctrl">
                <label>{{isSky ? CONSTANTS.PORTUGUESE : CONSTANTS.SPANISH}} Desc</label>
                <label class="switch">
                  <input type="checkbox" [checked]="program?.isPtEsDescHide"
                    (click)="showHideInformation('isPtEsDescHide')">
                  <span class="slider round"></span>
                </label>
              </div>
              <div class="btn-ctrl">
                <label>English Desc</label>
                <label class="switch">
                  <input type="checkbox" [checked]="program?.isEnDescHide"
                    (click)="showHideInformation('isEnDescHide')">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col form-group" *ngIf="program?.isEnDescHide">
            <label for="englishDescription">English Description</label>
            <span class="title-desc-len" *ngIf="program.englishDescription?.length">
              (Max-{{ program.englishDescription.length }})
            </span>
            <textarea id="englishDescription" class="form-control" [(ngModel)]="program.englishDescription.value"
              rows="2" spellcheck="false"></textarea>
          </div>
          <div class="col form-group" *ngIf="program?.isPtEsDescHide">
            <label for="spanishDescription">
              {{isSky ? 'Portuguese' : 'Spanish'}} Description
            </label>
            <span class="title-desc-len" *ngIf="program.spanishDescription?.length">
              (Max-{{ program.spanishDescription.length }})
            </span>
            <textarea [hidden]="isSky" id="spanishDescription" class="form-control"
              [(ngModel)]="program.spanishDescription.value" rows="2" spellcheck="false"></textarea>
            <textarea [hidden]="!isSky" id="spanishDescription" class="form-control"
              [(ngModel)]="program.portugueseDescription.value" rows="2" spellcheck="false"></textarea>
          </div>
          <div class="col form-group" *ngIf="program?.isOtherDescHide">
            <label for="englishDescription">{{program.otherLanguage?.toUpperCase()}} Description</label>
            <span class="title-desc-len" *ngIf="program.englishDescription?.length">
              (Max-{{ program.otherDescription.length }})
            </span>
            <textarea id="englishDescription" class="form-control" [(ngModel)]="program.otherDescription.value" rows="2"
              spellcheck="false"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col form-group" *ngIf="program?.isEnDescHide">
            <label for="englishShortDescription">English Short Description</label>
            <span class="title-desc-len" *ngIf="program.englishShortDescription?.length">
              ({{ program.englishShortDescription.length }})</span>
            <textarea id="englishShortDescription" class="form-control"
              [(ngModel)]="program.englishShortDescription.value" rows="2" spellcheck="false"></textarea>
          </div>
          <div class="col form-group" *ngIf="program?.isPtEsDescHide">
            <label for="spanishShortDescription">
              {{isSky ? 'Portuguese' : 'Spanish'}} Short Description
            </label>
            <span class="title-desc-len" *ngIf="program.spanishShortDescription?.length">
              ({{ program.spanishShortDescription.length }})</span>
            <textarea [hidden]="isSky" id="spanishShortDescription" class="form-control"
              [(ngModel)]="program.spanishShortDescription.value" rows="2" spellcheck="false"></textarea>
            <textarea [hidden]="!isSky" id="spanishShortDescription" class="form-control"
              [(ngModel)]="program.portugueseShortDescription.value" rows="2" spellcheck="false"></textarea>
          </div>
          <div class="col form-group" *ngIf="program?.isOtherDescHide">
            <label for="englishShortDescription">{{program.otherLanguage?.toUpperCase()}} Short Description</label>
            <span class="title-desc-len" *ngIf="program.otherShortDescription?.length">
              ({{ program.otherShortDescription.length }})</span>
            <textarea id="englishShortDescription" class="form-control"
              [(ngModel)]="program.otherShortDescription.value" rows="2" spellcheck="false"></textarea>
          </div>
        </div>
      </div>
      <div class="row mt-3 pl-3">
        <ul ngbNav #navMain="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs">
          <li [ngbNavItem]="1" [destroyOnHide]="true">
            <a ngbNavLink>Cast and Crew</a>
            <ng-template ngbNavContent>
              <div class="row">
                <div class="col" style="margin-left: 20px; margin-right: 20px">
                  <div class="form-group cast-form-group">
                    <label>Type</label>
                    <select class="form-control" [(ngModel)]="castOrCrew.type"
                      (change)="changeListRoles(castOrCrew.type)">
                      <option [value]="'cast'">Cast</option>
                      <option [value]="'crew'">Crew</option>
                    </select>
                  </div>
                </div>
                <div class="col" style="margin-left: 20px; margin-right: 20px">
                  <div class="form-group cast-form-group">
                    <label>Role</label>
                    <select class="form-control" [(ngModel)]="castOrCrew.role"
                      (change)="showOtherInput(castOrCrew.role, 'role')">
                      <option *ngFor="let role of listRole" [value]="role">
                        {{ role }}
                      </option>
                    </select><br />
                    <input type="text" class="form-control mb-3" [(ngModel)]="otherRole" [hidden]="isRoleInputHidden" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col input-group" style="margin-left: 20px; margin-right: 20px">
                  <div class="input-group d-flex">
                    <input type="text" class="form-control" spellcheck="false"
                      placeholder="Search from existing celebrities" [(ngModel)]="celebritySearchString"
                      [ngbTypeahead]="searchCelebrity" [resultFormatter]="formatter"
                      [inputFormatter]="inputFormatBandListValue" (selectItem)="selectedItem($event)" />
                    <div class="input-group-append">
                      <button class="btn btn-att btn-outline-primary" [disabled]="
                          castOrCrew.castName != '' &&
                          castOrCrew.role != '' &&
                          castOrCrew.type != '' &&
                          selectedProvider !== 'gracenote'
                            ? false
                            : true
                        " (click)="addItem('groupedCastAndCrew', castOrCrew)">
                        <i class="fa fa-plus"></i>&nbsp;
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4 mx-1" *ngIf="program.credits?.length > 0">
                <div class="col" *ngIf="program.groupedCast?.length > 0">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="col-5">Cast Name</th>
                        <th class="col-5">Role</th>
                        <th class="col-2">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="cursor-pointer" *ngFor="let cast of program.groupedCast; let i = index">
                        <td>
                          {{ cast.firstName || cast.name?.firstName || '' }}
                          {{ cast.lastName || cast.name?.lastName || '' }}
                        </td>
                        <td>
                          {{ cast.role?.value || cast.role || 'None' }}
                        </td>
                        <td>
                          <span class="cursor-pointer" (click)="deletedCastAndCrew('groupedCast', i)">
                            <i class="far fa-window-close ml-2"></i>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col" *ngIf="program.groupedCrew?.length > 0">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="col-5">Crew Name</th>
                        <th class="col-5">Role</th>
                        <th class="col-2">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="cursor-pointer" *ngFor="let crew of program.groupedCrew; let i = index">
                        <td>
                          {{ crew.firstName || crew.name?.firstName || '' }}
                          {{ crew.lastName || crew.name?.lastName || '' }}
                        </td>
                        <td>
                          {{ crew.role?.value || crew.role || 'None' }}
                        </td>
                        <td>
                          <span class="cursor-pointer" (click)="deletedCastAndCrew('groupedCrew', i)">
                            <i class="far fa-window-close ml-2"></i>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="alert alert-warning mt-4" role="alert"
                *ngIf="!program.credits?.length || program.credits?.length == 0"
                style="margin-left: 10px; margin-right: 10px">
                No cast and crew added
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>Images</a>
            <ng-template ngbNavContent>
              <div class="mt-2 program-pictures-list" *ngIf="
                  program.publishedImages?.length &&
                  selectedProvider !== 'gracenote'
                ">
                <div class="
                    row
                    no-gutters
                    row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5
                  ">
                  <div *ngFor="let image of program.publishedImages; let i = index" class="col pb-2">
                    <ng-container [ngTemplateOutlet]="programImageCard" [ngTemplateOutletContext]="{
                        image: image,
                        i: i,
                        published: true
                      }"></ng-container>
                  </div>
                </div>
              </div>
              <ul ngbNav #navImages="ngbNav" [(activeId)]="navImage" class="nav-pills p-2 mt-1">
                <li class="pr-5" [ngbNavItem]="i + 1" *ngFor="let size of program.imageSizes; let i = index">
                  <a ngbNavLink>{{ size }}</a>
                  <ng-template ngbNavContent>
                    <div role="alert" class="alert alert-warning mt-2" *ngIf="!program.programImages[size]?.length">
                      No {{ size }} images added
                    </div>
                    <div class="mt-4 program-pictures-list" *ngIf="program.programImages[size]?.length">
                      <div class="
                          row
                          no-gutters
                          row-cols-sm-2
                          row-cols-md-3
                          row-cols-lg-4
                          row-cols-xl-5
                        ">
                        <div *ngFor="
                            let image of program.programImages[size];
                            let i = index
                          " class="col pb-2">
                          <ng-container [ngTemplateOutlet]="programImageCard" [ngTemplateOutletContext]="{
                              image: image,
                              i: i,
                              published: false
                            }"></ng-container>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
              </ul>
              <ng-template #programImageCard let-image="image" let-i="i" let-published="published">
                <div class="card program-image mb-2">
                  <div class="card-header d-flex justify-content-start" *ngIf="selectedProvider !== 'gracenote'">
                    <input *ngIf="!published" [checked]="image.published" (click)="publishedImage(image)" type="radio"
                      class="img-radio-btn cursor-pointer" name="image-selected" ngbTooltip="Click to Publish" />
                    <span (click)="deleteImage(image, i)" *ngIf="image.imageFile && !published" class="
                        pub-span
                        badge badge-warning
                        delete-image
                        cursor-pointer
                      ">X</span>
                    <span *ngIf="image.published" class="pub-span badge badge-success">PUB </span>
                    <span container="body" class="badge badge-warning pub-span" *ngIf="!image.published">
                      UNPUB
                    </span>
                  </div>
                  <div class="card-body">
                    <img class="card-img-top cast-crew-img" [src]="
                        image.uri
                          ? image.baseUrl + image.uri
                          : image.imageURL || previewUrl
                      " style="object-fit: contain; width: 100%" (error)="onImageError($event)"
                      *ngIf="selectedProvider !== 'gracenote'" />
                    <img class="card-img-top cast-crew-img" [src]="
                        image.uri
                          ? 'http://tmsimg.com/' + image.uri
                          : previewUrl
                      " style="object-fit: contain; width: 100%" (error)="onImageError($event)"
                      *ngIf="selectedProvider === 'gracenote'" />
                  </div>
                  <div class="card-footer">
                    <p style="
                        margin-bottom: 0px;
                        text-align: left;
                        display: flex;
                        flex-direction: column;
                      " class="ml-auto">
                      <span>
                        <a *ngIf="selectedProvider === 'gracenote'" ngbTooltip="{{ image.id }}" target="_blank"
                          href="http://tmsimg.com/{{ image.uri }}">
                          Category: {{ image.category }}
                        </a>
                        <a *ngIf="selectedProvider !== 'gracenote'" ngbTooltip="{{ image.id }}" target="_blank" href="{{
                            image.uri ? image.baseUrl + image.uri : previewUrl
                          }}">
                          Category: {{ image.category }}
                        </a>
                      </span>
                      <span class="font-weight-bold">
                        Ratio: {{ image.ratio }}
                      </span>
                      <span class="font-weight-bold">
                        Source: {{
                        image.source === 'gracenote'
                        ? 'GN'
                        : image.source === 'VLS' || !image.source
                        ? 'VLS'
                        : 'DMS'
                        }}
                      </span>
                      <span class="font-weight-bold"
                        *ngIf="image.expiredDate !== undefined && image.expiredDate !== null">Expired date: {{
                        image.expiredDate | date: "yyyy-MM-dd" }}
                      </span>
                    </p>
                  </div>
                </div>
              </ng-template>
              <div class="d-flex justify-content-between mt-4 pl-2 pr-2" *ngIf="selectedProvider !== 'gracenote'">
                <div class="btn-group d-flex w-100">
                  <div class="btn-group" ngbDropdown>
                    <button class="btn btn-att btn-upload" ngbDropdownToggle style="font-size: 14px">
                      {{ selectedImageCategory }}
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                      <button class="custom-dropdown-item" *ngFor="let category of imageCategories"
                        (click)="setImageCategory(category)" ngbDropdownItem>
                        {{ category }}
                      </button>
                    </div>
                  </div>
                  <div class="custom-file program-image">
                    <input id="imageUpload" type="file" accept="image/png, image/jpeg"
                      (change)="onFileSelect($event)" />
                    <label container="body" ngbTooltip="Click to select image" id="inputLabel"
                      class="custom-file-label form-control" for="imageUpload">
                    </label>
                  </div>
                </div>
                <button class="btn btn-att btn-upload" [disabled]="!image.imageFile" (click)="addItem('images', image)">
                  Add
                </button>
              </div>
              <div [ngbNavOutlet]="navImages" class="pl-2 pr-2"></div>
            </ng-template>
          </li>
          <li [ngbNavItem]="3" *ngIf="program.type === 'SH'">
            <a ngbNavLink>Seasons and Episodes</a>
            <ng-template ngbNavContent>
              <div class="row mt-2">
                <div class="col ml-3 mb-3">
                  <div class="form-group">
                    <label>
                      <select class="form-control" (change)="onSeasonChange()" [(ngModel)]="season">
                        <option *ngFor="let s of program.seasons" [value]="s.seasonNumber">
                          Season {{ s.seasonNumber }}
                        </option>
                      </select>
                    </label>
                    <span class="ml-2">Total Episodes: {{ seasonsAndEpisodes?.length }}</span>
                  </div>
                </div>
              </div>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Episode title</th>
                    <th>Program ID</th>
                    <th>Original air date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="cursor-pointer" *ngFor="let episodes of seasonsAndEpisodes">
                    <td>{{ episodes?.episodeNumber }}</td>
                    <td>{{ episodes.titles[0]?.value }}</td>
                    <td>{{ episodes._id }}</td>
                    <td>{{ episodes?.origAirDate }}</td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </li>
          <li [ngbNavItem]="4" *ngIf="program.type === 'MV'">
            <a ngbNavLink>Movie Info</a>
            <ng-template ngbNavContent>
              <div class="ml-3 mr-3">
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label>Production Companies</label>
                      <input type="text" class="form-control" [(ngModel)]="program.productionCompaniesString" (change)="
                          stringToArray(
                            'productionCompanies',
                            'productionCompaniesString'
                          )
                        " [disabled]="selectedProvider === 'gracenote'" />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Movie Version Label</label>
                      <input type="text" class="form-control" [(ngModel)]="program.versionLabel"
                        [disabled]="selectedProvider === 'gracenote'" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label>External Links</label>
                      <input type="text" class="form-control" [(ngModel)]="program.externalLinks"
                        [disabled]="selectedProvider === 'gracenote'" />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>URL</label>
                      <input type="text" class="form-control" [(ngModel)]="program.url"
                        [disabled]="selectedProvider === 'gracenote'" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label>Original picture format</label>
                      <input type="text" class="form-control" [(ngModel)]="program.pictureFormats"
                        [disabled]="selectedProvider === 'gracenote'" />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Original sound mixes</label>
                      <input type="text" class="form-control" [(ngModel)]="program.soundMixes"
                        [disabled]="selectedProvider === 'gracenote'" />
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="program.type === 'MV'">
                  <div class="col form-group">
                    <label>Ratings Advisories</label>
                    <ng-select [items]="ratingsAdvisories" [multiple]="true" [hideSelected]="true"
                      [closeOnSelect]="true" [searchable]="true" [(ngModel)]="program.advisoryNames" bindLabel="name"
                      name="ratingsAdvisoryNames" [disabled]="selectedProvider === 'gracenote'"
                      (change)="advisoryOnChange()">
                    </ng-select>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="5" *ngIf="program.type === 'SE'">
            <!-- <li [ngbNavItem]="5"> -->

            <a ngbNavLink>Sports Info</a>
            <ng-template ngbNavContent>
              <div class="ml-3 mr-3">
                <div class="row" *ngIf = "program.isHighLight">
                  <div class="col">
                    <div class="form-group">
                      <label for="parentProgramId">Parent Program ID</label>
                      <input id="parentProgramId" type="text" class="form-control" (keydown.space)="$event.preventDefault()"
                        [(ngModel)]="program.parentProgramId" (ngModelChange)="parentProgramIdOnChange()"/>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group">
                    <label>Home Team</label>
                    <ng-select [items]="seasonTeams" [multiple]="false" [hideSelected]="true" [closeOnSelect]="true" [searchable]="true"
                      [(ngModel)]="program.homeTeam" bindLabel="team" name="homeTeams" [disabled]="program.isHighLight">
                      <ng-template ng-option-tmp let-item="item">
                        <div container="body" ngbTooltip="{{ item.id }}">
                          {{ item.team }}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                  <div class="col form-group">
                    <label>Away Team</label>
                    <ng-select [items]="seasonTeams" [multiple]="false" [hideSelected]="true" [closeOnSelect]="true" [searchable]="true"
                      [(ngModel)]="program.awayTeam" bindLabel="team" name="awayTeams" [disabled]="program.isHighLight">
                      <ng-template ng-option-tmp let-item="item">
                        <div container="body" ngbTooltip="{{ item.id }}">
                          {{ item.team }}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label>Season</label>
                      <input type="text" [disabled]="(program.isWorldCup == true) || (program.isHighLight == true)" class="form-control" [(ngModel)]="program?.sportsInfo.sportSeason.value" />
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Season Type</label>
                      <input type="text" class="form-control" [(ngModel)]="program?.sportsInfo.sportSeason.type" [disabled]="(program.isWorldCup == true) || (program.isHighLight == true)"/>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col form-group" *ngIf = 'program.isWorldCup'>
                    <label>Playoff Round</label>
                    <ng-select [items]="stages" [multiple]="false" [hideSelected]="true" [closeOnSelect]="true" [searchable]="true"
                      [(ngModel)]="program.sportsInfo.playoffRound" bindLabel="playoffRound" bindValue="playoffRound" name="playoffRound" (change) = 'onPlayoffRoundChange($event)' [disabled]="program.isHighLight">
                      <ng-template ng-option-tmp let-item="item">
                        <div container="body" ngbTooltip="{{ item.playoffRoundId }}">
                          {{ item.playoffRound }}
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                  <div class="col" *ngIf = '!program.isWorldCup'>
                    <div class="form-group">
                      <label>Playoff Round</label>
                      <input type="text" class="form-control" [(ngModel)]="program?.sportsInfo.playoffRound" [disabled]="program.isHighLight"/>
                    </div>
                  </div>
                  <div class="col" >
                    <div class="form-group">
                      <label>Group Name</label>
                      <input type="text" [disabled]="program.isHighLight || program.isWorldCup" class="form-control" [(ngModel)]="program?.sportsInfo.groupName" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label>Game Date (UTC)</label>
                      <input id="gameDate" type="date" class="form-control" [(ngModel)]="program?.sportsInfo.gameDate" [disabled]="program.isHighLight" required (change)="onFieldChange($event, 'gameDate', true)"/>
                    </div>
                  </div>
                  <div class="col">
                    <div class="form-group">
                      <label>Game Time (UTC)</label>
                      <input id="gameTime" type="text" class="form-control" [(ngModel)]="program?.sportsInfo.gameTime" [disabled]="program.isHighLight" required (keyup)="onFieldChange($event, 'gameTime', true)"/>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label for="venue">Venue</label>
                      <ng-select [items]="venuesV2" [multiple]="false" [hideSelected]="true" [closeOnSelect]="true" [searchable]="true"
                      [(ngModel)]="program.sportsInfo.venue" bindLabel="value" name="venues" [disabled]="program.isHighLight">
                      <ng-template ng-option-tmp let-item="item">
                        <div container="body" ngbTooltip="{{ item.id }}">
                          {{ item.value }}
                        </div>
                      </ng-template>
                    </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row seeInfoBtn" *ngIf = "program.isWorldCup">
                  <div class="col">
                    <div>
                      <div class="btn-ctrl" *ngIf = "!isSky">
                        <label>{{CONSTANTS.SPANISH}} Venue</label>
                        <label class="switch">
                          <input type="checkbox" [checked]="program?.isEsVenueHide"
                            (click)="showHideInformation('isEsVenueHide')">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <div class="btn-ctrl">
                        <label>English Venue</label>
                        <label class="switch">
                          <input type="checkbox" [checked]="program?.isEnVenueHide"
                            (click)="showHideInformation('isEnVenueHide')">
                          <span class="slider round"></span>
                        </label>
                      </div>
                      <div class="btn-ctrl" *ngIf = "isSky">
                        <label>{{CONSTANTS.PORTUGUESE}} Venue</label>
                        <label class="switch">
                          <input type="checkbox" [checked]="program?.isPtVenueHide"
                            (click)="showHideInformation('isPtVenueHide')">
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf = "program.isWorldCup">
                    <div class="col" *ngIf="program?.isEnVenueHide">
                      <div class="form-group">
                        <label for="englishVenueName">English Venue</label>
                        <input type="text" disabled class="form-control" value = "{{buildVenueName(program, CONSTANTS.EN)}}" />
                      </div>
                    </div>
                    <div class="col" *ngIf="program?.isEsVenueHide && !isSky">
                      <div class="form-group">
                        <label for="spanishVenueName">Spanish Venue</label>
                        <input type="text" disabled class="form-control" value = "{{buildVenueName(program, CONSTANTS.ES)}}" />
                      </div>
                    </div>
                    <div class="col" *ngIf = "isSky && program?.isPtVenueHide">
                      <div class="form-group">
                        <label for="spanishVenueName">Portuguese Venue</label>
                        <input type="text" disabled class="form-control" value = "{{buildVenueName(program, CONSTANTS.PT_BR)}}" />
                      </div>
                    </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="6">
            <a ngbNavLink>Releases</a>
            <ng-template ngbNavContent>
              <div class="d-flex justify-content-end mb-1">
                <button [disabled]="selectedProvider === 'gracenote'" class="btn btn-att btn-sm"
                  (click)="openModal(releaseModal)">
                  Add Release
                </button>
              </div>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Country</th>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Medium</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="cursor-pointer" *ngFor="let release of program.releases; let i = index">
                    <td>{{ release.country || '--' }}</td>
                    <td>{{ release.date || '--' }}</td>
                    <td>{{ release.type || '--' }}</td>
                    <td>{{ release.medium || '--' }}</td>
                    <td>
                      <ng-container [ngTemplateOutlet]="tableActions" [ngTemplateOutletContext]="{
                          modalName: releaseModal,
                          entity: 'release',
                          fieldName: 'releases',
                          filterName: 'index',
                          id: i
                        }"></ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </li>
          <li [ngbNavItem]="7">
            <a ngbNavLink>Awards</a>
            <ng-template ngbNavContent>
              <div class="d-flex justify-content-end mb-1">
                <button [disabled]="selectedProvider === 'gracenote'" class="btn btn-att btn-sm"
                  (click)="openModal(awardsModal)">
                  Add Awards
                </button>
              </div>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Award</th>
                    <th>Won</th>
                    <th>Category</th>
                    <th>Recipient</th>
                    <th>Year</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="cursor-pointer" *ngFor="let award of program.awards; let i = index">
                    <td>{{ award.name || '--' }}</td>
                    <td>{{ award.won != null ? award.won : '--' }}</td>
                    <td>{{ award.category || '--' }}</td>
                    <td>{{ award.recipient || '--' }}</td>
                    <td>{{ award.year || '--' }}</td>
                    <td>
                      <ng-container [ngTemplateOutlet]="tableActions" [ngTemplateOutletContext]="{
                          modalName: awardsModal,
                          entity: 'award',
                          fieldName: 'awards',
                          filterName: 'index',
                          id: i
                        }"></ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </li>
          <li [ngbNavItem]="8" *ngIf="program.type != 'MV'">
            <a ngbNavLink>External Info</a>
            <ng-template ngbNavContent>
              <div>
                <div class="d-flex justify-content-end mb-1">
                  <button [disabled]="selectedProvider === 'gracenote' || program.isHighLight" class="btn btn-att btn-sm"
                    (click)="openModal(externalInfoModal)">
                    Add External Info
                  </button>
                </div>
                <table class="table table-striped" *ngIf="program.externalRefs?.length > 0" [disabled]="selectedProvider === 'gracenote' || program.isHighLight">
                  <thead>
                    <tr>
                      <th>System</th>
                      <th>Name</th>
                      <th>Value</th>
                      <th *ngIf="!program.isHighLight">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="cursor-pointer" *ngFor="let externalRef of program.externalRefs; let i = index">
                      <td>{{ externalRef.system || '--' }}</td>
                      <td>{{ externalRef.refName || '--' }}</td>
                      <td>{{ externalRef.id || '--' }}</td>
                      <td *ngIf="!program.isHighLight">
                        <ng-container [ngTemplateOutlet]="tableActions" [ngTemplateOutletContext]="{
                          modalName: externalInfoModal,
                          entity: 'externalRef',
                          fieldName: 'externalRefs',
                          filterName: 'index',
                          id: i
                        }"></ng-container>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="alert alert-warning mt-4" role="alert"
                *ngIf="!program.externalRefs?.length || program.externalRefs?.length == 0"
                style="margin-left: 10px; margin-right: 10px">
                No external info added
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="9" *ngIf="(program.type === 'EP' || program.type === 'SE') && isDthPlatform()">
            <a ngbNavLink>Configurations</a>
            <ng-template ngbNavContent>
              <div class="col">
                <label style="font-size: 13px;">TCS Configuration</label>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Action</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="cursor-pointer" *ngFor="let action of tcsActions; let i = index">
                      <td *ngIf ="program.type === action.useFor">
                        {{action.action}}
                      </td>
                      <td *ngIf ="program.type === action.useFor">
                        <input id="index" type="checkbox" [checked]="program?.dthInfo[action.field]" (change) = "onTCSActionCheck(action.field, $event)"/>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="navMain" class="mt-2 w-100 p-2"></div>
      </div>
    </div>
  </div>
  <div class="panel-footer mb-2 d-flex justify-content-between p-1">
    <button class="btn btn-att ml-2" (click)="saveProgram(false)" [disabled]="!program.type"
      *ngIf="selectedProvider === 'masterentity'">
      Quick Save
    </button>
    <div class="ml-auto">
      <button disabled class="btn btn-att mr-2" *ngIf="selectedProvider === 'gracenote'" (click)="copyToMaster()">
        Copy to master
      </button>
      <button (click)="saveProgram(true)" class="btn btn-att mr-2" *ngIf="selectedProvider === 'masterentity'" [disabled]="!program.type">
        Publish
      </button>
    </div>
  </div>

  <!--Manage Ratings Modal -->
  <ng-template #ratingModal id="ratingModal">
    <ng-container [ngTemplateOutlet]="modalHeader" [ngTemplateOutletContext]="{ headerTitle: 'Select Ratings' }">
    </ng-container>
    <div class="modal-body">
      <form>
        <div class="row" *ngIf="program.market == 'ssla'">
          <div class="col form-group">
            <label container="body" ngbTooltip="USA Parental Rating">USA Parental Ratings</label>
            <select class="form-control form-select" (change)="ratingOnChange()"
              [(ngModel)]="program.usaParentalRatings" name="usaParentalRatings">
              <option value="" selected>Select Ratings</option>
              <option value="{{ rating.id }}" *ngFor="let rating of usaParental">
                {{ rating.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="program.market == 'ssla'">
          <div class="col form-group">
            <label container="body" ngbTooltip="Motion Picture Association">MPAA Ratings</label>
            <select class="form-control form-select" (change)="ratingOnChange()" [(ngModel)]="program.mpaaRatings"
              name="mpaaRatings">
              <option value="" selected>Select Ratings</option>
              <option value="{{ rating.id }}" *ngFor="let rating of mpaaList">
                {{ rating.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row" *ngIf="program.market == 'sky'">
          <div class="col form-group">
            <label container="body" ngbTooltip="Dirección General de Radio, Televisión y Cinematografía">
              Brazil Ratings
            </label>
            <select class="form-control form-select" (change)="ratingOnChange()" [(ngModel)]="program.brazilRatings"
              name="brazilRatings">
              <option value="" selected>Select Ratings</option>
              <option value="{{ rating.id }}" *ngFor="let rating of brazilRatings">
                {{ rating.name }}
              </option>
            </select>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-att mr-2" (click)="closeModal()">Confirm</button>
    </div>
  </ng-template>

  <!-- Cast and Crew Modal -->
  <ng-template #castCrewModal id="castCrewModal">
    <ng-container [ngTemplateOutlet]="modalHeader" [ngTemplateOutletContext]="{ headerTitle: 'Manage cast and crew' }">
    </ng-container>
    <div class="modal-body">
      <div class="row">
        <div class="col input-group mr-2">
          <div class="input-group d-flex">
            <input type="text" class="form-control" spellcheck="false" placeholder="Search from existing celebrities"
              [(ngModel)]="celebritySearchString" [ngbTypeahead]="searchCelebrity" [resultFormatter]="formatter"
              [inputFormatter]="inputFormatBandListValue" (selectItem)="selectedItem($event)" />
            <div class="input-group-append">
              <button class="btn btn-att btn-outline-primary">
                <i class="fa fa-plus"></i>&nbsp;
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col">
          <div class="row mt-2">
            <div class="col">
              <div class="form-group cast-form-group">
                <label>Character Name</label>
                <input [readonly]="activeCast == 2 || isRemove ? true : false" type="text" class="form-control"
                  [(ngModel)]="castOrCrew.characterName" />
              </div>
            </div>
            <div class="col">
              <div class="form-group cast-form-group">
                <label>Role</label>
                <select [disabled]="isRemove" class="form-control" [(ngModel)]="castOrCrew.role"
                  (change)="showOtherInput(castOrCrew.role, 'role')">
                  <option *ngFor="let role of listRole" [value]="role">
                    {{ role }}
                  </option>
                </select><br />
                <input type="text" [readonly]="isRemove" class="form-control mt-2" [(ngModel)]="otherRole"
                  [hidden]="isRoleInputHidden" />
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <button class="btn btn-att mr-2 mb-3" [disabled]="
                  castOrCrew.castName != '' &&
                  !isRemove &&
                  ((activeCast == 1 && castOrCrew.characterName != '') ||
                    (activeCast == 2 && castOrCrew.role != ''))
                    ? false
                    : true
                " (click)="addNewCastCrew(castOrCrew)">
                Add
              </button>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col">
              <div class="celebrity-panel">
                <div class="celebrity-header">
                  <div class="d-flex justify-content-start">
                    <h6>Selected {{ activeCast == 1 ? 'Casts' : 'Crews' }}</h6>
                    <div class="ml-auto d-flex celebrity-button">
                      <button class="btn btn-danger py-0 px-2" [disabled]="!isRemove" (click)="removeCelebrity()">
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
                <div class="panel-body celebrity-wrapper">
                  <div *ngFor="
                      let celebrity of listSelectedCelebrity;
                      let i = index
                    " cdkDrag [cdkDragData]="program" (cdkDragStarted)="onDragStart($event, i)"
                    class="d-flex justify-content-between p-1">
                    <div id="celebrityItem-{{ i }}" class="celebrity-item d-block" (click)="selectCelebrity(celebrity)"
                      [class.active]="celebrity.index === castOrCrew.index">
                      <div class="celebrity-name d-flex">
                        <strong class="text-truncate">
                          {{ celebrity.firstName }} {{ celebrity.lastName }}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-att mr-2" [disabled]="!(listSelectedCelebrity?.length > 0)"
        (click)="addItem('groupedCastAndCrew')">
        Save
      </button>
    </div>
  </ng-template>

  <!-- Release modal -->
  <ng-template #releaseModal id="releaseModal">
    <ng-container [ngTemplateOutlet]="modalHeader" [ngTemplateOutletContext]="{ headerTitle: 'Add Release' }">
    </ng-container>
    <div class="modal-body">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Country</label>
            <select class="form-control" [(ngModel)]="release.country">
              <option *ngFor="let c of countries" [value]="c.code">
                {{ c.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Type</label>
            <select class="form-control" [(ngModel)]="release.type" (change)="showOtherInput(release.type, 'type')">
              <option *ngFor="let type of releaseType" [value]="type">
                {{ type }}
              </option>
            </select>
            <br />
            <input type="text" class="form-control" [(ngModel)]="otherType" [hidden]="isTypeInputHidden" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Date</label>
            <input type="date" class="form-control" [(ngModel)]="release.date" />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Medium</label>
            <select class="form-control" [(ngModel)]="release.medium"
              (change)="showOtherInput(release.medium, 'medium')">
              <option *ngFor="let med of medium" [value]="med">
                {{ med }}
              </option>
            </select><br />
            <input type="text" class="form-control" [(ngModel)]="otherMedium" [hidden]="isMediumInputHidden" />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-att mr-2" [disabled]="
          release.country === '' ||
          release.date === '' ||
          release.medium === '' ||
          release.type === '' ||
          (release.type === 'Others' && otherType == '') ||
          (release.medium === 'Others' && otherMedium == '')
        " (click)="addItem('releases', release)">
        Add
      </button>
    </div>
  </ng-template>

  <!-- Awards modal -->
  <ng-template #awardsModal id="awardsModal">
    <ng-container [ngTemplateOutlet]="modalHeader" [ngTemplateOutletContext]="{ headerTitle: 'Add Awards' }">
    </ng-container>
    <div class="modal-body">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Award</label>
            <input type="text" name="name" class="form-control" [(ngModel)]="award.name" />
            <br />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Award Won</label>
            <br />
            <div class="row">
              <div class="col">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                    [value]="'true'" [(ngModel)]="award.won" />
                  <label class="form-check-label" for="inlineRadio1">True</label>
                </div>
              </div>
              <div class="col">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                    [value]="'false'" [(ngModel)]="award.won" />
                  <label class="form-check-label" for="inlineRadio2">False</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <label>Category</label>
          <ng-select [items]="categories" [multiple]="false" [hideSelected]="true" [closeOnSelect]="true"
            [searchable]="true" [(ngModel)]="selectedCategory" (change)="categoryOnChange()" bindLabel="categoryName"
            name="category">
            <ng-template ng-option-tmp let-item="item">
              <div container="body" ngbTooltip="{{ item.categoryId }}">
                {{ item.categoryName || '---' }}
              </div>
            </ng-template>
          </ng-select>
          <br />
          <input type="text" [style.display]="
              selectedCategory != null &&
              selectedCategory['categoryName'] == 'Others'
                ? 'block'
                : 'none'
            " class="form-control" [(ngModel)]="award.category" />
          <br />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Year</label>
            <ng-select [items]="years" [multiple]="false" [hideSelected]="true" [closeOnSelect]="true"
              [searchable]="true" [(ngModel)]="award.year" bindLabel="year" name="year">
              <ng-template ng-option-tmp let-item="item">
                <div container="body" ngbTooltip="{{ item }}">
                  {{ item }}
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Recipient</label>
            <ng-select [items]="program.credits" [multiple]="false" [hideSelected]="true" [closeOnSelect]="true"
              [searchable]="true" [(ngModel)]="selectedRecipient" (change)="recipientOnChange()" bindLabel="castName"
              name="recipient">
              <ng-template ng-option-tmp let-item="item">
                <div container="body" ngbTooltip="{{ item.personId }}">
                  {{ item.castName || '---' }}
                </div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-att mr-2" [disabled]="
          award.name != '' &&
          award.category != '' &&
          award.year != '' &&
          award.recipient != ''
            ? false
            : true
        " (click)="addItem('awards', award)">
        Add
      </button>
    </div>
  </ng-template>

  <!-- External Info modal -->
  <ng-template #externalInfoModal id="externalInfoModal">
    <ng-container [ngTemplateOutlet]="modalHeader" [ngTemplateOutletContext]="{ headerTitle: 'Add External Info' }">
    </ng-container>
    <div class="modal-body">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>System</label>
            <select class="form-control" [(ngModel)]="externalRef.system" (change)="onSystemChange($event)">
              <option value="" disabled selected hidden>Select system</option>
              <option *ngFor="let system of systems" [value]="system.system">
                {{ system.system }}
              </option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Name</label>
            <select name = 'refName' class="form-control" [(ngModel)]="externalRef.refName">
              <option value="" disabled selected hidden>Select reference name</option>
              <option *ngFor="let refName of refNames" [value]= 'refName'>
                {{ refName }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Value</label>
            <input type="text" name="name" class="form-control" [(ngModel)]="externalRef.id" />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-att mr-2" (click)="addItem('externalRefs', externalRef)" [disabled]="
          externalRef.system != '' &&
          externalRef.id != ''
            ? false
            : true">
        Add
      </button>
    </div>
  </ng-template>

  <!-- Keywords modal -->
  <ng-template #keywordsModal id="keywordsModal">
    <ng-container [ngTemplateOutlet]="modalHeader" [ngTemplateOutletContext]="{ headerTitle: 'Add Keywords' }">
    </ng-container>
    <div class="modal-body">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Key</label>
            <input type="text" class="form-control" [(ngModel)]="keyword.key" />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Value</label>
            <input type="text" class="form-control" [(ngModel)]="keyword.value" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Source</label>
            <input type="text" class="form-control" [(ngModel)]="keyword.src" />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-att mr-2" (click)="addItem('keywords', keyword)">
        Add
      </button>
    </div>
  </ng-template>

  <!--Duplicate program confirm modal-->
  <ng-template #duplicateProgConfirmModal id='duplicateProgConfirmModal'>
    <ng-container [ngTemplateOutlet]="modalHeader" [ngTemplateOutletContext]="{ headerTitle: 'Warning' }">
    </ng-container>
    <div class="modal-body">
      <span>
        There is an existing program with the same titles and original air date. Click here to view duplicate <a
          [routerLink]="" (click)="openModal(duplicateModal)">program detail</a>. Do you still want to continue?
      </span>
    </div>
    <div class="modal-footer">
      <button class="btn btn-att ml-2" (click)="closeModal()">
        Cancel
      </button>
      <button class="btn btn-att mr-2" (click)="saveProgWithConfirmed(isPublish)">
        Ok
      </button>
    </div>
  </ng-template>
  <!--Duplicate program modal-->
  <ng-template #duplicateModal id="duplicateModal">
    <div class="program-form">
      <ng-container [ngTemplateOutlet]="modalHeader" [ngTemplateOutletContext]="{ headerTitle: 'Program Detail' }">
      </ng-container>
      <div class="duplicate-program">
        <div *ngFor="let duplicateProgram of duplicatePrograms" class="panel-body" id="programForm">
          <div class="container-fluid">
            <div class="title-desc mt-1">
              <div class="row season-episode">
                <div class="col">
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label>Program ID</label>
                        <input type="text" class="form-control" [(ngModel)]="duplicateProgram._id" disabled />
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label for="vrioId">VRIO ID</label>
                        <input disabled id="vrioId" type="text" class="form-control"
                          [(ngModel)]="duplicateProgram.vrioId" />
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label for="optaId">Opta ID</label>
                        <input disabled id="optaId" type="text" class="form-control"
                          [(ngModel)]="duplicateProgram.optaId.id" />
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label for="rootId">Root Id</label>
                        <input disabled id="rootId" type="text" class="form-control"
                          [(ngModel)]="duplicateProgram.rootId.id" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="row season-episode">
                        <div class="col">
                          <div class="form-group has-validation">
                            <label for="originalTitle">Original Title</label>
                            <input id="originalTitle" type="text" class="form-control" disabled
                              [(ngModel)]="duplicateProgram.originalTitle.value" />
                          </div>
                        </div>
                        <div class="col">
                          <div class="row">
                            <div class="col">
                              <div class="form-group">
                                <label for="origAirDate">Original Air Date</label>
                                <input disabled id="origAirDate" type="date" class="form-control"
                                  [(ngModel)]="duplicateProgram.origAirDate" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="form-group">
                            <label for="englishTitle">English Title</label>
                            <input id="englishTitle" type="text" class="form-control" disabled
                              [(ngModel)]="duplicateProgram.englishTitle.value" />
                          </div>
                        </div>
                        <div class="col">
                          <div class="form-group">
                            <label for="spanishTitle">
                              Spanish Title
                            </label>
                            <input [hidden]="isSky" id="spanishTitle" type="text" class="form-control" disabled
                              [(ngModel)]="duplicateProgram.spanishTitle.value" />
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col form-group">
                          <label for="englishDescription">English Description</label>
                          <textarea id="englishDescription" class="form-control" disabled
                            [(ngModel)]="duplicateProgram.englishDescription.value" rows="2" spellcheck="false">
                        </textarea>
                        </div>
                        <div class="col form-group">
                          <label for="spanishDescription">
                            Spanish Description
                          </label>
                          <textarea id="spanishDescription" class="form-control"
                            [(ngModel)]="duplicateProgram.spanishDescription.value" rows="2" spellcheck="false"
                            disabled>
                          </textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row season-episode">
                  <div class="col">
                    <div class="row">
                      <div class="col pl-2">
                        <div class="form-group">
                          <label for="programType">Program Type</label>
                          <input disabled id="programType" type="text" class="form-control"
                            [(ngModel)]="duplicateProgram.programType" />
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label for="countries">Countries</label>
                          <input disabled id="countries" type="text" class="form-control"
                            [(ngModel)]="duplicateProgram.countriesOfOrigin" />
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group" *ngIf="duplicateProgram.programType === 'MOVIE'">
                          <label for="releaseYear">Release Year</label>
                          <input [disabled]="true" id="releaseYear" type="number" class="form-control"
                            [(ngModel)]="duplicateProgram.releaseYear" />
                        </div>
                        <div class="form-group" *ngIf="duplicateProgram.programType !== 'MOVIE'">
                          <label for="seasonYear">Season Year</label>
                          <input [disabled]="true" id="releaseYear" type="number" class="form-control"
                            [(ngModel)]="duplicateProgram.releaseYear" />
                        </div>
                      </div>
                      <div class="col mr-2">
                        <div class="form-group">
                          <label for="sportSeason">Sport Season</label>
                          <input [disabled]="true" id="sportSeason" type="number" class="form-control"
                            [(ngModel)]="duplicateProgram.sportSeason" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row season-episode">
                  <div class="col">
                    <div class="row">
                      <div class="col pl-2">
                        <div class="form-group">
                          <label for="season">Season</label>
                          <input disabled id="season" type="text" class="form-control"
                            [(ngModel)]="duplicateProgram.seasonNumber" />
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label for="episode">Episode</label>
                          <input disabled id="episode" type="text" class="form-control"
                            [(ngModel)]="duplicateProgram.episodeNumber" />
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label for="alternateSeason">Alternate Season</label>
                          <input disabled id="alternateSeason" type="number" class="form-control"
                            [(ngModel)]="duplicateProgram.alternateSeasonNumber" />
                        </div>
                      </div>
                      <div class="col mr-2">
                        <div class="form-group">
                          <label for="alternateEpisode">Alternate Episode</label>
                          <input disabled id="alternateEpisode" type="text" class="form-control"
                            [(ngModel)]="duplicateProgram.alternateEpisodeNumber" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label for="runTime">Runtime</label>
                        <input disabled id="runTime" type="text" class="form-control"
                          [(ngModel)]="duplicateProgram.runTime" />
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label for="originalAudiolang">Original Audio</label>
                        <input disabled id="originalAudiolang" type="text" class="form-control"
                          [(ngModel)]="duplicateProgram.originalAudiolang" />
                      </div>
                    </div>
                    <div class="col">
                      <div class="form-group">
                        <label>Source</label>
                        <input type="text" class="form-control" [(ngModel)]="duplicateProgram.source" disabled />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- Common header modal template -->
  <ng-template #modalHeader id="modalHeader" let-header="headerTitle">
    <div class="modal-header">
      <h4 class="modal-title">{{ header }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </ng-template>

  <!-- Common table actions template for releases, awards and keywords list  -->
  <ng-template #tableActions let-modalName="modalName" let-entity="entity" let-fieldName="fieldName"
    let-filterName="filterName" let-id="id">
    <span class="cursor-pointer" (click)="openModal(modalName, entity, fieldName, filterName, id)">
      <i class="fas fa-edit"></i>
    </span>
    <span class="cursor-pointer" (click)="openDeleteModal(fieldName, filterName, id)">
      <i class="far fa-window-close ml-2"></i>
    </span>
  </ng-template>
</div>
