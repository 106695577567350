<div class="container-fluid mt-3">
  <div class="d-flex justify-content-between p2">
    <h3>User Management</h3>
  </div>
  <div class="row mt-2">
    <div class="col">
      <ul ngbNav #navUser="ngbNav" [(activeId)]="active" class="nav-tabs">
        <li [ngbNavItem]="1">
          <a ngbNavLink>Users</a>
          <ng-template ngbNavContent>
            <div class="user-list">
              <table class="table table-striped">
                <thead class="bg-dark">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Username</th>
                    <th scope="col">Email</th>
                    <th scope="col">Roles/Access</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="!hasUser">
                    <tr>
                      <td colspan="6">No Data Found</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="hasUser">
                    <tr
                      class="cursor-pointer"
                      *ngFor="
                        let user of users
                          | slice
                            : (page - 1) * pageSize
                            : (page - 1) * pageSize + pageSize;
                        let i = index
                      "
                    >
                      <td>{{ user.firstName }} {{ user.lastName }}</td>
                      <td>{{ user.username }}</td>
                      <td>{{ user.email }}</td>
                      <td>{{ formatRoles(user.roleIds) }}</td>
                      <td>
                        <div class="switcher ml-1">
                          <label for="user-{{ i }}">
                            <input
                              type="checkbox"
                              class="user-roles"
                              id="user-{{ i }}"
                              [checked]="user.active"
                              (change)="
                                user.active = !user.active;
                                manageUserStatus(user)
                              "
                            />
                            <span><small></small></span>
                          </label>
                        </div>
                      </td>
                      <td>
                        <span
                          class="cursor-pointer"
                          (click)="openModal(userModal, user.id)"
                        >
                          <i class="fas fa-edit"></i>
                        </span>
                        <span
                          class="cursor-pointer"
                          (click)="openDeleteModal(user)"
                        >
                          <i class="fa fa-trash ml-2"></i>
                        </span>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <div class="d-flex justify-content-between py-2">
                <select class="custom-select" [(ngModel)]="pageSize">
                  <option [ngValue]="5">5 items per page</option>
                  <option [ngValue]="10">10 items per page</option>
                  <option [ngValue]="25">25 items per page</option>
                </select>
                <ngb-pagination
                  [(page)]="page"
                  [pageSize]="pageSize"
                  [maxSize]="10"
                  [collectionSize]="collectionSize"
                >
                </ngb-pagination>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink>Roles and Permission</a>
          <ng-template ngbNavContent>
            <app-users-roles></app-users-roles>
          </ng-template>
        </li>
        <li class="ml-auto" *ngIf="active === 1">
          <div class="form-group form-inline search-user">
            <div class="input-group d-flex">
              <input
                type="search"
                [(ngModel)]="searchUser"
                (ngModelChange)="searchChange($event)"
                class="input-search form-control"
                placeholder="Search users or email"
                (keyup.enter)="onSearchFilter()"
              />
              <div class="input-group-append">
                <button class="btn btn-light" (click)="onSearchFilter()">
                  <i class="fa fa-search mx-2"></i>
                </button>
              </div>
            </div>
            <button class="ml-1 btn btn-light" (click)="openModal(userModal)">
              <i class="fa fa-user-plus"></i>&nbsp;
            </button>
          </div>
        </li>
      </ul>
      <div [ngbNavOutlet]="navUser" class="bg-white p-1"></div>
    </div>
  </div>
</div>
<ng-template #userModal>
  <div class="modal-header">
    <h4 class="modal-title">{{ action }} User</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="alert alert-danger alert-dismissible" *ngIf="responseMessage">
        {{ responseMessage }}
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Status</label>
            <div class="d-flex">
              <div class="switcher ml-1">
                <label for="Active">
                  <input
                    type="checkbox"
                    class="user-roles"
                    id="Active"
                    [checked]="user.active"
                    (change)="user.active = !user.active"
                  />
                  <span><small></small></span>
                </label>
                <span class="ml-2">Active</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>First Name*</label>
            <input
              id="firstName"
              type="text"
              name="firstName"
              [(ngModel)]="user.firstName"
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Last Name*</label>
            <input
              id="lastName"
              type="text"
              name="firstName"
              [(ngModel)]="user.lastName"
              class="form-control"
              required
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>User Name*</label>
            <input
              id="username"
              type="text"
              name="username"
              [(ngModel)]="user.username"
              ngDefaultControl
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Email*</label>
            <input
              id="email"
              type="email"
              name="email"
              [(ngModel)]="user.email"
              class="form-control"
              required
            />
          </div>
        </div>
      </div>
      <div *ngIf="!isEdit" class="row">
        <div class="col">
          <div class="form-group">
            <label>Password*</label>
            <input
              id="password"
              type="password"
              name="password"
              [(ngModel)]="user.password"
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="col">
          <div *ngIf="!isEdit" class="form-group">
            <label>Confirm Password*</label>
            <input
              id="confirmPassword"
              type="password"
              name="confirmPassword"
              [(ngModel)]="user.confirmPassword"
              class="form-control"
              required
            />
          </div>
        </div>
      </div>

      <div *ngIf="isEdit">
        <h6>Update Password</h6>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>New Password</label>
              <input
                id="password"
                type="password"
                name="newPassword"
                [(ngModel)]="user.newPassword"
                class="form-control"
                required
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Confirm Password</label>
              <input
                id="confirmPassword"
                type="password"
                name="confirmPassword"
                [(ngModel)]="user.confirmPassword"
                class="form-control"
                required
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col form-group">
          <label>Roles/Access*</label>
          <ng-select
            [items]="userRoles"
            [multiple]="true"
            [hideSelected]="true"
            [closeOnSelect]="true"
            [searchable]="true"
            [(ngModel)]="user.roles"
            bindLabel="name"
            name="userRole"
          >
          </ng-select>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button (click)="submit()" [disabled]="showLoader" class="btn btn-att">
      <i *ngIf="showLoader" class="fas fa-spinner fa-spin"></i> Save
    </button>
  </div>
</ng-template>
