import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {OFFSET_OPTIONS} from 'src/app/utils/contants'
import {NgbCalendar, NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {ScheduleVersionRequest} from 'src/app/pages/schedules/schedule';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-schedule-header',
  templateUrl: './schedule-header.component.html',
  styleUrls: ['./schedule-header.component.scss'],
})
export class SchedulesHeaderComponent implements OnInit {
  // Accessors
  @Input() hasEvent = false;
  @Input() checkAll = false;
  @Input() checkAllIndeterminate = false;
  @Input() isSchedulesLoading = false;
  @Input() updateScheduleDate: Function;
  @Input() updateTimezone: Function;

  @Output() onShowOrHideProgramPanel: EventEmitter<boolean> = new EventEmitter();
  @Output() onCheckAllEvents: EventEmitter<boolean> = new EventEmitter();
  @Output() onDisplayProgramPanel: EventEmitter<boolean> = new EventEmitter();

  // private fields
  shouldHideProgramPanel = true;
  TODAY: NgbDate = this.calendar.getToday();
  DEFAULT_TIMEZONE = environment.timezone ? OFFSET_OPTIONS.find(o => o.value === environment.timezone) : OFFSET_OPTIONS[0];
  request: ScheduleVersionRequest = {
    date: this.TODAY,
    timezone: this.DEFAULT_TIMEZONE,
  };
  offsetOptions = OFFSET_OPTIONS;
  hoveredDate: NgbDate;
  showTimezoneDropDown = false;

  // Life cycle methods
  constructor(private calendar: NgbCalendar,) {
  }

  ngOnInit() {
  }

  // Show or hide the timezone dropdown list.
  toggleRenderTimezoneDropdown() {
    this.showTimezoneDropDown = !this.showTimezoneDropDown;
  }

  // Used by the datepicker to set the UX on datepicker.
  isRange(selectedDate: NgbDate) {
    const fromDate = this.TODAY;
    const toDate = this.calendar.getNext(fromDate, 'd', 13)
    return (
      selectedDate.equals(fromDate) ||
      (toDate && selectedDate.equals(toDate)) ||
      this.isInside(selectedDate) ||
      this.isHovered(selectedDate)
    );
  }

  // Used by the datepicker to highlight selected date.
  isInside(selectedDate: NgbDate) {
    const fromDate = this.TODAY;
    const toDate = this.calendar.getNext(fromDate, 'd', 13)
    return toDate && selectedDate.after(fromDate) && selectedDate.before(toDate);
  }

  // Used by the datepicker to highlight 14 days (from today) as the range of the current schedule.
  isHovered(selectedDate: NgbDate) {
    const fromDate = this.TODAY;
    const toDate = this.calendar.getNext(fromDate, 'd', 13)
    return (
      fromDate &&
      !toDate &&
      this.hoveredDate &&
      selectedDate.after(fromDate) &&
      selectedDate.before(this.hoveredDate)
    )
  }

  // Handle checking all events on UI.
  onCheckAllScheduleEvents($event) {
    this.onCheckAllEvents.emit($event.target.checked);
  }

  toggleProgramPanel() {
    this.shouldHideProgramPanel = !this.shouldHideProgramPanel;
    this.onDisplayProgramPanel.emit(this.shouldHideProgramPanel);
  }
}
