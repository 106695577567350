<div class="container-fluid mt-3">
  <div class="row mt-2 p-1">
    <div class="col-lg-3 d-none d-lg-block">
      <app-channels-list
        [targetComponent]="'schedules'"
        componentName="Service Channel"
        (channel)="onChannelChange($event)"
        (autoPublish)="onProcessingTypeChange($event)"
      ></app-channels-list>
    </div>
    <div class="{{hidePrograms? 'col-lg-2 d-lg-block':'d-none'}}">
      <app-schedule-versions
        [displayEmptyScheduleVersionWarning]="displayEmptyScheduleVersionWarning"
        (onSelectedVersionChanged)="handleSelectedScheduleVersion($event)"
      ></app-schedule-versions>
    </div>
    <div class="col-lg-{{ hidePrograms ? '7' : '6' }}">
      <app-schedule-panel
        [selectedChannel]="channel"
        [shouldHideProgramPanel]="hidePrograms"
        [selectedScheduleVersions]="selectedScheduleVersions"
        (onScheduleVersionRequestChanged)="onScheduleRequestChanged($event)"
        (onShowOrHideProgramPanel)="onShowOrHideProgramPanel($event)"
        (onInvalidProgram)="onInvalidProgram($event)"
      ></app-schedule-panel>
    </div>
    <div class="{{!hidePrograms? 'col-lg-3 d-lg-block':'d-none'}}">
      <app-program-list
        pageName="schedules"
        [selectedMarket]="'LatAm/Mx'"
        [dropList]="['schedule-0','schedule-1']"
      >
      </app-program-list>
    </div>
  </div>
  <div class="page-title d-flex justify-content-start">
    <h4>{{ 'vls.schedules.pageTitle' | translate }}</h4>
  </div>
</div>
