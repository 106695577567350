<div class="container-fluid reports-publications mt-3">
    <div class="page-title d-flex justify-content-start">
        <h4>Publishing LS File</h4>
    </div>
    <div class="row">
        <div class="col">
            <div class="panel-header">
                <div class="row">
                    <div class="col-2">
                        <h6>Channel to Publish</h6>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-3 publication-schedule-input">
                        <div class="input-group d-flex">
                            <input type="search" class="input-search form-control form-control-sm"
                                placeholder="Search Channel" [(ngModel)]="searchString" 
                                (ngModelChange)="searchChange(this.searchString)"
                            />
                            <div class="input-group-append">
                                <button class="btn btn-light form-control-sm publication-schedule-btn">
                                    <i class="fa fa-search mx-2"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 publication-schedule-input">
                        <label class="label-for-date">
                            Start date &nbsp;
                        </label>
                        <input [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" placeholder="mm/dd/yyyy"
                            [(ngModel)]="publisherModel.startDate" [min]="publisherModel.minStartDate" [max]="publisherModel.endDate" (focus)="onStartDateChange()" class="form-control form-control-sm date-input"/>  
                        <div class="input-group-append date-button">
                            <button class="btn btn-light form-control-sm publication-schedule-btn" [owlDateTimeTrigger]="dt1" (focus)="onStartDateChange()">
                                <i class="fa fa-calendar"></i>
                            </button>
                        </div>
                        <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
                    </div>
                    <div class="col-3 publication-schedule-input">
                        <label class="label-for-date">
                            End date &nbsp;
                        </label>
                        <input [owlDateTime]="dt2" [owlDateTimeTrigger]="dt2" placeholder="mm/dd/yyyy"
                            [(ngModel)]="publisherModel.endDate" [min]="publisherModel.minEndDate" [max]="publisherModel.maxDate" (focus)="onEndDateChange()"
                               class="form-control form-control-sm date-input" />
                        <div class="input-group-append date-button">
                            <button class="btn btn-light form-control-sm publication-schedule-btn" [owlDateTimeTrigger]="dt2" (focus)="onEndDateChange()">
                                <i class="fa fa-calendar"></i>
                            </button>
                        </div>
                        <owl-date-time [pickerType]="'calendar'" #dt2></owl-date-time>
                    </div>
                </div>
            </div>
            <div class="panel-body p-3">
                <ng-container *ngIf="showLoader">
                    <div class="loading">
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                  </ng-container>
                <div class="table-content table-fixed">
                    <table class="table table-striped">
                        <thead class="bg-dark">
                            <tr>
                              <th *ngFor="let header of sortByPublishingHeader; let j = index">
                                <div class="d-flex align-items-end justify-content-start">
                                    {{header.name}}
                                    <div *ngIf="header.isDisplay" class="sort-button ml-2">
                                        <a class="btn btn-xs btn-link p-0" *ngIf="header.isAsc != false" (click)="onChangeSorting(header.fieldName, j, false)">
                                            <i class="fas fa-sort-up sort-icon"></i>
                                        </a>
                                        <a class="btn btn-xs btn-link p-0" *ngIf="header.isAsc != true" (click)="onChangeSorting(header.fieldName, j, true)">
                                            <i class="fas fa-sort-down sort-icon"></i>
                                        </a>
                                    </div>
                                </div>
                            </th>
                            <th>
                            </th>
                            <th>
                            </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let channel of channelsWithSlaves; let i = index;">
                                <td [ngClass]="{'slave-display-left': channel.displayAsSlave == true}">{{ channel.sourceId || "--"  }}</td>
                                <td>{{ channel.channelNames[0]?.value || "--" }}</td>
                                <td>{{ channel.createdDate }}</td>
                                <td>
                                    <div *ngIf="getKindOfChannel(channel) != ''" class="badge badge-info">
                                        {{ getKindOfChannel(channel) }}
                                    </div>
                                </td>
                                <td [ngClass]="{'checkbox-display-right': channel.displayAsSlave != true}">
                                    <input type="checkbox" (click)="updateListChannels(channel, i)"
                                        [checked]="channel.checked" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="panel-footer justify-content-end mb-2">
                <div class="lsPublishingPagination">
                    <ngb-pagination
                    [(page)]="page"
                    [pageSize]="pageSize"
                    [maxSize]="10"
                    [collectionSize]="collectionSize"
                    (pageChange) = "onPageChange()"
                  >
                  </ngb-pagination>
                  </div>
                <div class="float-right">
                    <button class="btn btn-sm btn-att mr-2 mt-2" [disabled]="disabledPublishButton() || !!isPublishing" (click)="publisher()">
                        Publish
                    </button>
                    <!-- <button class="btn btn-sm btn-att mr-2 mt-2" (click) = "onClear()">Cancle</button> -->
                </div>
            </div>
        </div>
    </div>
</div>
