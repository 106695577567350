<div class="program-form">
  <div class="panel-header d-flex">
    <div class="d-flex justify-content-start">
      <h6 *ngIf="!isSky">
        {{ formatHeaderTitle('englishName', 'spanishName') || 'New Venue' }}
      </h6>
      <h6 *ngIf="isSky">
        {{ formatHeaderTitle('englishName', 'portugueseName') || 'New Venue' }}
      </h6>
    </div>
  </div>
  <div class="panel-body" id="venueForm">
    <div class="container-fluid">
      <ng-container *ngIf="isLoading">
        <div class="loading">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </ng-container>
      <div class="title-desc mt-2">
        <div class="row season-episode">
          <div class="col-6">
            <div class="form-group has-validation">
              <label for="venueId">Venue ID</label>
              <input id="venueId" type="text" class="form-control" [(ngModel)]="venue.id" disabled />
            </div>
          </div>
          <div class="col-4">
            <div class="form-group has-validation">
              <label for="optaId">Opta ID</label>
              <input id="optaId" type="text" class="form-control" [(ngModel)]="venue.externalId" [disabled]="true" />
            </div>
          </div>
          <div class="col-2">
            <div class="form-group has-validation">
              <label for="capacity">Capacity</label>
              <input id="capacity" type="text" class="form-control" [(ngModel)]="venue.capacity" />
            </div>
          </div>
        </div>
        <div class="row seeInfoBtn mt-2">
          <div class="col">
            <div>
              <div class="btn-ctrl" *ngIf="venue?.otherLanguage">
                <label>Other Name</label>
                <label class="switch">
                  <input type="checkbox" [checked]="venue?.isOtherTitleHide"
                    (click)="showHideInformation('isOtherTitleHide')">
                  <span class="slider round"></span>
                </label>
              </div>
              <div class="btn-ctrl">
                <label>{{isSky ? CONSTANTS.PORTUGUESE : CONSTANTS.SPANISH}} Name</label>
                <label class="switch">
                  <input type="checkbox" [checked]="venue?.isPtEsTitleHide"
                    (click)="showHideInformation('isPtEsTitleHide')">
                  <span class="slider round"></span>
                </label>
              </div>
              <div class="btn-ctrl">
                <label>English Name</label>
                <label class="switch">
                  <input type="checkbox" [checked]="venue?.isEnTitleHide"
                    (click)="showHideInformation('isEnTitleHide')">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row season-episode mt-2">
          <div class="col" *ngIf="venue?.isEnTitleHide">
            <div class="form-group">
              <label for="englishName">English Venue Name</label>
              <input id="englishName" type="text" class="form-control" [(ngModel)]="venue.englishName.value"
                (keyup)="onFieldChange($event, 'Name', true)" />
            </div>
          </div>
          <div class="col" *ngIf="venue?.isPtEsTitleHide">
            <div class="form-group">
              <label for="spanishName">
                {{isSky ? CONSTANTS.PORTUGUESE : CONSTANTS.SPANISH}} Venue Name
              </label>
              <input [hidden]="isSky" id="spanishName" type="text" class="form-control"
                (keyup)="onFieldChange($event, 'Name')" [(ngModel)]="venue.spanishName.value" />
              <input [hidden]="!isSky" id="portugueseName" type="text" class="form-control"
                (keyup)="onFieldChange($event, 'Name')" [(ngModel)]="venue.portugueseName.value" />
            </div>
          </div>
          <div class="col" *ngIf="venue?.isOtherTitleHide">
            <div class="form-group">
              <label for="otherName">Other Venue Name</label>
              <input id="otherName" type="text" class="form-control" [(ngModel)]="venue.otherName.value"
                (keyup)="onFieldChange($event, 'Name', true)" />
            </div>
          </div>
        </div>
        <div class="row season-episode mt-2">
          <div class="col-6">
            <div class="form-group has-validation">
              <label for="city">City</label>
              <input id="city" type="text" class="form-control" [(ngModel)]="venue.newCity" />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group has-validation">
              <label for="countryCode">Country code</label>
              <ng-select [items]="countryCodes" [multiple]="false" [hideSelected]="true" [closeOnSelect]="true"
                [searchable]="true" [(ngModel)]="venue.countryCode" (change)="countryCodeOnChange()" bindLabel="name"
                name="countryCode">
                <ng-template ng-option-tmp let-item="item">
                  <div container="body" ngbTooltip="{{ item.code }}">
                    {{ item.name }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group has-validation">
              <label for="timeZone">Timezone</label>
              <input id="timeZone" type="text" class="form-control" [(ngModel)]="venue.timeZone" />
            </div>
          </div>
        </div>
        <div class="row season-episode mt-2">
          <div class="col-6">
            <div class="form-group has-validation">
              <label for="address">Address</label>
              <input id="address" type="text" class="form-control" [(ngModel)]="venue.newAddress" />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group has-validation">
              <label for="longitude">Longitude</label>
              <input id="longitude" type="text" class="form-control" [(ngModel)]="venue.longitude" />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group has-validation">
              <label for="latitude">Latitude</label>
              <input id="latitude" type="text" class="form-control" [(ngModel)]="venue.latitude" />
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3 pl-3">
        <ul ngbNav #navMain="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs">
          <li [ngbNavItem]="1">
            <a ngbNavLink>Images</a>
            <ng-template ngbNavContent>
              <div class="mt-2 program-pictures-list">
                <div class="
                    row
                    no-gutters
                    row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5
                  ">
                  <div *ngFor="let image of venue.publishedImages; let i = index" class="col pb-2">
                    <ng-container [ngTemplateOutlet]="venueImageCard" [ngTemplateOutletContext]="{
                        image: image,
                        i: i,
                        published: true
                      }"></ng-container>
                  </div>
                </div>
              </div>
              <ul ngbNav #navImages="ngbNav" [(activeId)]="navImage" class="nav-pills p-2 mt-1">
                <li class="pr-5" [ngbNavItem]="i + 1" *ngFor="let size of venue.imageSizes; let i = index">
                  <a ngbNavLink>{{ size }}</a>
                  <ng-template ngbNavContent>
                    <div role="alert" class="alert alert-warning mt-2" *ngIf="!venue.groupedImages[size]?.length">
                      No {{ size }} images added
                    </div>
                    <div class="mt-4 program-pictures-list" *ngIf="venue.groupedImages[size]?.length">
                      <div class="
                          row
                          no-gutters
                          row-cols-sm-2
                          row-cols-md-3
                          row-cols-lg-4
                          row-cols-xl-5
                        ">
                        <div *ngFor="
                            let image of venue.groupedImages[size];
                            let i = index
                          " class="col pb-2">
                          <ng-container [ngTemplateOutlet]="venueImageCard" [ngTemplateOutletContext]="{
                              image: image,
                              i: i,
                              published: false
                            }"></ng-container>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
              </ul>
              <ng-template #venueImageCard let-image="image" let-i="i" let-published="published">
                <div class="card program-image mb-2">
                  <div class="card-header d-flex justify-content-start">
                    <input *ngIf="!published" [checked]="image.published" (click)="publishedImage(image)" type="radio"
                      class="img-radio-btn cursor-pointer" name="image-selected" ngbTooltip="Click to Publish" />
                    <span (click)="deleteImage(image, i)" *ngIf="image.imageFile && !published" class="
                        pub-span
                        badge badge-warning
                        delete-image
                        cursor-pointer
                      ">X</span>
                    <span *ngIf="image.published" class="pub-span badge badge-success">PUB </span>
                    <span container="body" class="badge badge-warning pub-span" *ngIf="!image.published">
                      UNPUB
                    </span>
                  </div>
                  <div class="card-body">
                    <img class="card-img-top cast-crew-img" [src]="
                        image.uri
                          ? image.baseUrl + image.uri
                          : image.imageURL || previewUrl
                      " style="object-fit: contain; width: 100%" (error)="onImageError($event)" />
                  </div>
                  <div class="card-footer">
                    <p style="
                        margin-bottom: 0px;
                        text-align: left;
                        display: flex;
                        flex-direction: column;
                      " class="ml-auto">
                      <span>
                        <a *ngIf="selectedProvider === 'gracenote'" ngbTooltip="{{ image.id }}" target="_blank"
                          href="http://tmsimg.com/{{ image.uri }}">
                          Category: {{ image.category }}
                        </a>
                        <a *ngIf="selectedProvider !== 'gracenote'" ngbTooltip="{{ image.id }}" target="_blank" href="{{
                            image.uri ? image.baseUrl + image.uri : previewUrl
                          }}">
                          Category: {{ image.category }}
                        </a>
                      </span>
                      <span class="font-weight-bold">
                        Ratio: {{ image.ratio }}
                      </span>
                      <span class="font-weight-bold">
                        Source: {{
                        image.source === 'gracenote'
                        ? 'GN'
                        : image.source === 'VLS' || !image.source
                        ? 'VLS'
                        : 'DMS'
                        }}
                      </span>
                    </p>
                  </div>
                </div>
              </ng-template>
              <div class="d-flex justify-content-between mt-4 pl-2 pr-2">
                <div class="btn-group d-flex w-100">
                  <div class="btn-group" ngbDropdown>
                    <button class="btn btn-att btn-upload" ngbDropdownToggle style="font-size: 14px">
                      {{ selectedImageCategory }}
                    </button>
                    <div class="dropdown-menu" ngbDropdownMenu>
                      <button class="custom-dropdown-item" *ngFor="let category of imageCategories"
                        (click)="setImageCategory(category)" ngbDropdownItem>
                        {{ category }}
                      </button>
                    </div>
                  </div>
                  <div class="custom-file program-image">
                    <input id="imageUpload" type="file" accept="image/png, image/jpeg"
                      (change)="onFileSelect($event)" />
                    <label container="body" ngbTooltip="Click to select image" id="inputLabel"
                      class="custom-file-label form-control" for="imageUpload">
                    </label>
                  </div>
                </div>
                <button class="btn btn-att btn-upload" [disabled]="!image.imageFile" (click)="addImage(image)">
                  Add
                </button>
              </div>
              <div [ngbNavOutlet]="navImages" class="pl-2 pr-2"></div>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>External Info</a>
            <ng-template ngbNavContent>
              <div class="d-flex justify-content-end mb-1 ml-2 mr-2">
                <button class="btn btn-att btn-sm" (click)="openModal(externalInfoModal)">
                  Add External Info
                </button>
              </div>
              <table class="table table-striped ml-2 mr-2" *ngIf="venue.externalRefs?.length">
                <thead>
                  <tr>
                    <th>System</th>
                    <th>Ref Name</th>
                    <th>Value</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="cursor-pointer" *ngFor="let externalRef of venue.externalRefs; let i = index">
                    <td>{{ externalRef.system || '--' }}</td>
                    <td>{{ externalRef.refName || '--' }}</td>
                    <td>{{ externalRef.id || '--' }}</td>
                    <td>
                      <ng-container [ngTemplateOutlet]="tableActions" [ngTemplateOutletContext]="{
                                modalName: externalInfoModal,
                                entity: 'externalRef',
                                fieldName: 'externalRefs',
                                filterName: 'index',
                                id: i
                              }"></ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div role="alert" class="alert alert-warning mt-4 mr-2 ml-2" *ngIf="!venue.externalRefs?.length">
                No external info added
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="navMain" class="mt-2 w-100 p-2"></div>
      </div>
    </div>
  </div>
  <div class="panel-footer mb-2 d-flex justify-content-between p-1">
    <button class="btn btn-att ml-2" (click)="cancel()">
      Cancel
    </button>
    <div class="ml-auto">
      <button class="btn btn-att mr-2" (click)="saveVenue()">
        Quick Save
      </button>
    </div>
  </div>
  <!-- Common table actions template -->
  <ng-template #tableActions let-modalName="modalName" let-entity="entity" let-fieldName="fieldName"
    let-filterName="filterName" let-id="id">
    <span class="cursor-pointer" (click)="openModal(modalName, entity, fieldName, filterName, id)">
      <i class="fas fa-edit"></i>
    </span>
    <span class="cursor-pointer" (click)="openDeleteModal(fieldName, filterName, id)">
      <i class="far fa-window-close ml-2"></i>
    </span>
  </ng-template>
  <!-- External Info modal -->
  <ng-template #externalInfoModal id="externalInfoModal">
    <ng-container [ngTemplateOutlet]="modalHeader" [ngTemplateOutletContext]="{ headerTitle: 'Add External Info' }">
    </ng-container>
    <div class="modal-body">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>System</label>
            <select class="form-control" [(ngModel)]="externalRef.system" (change)="onSystemChange($event)">
              <option value= '' disabled selected hidden>
                Select system
              </option>
              <option *ngFor="let system of systems" [value]="system.system">
                {{ system.system }}
              </option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Name</label>
            <select class="form-control" [(ngModel)]="externalRef.refName">
              <option value= '' disabled selected hidden>
                Select reference name
              </option>
              <option *ngFor="let refName of refNames" [value]="refName">
                {{ refName }}
              </option>
            </select>
            <br />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Value</label>
            <input type="text" name="name" class="form-control" [(ngModel)]="externalRef.id" />
            <br />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-att mr-2" (click)="addItem('externalRefs', externalRef)" [disabled]="
            externalRef.system != '' &&
            externalRef.value != '' 
              ? false
              : true">
        Add
      </button>
    </div>
  </ng-template>
  <!-- Common header modal template -->
  <ng-template #modalHeader id="modalHeader" let-header="headerTitle">
    <div class="modal-header">
      <h4 class="modal-title">{{ header }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </ng-template>
</div>