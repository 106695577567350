import { Component, OnInit, ViewChild } from '@angular/core'
import { NgbModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { UserService } from '../users/users.service'
import { AppService } from '../../app.service'
import { UrlSegment } from '@angular/router'
import { ProgramsService } from '../programs/programs.service'
import { ChannelManagementService } from './channel-management.service'

@Component({
  selector: 'app-channels',
  templateUrl: './channel-management.component.html',
  styleUrls: ['./channel-management.component.scss'],
})
export class ChannelManagementComponent implements OnInit {
  serviceChannel: SourceChannel
  oldServiceChannel: string
  resourceTypes: ResourceType[]
  refreshSourceId: string
  refresh: boolean
  displayTab

  constructor(
    private channelService: ChannelManagementService,
    private userService: UserService,
    private modalService: NgbModal,
    private appService: AppService,
  ) { }

  ngOnInit() { }

  onChannelChange(channel: SourceChannel) {
    // retrieve service channel information in BE

    this.channelService.getChannelDetails(channel.id).subscribe((data: any) => {
        channel = data.response;
        channel.language = channel?.channelNames[0].language || '';
        channel.callSign = this.channelService.findNameDescription(channel.channelNames, "short");
        channel.name = this.channelService.findNameDescription(channel.channelNames, "full");
        channel.shortDescription = this.channelService.findNameDescription(channel.descriptions, "short");
        channel.fullDescription = this.channelService.findNameDescription(channel.descriptions, "full");
        channel.region = !channel.regions ? '' : channel.regions.map(({ regionName }) =>
          regionName != undefined ? regionName : '').join(',');
        channel.processingType = (channel.processingType || '');
        channel.mappings = channel.mappings === undefined ? [] : channel.mappings,
        channel.category = !channel.categories ? '' : channel.categories.map(({ name }) => name).join(',');

        if (channel.urls !== null && channel.urls !== undefined) {
          channel.urls.forEach(item => {
            let drmsName = item.drms[0].drm ? item.drms[0].drm : '';
            item.drmsName = drmsName;
          });
        }

        let prgSvcId = "0"
        if (channel.dayParts?.length > 0 && channel.dayParts[0]?.mappings) {
          prgSvcId = channel.dayParts[0]?.mappings[0]?.prgSvcId
        }

        channel.prgSvcId = prgSvcId;
        this.channelService.getChannelImage(channel.id, prgSvcId).subscribe(imgData => {
          const imageDetail = imgData.response;
          if (imageDetail.uri) {
            channel.channelImage = `${imageDetail.baseUrl}${imageDetail.uri}`;
          } else {
            channel.channelImage = 'assets/images/noImage.jpg';
          }
        });

        // init masterChannel for channel
        channel.masterChannelName = {
          id: null,
          name: null,
          sourceId: null
        }

        if (!channel.dthInfo) {
          channel.dthInfo = this.channelService.dthInfoDefault()
        } else {
          if (channel.dthInfo.isSlave == true) {
            this.channelService.getMasterChannelOfSlave(channel.dthInfo?.masterSource).subscribe(data => {
              channel.oldDayPart = channel.dayParts
              channel.dayParts = data.response.dayParts
              channel.masterChannelName = {
                id: data.response?.id,
                name: data.response?.sourceId + ' - ' + data.response?.channelNames[0].value,
                sourceId: data.response?.sourceId
              }
            })
          }
        }
        this.serviceChannel = channel
        this.oldServiceChannel = JSON.stringify(this.serviceChannel);
      });
  }

  onRefreshChannel(sourceId) {
    this.refreshSourceId = sourceId
    this.refresh = !this.refresh
  }
}
