<div class="assets-panel">
    <div class="panel-header assets-header">
      <div class="d-flex justify-content-start">
        <h6>{{ 'vls.VOD-assets.pageTitle' | translate }}</h6>
        <div class="ml-auto d-flex">
          <div class="asset-filters" *ngFor="let filter of assetFilters">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="{{ filter.name }}"
                [checked]="filter.value"
                (change)="filter.value = !filter.value; getParams.page = 1; getAssets()"
              />
              <label class="form-check-label" for="{{ filter.name }}">{{
                filter.abbrev
              }}</label>
            </div>
          </div>
        </div>
        <div
        class="availability-filter mt-1"
        ngbDropdown
        placement="bottom-right"
        display="dynamic"
      >
        <button class="btn btn-default btn-sm" ngbDropdownToggle>
          <i class="fa fa-filter text-white"></i>
        </button>
        <div class="dropdown-menu" ngbDropdownMenu>
          <div class="mx-2" *ngFor="let addFilter of additionalFilters">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="{{ addFilter.name }}"
                [checked]="addFilter.value"
                (change)="addFilter.value = !addFilter.value; getParams.page = 1; getAssets()"
              />
              <label class="form-check-label" for="{{ addFilter.name }}">
                {{ addFilter.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div style="display: block; justify-content: flex-start;" >
        <select class="filter-option" 
        (change)="changeAdditionalFilterOption($event.target.value); 
        getParams.page = 1;
        categoryFilter = [];
        channelFilter = [];
        getAssets()">
          <option [value] = "'service'">
            Service Category
          </option>
          <option [value] = "'channel'">
            Channel
          </option>
        </select>
        <select 
        class="filter-value" 
        (change) = "selectedServiceOrChannel($event.target.value); getParams.page = 1; getAssets()" 
        *ngIf = "channelOrCategory === 'service'">
          <option 
          *ngFor="let item of serviceCategoryList" [value] = "item.id" >
          {{item.name}}
          </option>
        </select>
        <select 
        class="filter-value" 
        (change) = "selectedServiceOrChannel($event.target.value); getParams.page = 1; getAssets()" 
        *ngIf = "channelOrCategory === 'channel'">
          <option 
          *ngFor="let item of channelsList" 
          [value] = "item.id" >
          {{item.name}}
          </option>
        </select>
      </div>
      <div class="assets-search input-group mt-1">
        <input
          type="search"
          class="form-control"
          placeholder="Search movies, shows or episodes"
          [(ngModel)]="searchString"
          (ngModelChange)="searchChange($event)"
          (keyup)="searchKeyup($event)"
        />
        <div class="input-group-append">
          <span class="input-group-text" (click)="searchAssets()">
            <i class="fa fa-search"></i>
          </span>
        </div>
      </div>
    </div>
    <div
      class="panel-body assets-wrapper"
      id="assetList"
      (scroll)="onScroll($event)"
    >
      <ng-container *ngIf="showLoader">
        <div class="loading">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </ng-container>
      <div
        *ngFor="let asset of assets; let i = index"
        class="d-flex justify-content-between p-1 flex-column"
      >
        <div
          id="assetItem-{{ i }}"
          class="assets-item d-block"
          (click)="viewAsset(asset.assetId)"
          [class.active]="asset.assetId === selectedAssetId"
        >
          <div class="asset-name d-flex">
            <strong class="text-truncate">
              <span *ngIf="asset.title">
                {{ asset.title }}</span
              >
            </strong>
            <div class="ml-auto" style="flex: none">
              <span *ngIf="asset.displaySeasonNumber" class="badge badge-light mr-1">
                sN: {{ asset.displaySeasonNumber }}
              </span>
              <span *ngIf="asset.displayEpisodeNumber" class="badge badge-light mr-2">
                eN: {{ asset.displayEpisodeNumber }}
              </span>
              <span [className]="'badge badge-' + getAssetTypeStyle(asset.published)">
                {{getProgramType(asset.programId)}}
              </span>
            </div>
          </div>
          <div class="asset-name d-flex" style="align-items: center">
            <div class="text-muted text-truncate mr-3">
              {{ asset.programId || 'No description' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-footer mb-2 d-flex">
      <div class="footer-count">
        <label> 
          Showing 
          <strong>
            {{assets.length}}
          </strong> 
          of 
          <strong>
            {{ totalAssets }}
          </strong></label>
    </div>
    </div>
</div>