<div class="team-form" id="teamForm">
  <div class="panel-header d-flex">
    <div class="d-flex justify-content-start">
      <h6 *ngIf="!isSky">
        {{ formatHeaderTitle('englishOfficialName', 'spanishOfficialName') || 'New Team' }}
      </h6>
      <h6 *ngIf="isSky">
        {{ formatHeaderTitle('englishOfficialName', 'portugueseOfficialName') || 'New Team' }}
      </h6>
    </div>
  </div>
  <div class="panel-body" style="overflow-x: visible;" id="teamForm">
    <div class="container-fluid">
      <ng-container *ngIf="isLoading">
        <div class="loading">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </ng-container>
      <div class="mt-2 team-info-view">
        <div class="col ml-2">
          <div class="row">
            <div class="col form-group">
              <label>Team ID</label>
              <input disabled id="teanId" type="text" class="form-control" [(ngModel)]="team.id" />
            </div>
            <div class="col mr-1">
              <div class="form-group">
                <label for="optaTeamId">Opta Team Id</label>
                <input disabled id="optaTeamId" type="text" class="form-control" [(ngModel)]="team.optaTeamId" />
              </div>
            </div>
          </div>
        </div>
        <div class="col ml-2">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label for="founded">Founded</label>
                <input id="founded" type="text" class="form-control" [(ngModel)]="team.founded" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="abbreviation">Abbreviation</label>
                <input id="abbreviation" type="text" class="form-control" [(ngModel)]="team.abbreviation" />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="zipCode">Zip Code</label>
                <input id="zipCode" type="text" class="form-control" [(ngModel)]="team.addressZip" />
              </div>
            </div>
            <div class="col mr-1">
              <div class="form-group">
                <label for="type">Type</label>
                <input id="type" type="text" class="form-control" [(ngModel)]="team.type" />
              </div>
            </div>
          </div>
        </div>
        <div class="col ml-2">
          <div class="row">
            <!-- <div class="col">
              <label>Genres</label>
              <ng-select [items]="genres" [multiple]="true" [hideSelected]="true" [closeOnSelect]="true"
                [searchable]="true" [(ngModel)]="team.genres" (change)="genreOnChange()" bindLabel="name"
                name="genreNames">
                <ng-template ng-option-tmp let-item="item">
                  <div container="body" ngbTooltip="{{ item.id }}">
                    {{ item.name }}
                  </div>
                </ng-template>
              </ng-select>
            </div> -->
            <div class="col mr-1">
              <label>Venue</label>
              <ng-select [items]="venues" [multiple]="false" [hideSelected]="true" [closeOnSelect]="true"
                [searchable]="true" [(ngModel)]="team.venueId" (change)="venueOnChange($event)" bindLabel="name"
                name="venueName">
                <ng-template ng-option-tmp let-item="item">
                  <div container="body" ngbTooltip="{{ item.id }}">
                    {{ item.name }}
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
        <div class="col ml-2 mt-2">
          <div class="row">
            <div class="col form-group">
              <label for="englishOfficialName">English Official Name</label>
              <textarea id="englishOfficialName" type="text" class="form-control"
                [(ngModel)]="team.englishOfficialName" (keyup)="onFieldChange($event, 'Name', true)"></textarea>
            </div>
            <div class="col" *ngIf="!isSky">
              <div class="form-group">
                <label for="spanishOfficialName">Spanish Official Name</label>
                <textarea id="spanishOfficialName" type="text" class="form-control"
                  [(ngModel)]="team.spanishOfficialName" (keyup)="onFieldChange($event, 'Name', true)"></textarea>
              </div>
            </div>
            <div class="col" *ngIf="isSky">
              <div class="form-group">
                <label for="portugueseOfficialName">Portuguese Official Name</label>
                <textarea id="portugueseOfficialName" type="text" class="form-control"
                  [(ngModel)]="team.portugueseOfficialName" (keyup)="onFieldChange($event, 'Name', true)"></textarea>
              </div>
            </div>
            <div class="col form-group">
              <label for="englishVenueName">English Venue Name</label>
              <textarea id="englishVenueName" type="text" class="form-control" 
                [(ngModel)]="venue.englishVenueName" [disabled] ="true"></textarea>
            </div>
            <div class="col mr-1" *ngIf="!isSky">
              <div class="form-group">
                <label for="spanishVenueName">Spanish Venue Name</label>
                <textarea id="spanishVenueName" type="text" class="form-control"
                  [(ngModel)]="venue.spanishVenueName" [disabled] ="true"></textarea>
              </div>
            </div>
            <div class="col" *ngIf="isSky">
              <div class="form-group">
                <label for="portugueseVenueName">Portuguese Venue Name</label>
                <textarea id="portugueseVenueName" type="text" class="form-control"
                  [(ngModel)]="team.portugueseVenueName" (keyup)="onFieldChange($event, 'Name', true)" [disabled] ="true"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3 pl-3">
          <ul ngbNav #navMain="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs">
            <li [ngbNavItem]="1">
              <a ngbNavLink>Images</a>
              <ng-template ngbNavContent>
                <div class="mt-2 team-pictures-list" *ngIf="
                        team.images?.length
                      ">
                  <div class="
                          row
                          no-gutters
                          row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5
                        ">
                  </div>
                </div>
                <ul ngbNav #navImages="ngbNav" [(activeId)]="navImage" class="nav-pills p-2 mt-1">
                  <li class="pr-5" [ngbNavItem]="i + 1" *ngFor="let size of team.imageSizes; let i = index">
                    <a ngbNavLink>{{ size }}</a>
                    <ng-template ngbNavContent>
                      <div role="alert" class="alert alert-warning mt-4" *ngIf="!team.teamImages[size]?.length">
                        No {{ size }} images added
                      </div>
                      <div class="mt-4 team-pictures-list" *ngIf="team.teamImages[size]?.length">
                        <div class="
                                row
                                no-gutters
                                row-cols-sm-2
                                row-cols-md-3
                                row-cols-lg-4
                                row-cols-xl-5
                              ">
                          <div *ngFor="
                                  let image of team.teamImages[size];
                                  let i = index
                                " class="col pb-2">
                            <ng-container [ngTemplateOutlet]="teamImageCard" [ngTemplateOutletContext]="{
                                    image: image,
                                    i: i,
                                    published: false
                                  }"></ng-container>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </li>
                </ul>
                <ng-template #teamImageCard let-image="image" let-i="i" let-published="published">
                  <div class="card team-image mb-2">
                    <div class="card-header d-flex justify-content-start">
                      <input *ngIf="!published" [checked]="image.published" (click)="publishedImage(image)" type="radio"
                        class="img-radio-btn cursor-pointer" name="image-selected" ngbTooltip="Click to Publish" />
                      <span (click)="deleteImage(image, i)" *ngIf="image.imageFile && !published"  class="
                              pub-span
                              badge badge-warning
                              delete-image
                              cursor-pointer
                            ">X</span>
                      <span *ngIf="image.published" class="pub-span badge badge-success">PUB </span>
                      <span container="body" class="badge badge-warning pub-span" *ngIf="!image.published">
                        UNPUB
                      </span>
                    </div>
                    <div class="card-body">
                      <img class="card-img-top cast-crew-img" [src]="
                              image.uri
                                ? image.baseUrl + image.uri
                                : image.imageURL || previewUrl
                            " style="object-fit: contain; width: 100%" (error)="onImageError($event)" />
                    </div>
                    <div class="card-footer">
                      <p style="
                              margin-bottom: 0px;
                              text-align: left;
                              display: flex;
                              flex-direction: column;
                            " class="ml-auto">
                        <span>
                          <a ngbTooltip="{{ image.id }}" target="_blank" href="{{
                                  image.uri ? image.baseUrl + image.uri : previewUrl
                                }}">
                            Category: {{ image.category }}
                          </a>
                        </span>
                        <span class="font-weight-bold">
                          Ratio: {{ image.ratio }}
                        </span>
                        <span class="font-weight-bold">
                          Source: {{image.source}}
                        </span>
                        <span class="font-weight-bold"
                          *ngIf="image.expiredDate !== undefined && image.expiredDate !== null">Expired date: {{
                          image.expiredDate | date: "yyyy-MM-dd" }}
                        </span>
                      </p>
                    </div>
                  </div>
                </ng-template>
                <div class="d-flex justify-content-between mt-4 pl-2 pr-2">
                  <div class="btn-group d-flex w-100">
                    <div class="btn-group" ngbDropdown>
                      <button class="btn btn-att btn-upload" ngbDropdownToggle style="font-size: 14px">
                        {{ selectedImageCategory }}
                      </button>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <button class="custom-dropdown-item" *ngFor="let category of imageCategories"
                          (click)="setImageCategory(category)" ngbDropdownItem>
                          {{ category }}
                        </button>
                      </div>
                    </div>
                    <div class="custom-file team-image">
                      <input id="imageUpload" type="file" accept="image/png, image/jpeg"
                        (change)="onFileSelect($event)" />
                      <label container="body" ngbTooltip="Click to select image" id="inputLabel"
                        class="custom-file-label form-control" for="imageUpload">
                      </label>
                    </div>
                  </div>
                  <button class="btn btn-att btn-upload" [disabled]="!image.imageFile"
                    (click)="addItem('images', image)">
                    Add
                  </button>
                </div>
                <div [ngbNavOutlet]="navImages" class="pl-2 pr-2"></div>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>External Info</a>
              <ng-template ngbNavContent>
                <div class="d-flex justify-content-end mb-1 ml-2 mr-2">
                  <button class="btn btn-att btn-sm" (click)="openModal(externalInfoModal)">
                    Add External Info
                  </button>
                </div>
                <table class="table table-striped ml-2 mr-2" *ngIf="team.externalRefs?.length">
                  <thead>
                    <tr>
                      <th>System</th>
                      <th>Ref Name</th>
                      <th>Value</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="cursor-pointer" *ngFor="let externalRef of team.externalRefs; let i = index">
                      <td>{{ externalRef.system || '--' }}</td>
                      <td>{{ externalRef.refName || '--' }}</td>
                      <td>{{ externalRef.id || '--' }}</td>
                      <td>
                        <ng-container [ngTemplateOutlet]="tableActions" [ngTemplateOutletContext]="{
                                  modalName: externalInfoModal,
                                  entity: 'externalRef',
                                  fieldName: 'externalRefs',
                                  filterName: 'index',
                                  id: i
                                }"></ng-container>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div role="alert" class="alert alert-warning mt-4 mr-2 ml-2" *ngIf="!team.externalRefs?.length">
                  No external info added
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="navMain" class="mt-2 w-100 p-2"></div>
        </div>
      </div>
    </div>
    <div class="panel-footer mb-2 d-flex justify-content-between p-1">
      <button class="btn btn-att ml-2" (click)="resetData()">
        Cancel
      </button>
      <div class="ml-auto">
        <button class="btn btn-att mr-2" (click)="save()">
          Quick Save
        </button>
      </div>
    </div>
      <!-- Common header modal template -->
  <ng-template #modalHeader id="modalHeader" let-header="headerTitle">
    <div class="modal-header">
      <h4 class="modal-title">{{ header }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </ng-template>

    <!-- Common table actions template -->
    <ng-template #tableActions let-modalName="modalName" let-entity="entity" let-fieldName="fieldName"
      let-filterName="filterName" let-id="id">
      <span class="cursor-pointer" (click)="openModal(modalName, entity, fieldName, filterName, id)">
        <i class="fas fa-edit"></i>
      </span>
      <span class="cursor-pointer" (click)="openDeleteModal(fieldName, filterName, id)">
        <i class="far fa-window-close ml-2"></i>
      </span>
    </ng-template>

  <!-- External Info modal -->
  <ng-template #externalInfoModal id="externalInfoModal">
    <ng-container [ngTemplateOutlet]="modalHeader" [ngTemplateOutletContext]="{ headerTitle: 'Add External Info' }">
    </ng-container>
    <div class="modal-body">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>System</label>
            <select class="form-control" [(ngModel)]="externalRef.system" (change)="onSystemChange($event)">
              <option value="" disabled selected hidden>Select system</option>
              <option *ngFor="let system of systems" [value]="system.system">
                {{ system.system }}
              </option>
            </select>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <label>Name</label>
            <select class="form-control" [(ngModel)]="externalRef.refName">
              <option value="" disabled selected hidden>Select reference name</option>
              <option *ngFor="let refName of refNames" [value]="refName">
                {{ refName }}
              </option>
            </select>
            <br />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Value</label>
            <input type="text" name="name" class="form-control" [(ngModel)]="externalRef.id" />
            <br />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-att mr-2" (click)="addItem('externalRefs', externalRef)" [disabled]="
          externalRef.system != '' &&
          externalRef.value != '' 
            ? false
            : true">
        Add
      </button>
    </div>
  </ng-template>
  </div>