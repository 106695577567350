<div class="channel-panel">
  <div class="panel-header header">
    <div class="d-flex justify-content-start">
      <h6>{{ componentName }}</h6>
      <div class="ml-auto d-flex mb-1">
        <ng-container *ngIf="targetComponent === 'service' && !isDthPlatform()">
          <div class="filters" *ngFor="let filter of channelFilters">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="{{ filter.name }}" [checked]="filter.value"
                (change)="filter.value = !filter.value; getChannels()" />
              <label class="form-check-label" for="{{ filter.name }}">
                {{ filter.name }}
              </label>
            </div>
          </div>
        </ng-container>
        <div
          class="availability-filter mt-1"
          ngbDropdown
          placement="bottom-right"
          display="dynamic"
          *ngIf="targetComponent !== 'schedules'"
        >
          <button class="btn btn-default btn-sm" ngbDropdownToggle>
            <i class="fa fa-filter text-white"></i>
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <div class="mx-2" *ngFor="let filter of additionalFilters">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="filter1" [checked]="filter.value"
                  (change)="filter.value = !filter.value; getChannels()" />
                <label class="form-check-label" for="filter1">
                  {{ filter.name }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="search input-group">
      <input type="search" class="form-control" placeholder="Search channels" [(ngModel)]="searchString"
        (ngModelChange)="searchChange($event)" (keyup)="searchKeyUp($event)" />
      <div class="input-group-append">
        <span class="input-group-text" (click)="getChannels()">
          <i class="fa fa-search"></i>
        </span>
      </div>
    </div>
  </div>
  <ng-container *ngIf="targetComponent === 'service' || targetComponent === 'schedules'">
    <div class="panel-body channel-wrapper">
      <div id="sourceChannelList" class="channel-item mt-1 d-flex row" [class.active]="channel?.id == selectedChannel?.id"
        *ngFor="let channel of channels">
        <div class="col-2 channel-logo" (click)="channelClick($event, channel)">
          <svg class="att-logo mt-1" aria-label="VLS home">
            <path
              d="M24.184 2.256A15.818 15.818 0 0 0 16 0c-3.023 0-5.89.847-8.298 2.323-.72.445-1.128.8-1.128 1.258 0 1.35 3.154 2.8 8.747 2.8 5.534 0 9.828-1.589 9.828-3.119 0-.365-.319-.62-.965-1.006m2.382 1.778c.067.104.1.218.1.369 0 1.777-5.438 4.922-14.096 4.922-6.36 0-7.551-2.36-7.551-3.862 0-.537.205-1.086.658-1.643.025-.034.004-.046-.025-.021-.826.7-1.585 1.488-2.252 2.34-.318.402-.516.758-.516.972 0 3.111 7.8 5.367 15.09 5.367 7.77 0 11.238-2.536 11.238-4.767 0-.797-.31-1.262-1.103-2.164a18.898 18.898 0 0 0-1.518-1.535c-.025-.02-.042-.004-.025.022M1.543 9.136c-.47.931-.99 2.504-1.145 3.317-.067.348-.038.515.084.775.981 2.084 5.946 5.418 17.526 5.418 7.065 0 12.554-1.736 13.443-4.906.163-.583.171-1.199-.038-2.03-.235-.926-.675-2.008-1.048-2.767-.013-.025-.034-.02-.03.009.139 4.155-11.446 6.834-17.291 6.834-6.331 0-11.614-2.524-11.614-5.71 0-.306.063-.613.142-.931.009-.03-.016-.034-.03-.009m11.242 13.28c-8.189 0-12.05-3.816-12.75-6.42C.025 15.958 0 15.966 0 16c0 .876.088 2.008.239 2.759.071.365.369.94.805 1.396 1.983 2.067 6.927 4.965 15.488 4.965 11.665 0 14.332-3.887 14.877-5.166.39-.914.591-2.566.591-3.954 0-.336-.008-.604-.021-.868 0-.042-.025-.046-.034-.004-.583 3.128-10.549 7.287-19.161 7.287m4.302 7.04c3.937 0 8.264-1.073 10.85-3.2.71-.578 1.385-1.35 1.988-2.385.348-.596.688-1.304.965-2 .013-.033-.009-.05-.034-.013-2.407 3.544-9.375 5.753-16.57 5.753-5.086 0-10.558-1.627-12.7-4.734-.021-.03-.042-.017-.03.017 1.996 4.243 8.05 6.562 15.531 6.562m-10.898-.818A15.983 15.983 0 0 0 16 32c4.042 0 7.727-1.501 10.54-3.966.034-.03.018-.05-.016-.03-1.262.843-4.86 2.684-10.524 2.684-4.922 0-8.034-1.1-9.79-2.08-.034-.017-.046.008-.022.03"
              fill="#009FDB" fill-rule="evenodd"></path>
          </svg>
        </div>
        <div class="channel-info" (click)="channelClick($event, channel)" [class] ="isChannel? 'col-10':'col-9'">
          <div class="row">
            <div class="col ml-1 d-flex channel-name">
              <strong class="mt-1" container="body" ngbTooltip="{{ channel.channelNameFull }}">
                {{ channel.channelName?.toUpperCase() || channel.channelNameFull}}
              </strong>
              <div class="switcher ml-auto">
                <div
                  *ngIf="targetComponent !== 'schedules' && channel.channelTarget.toLowerCase() === CONSTANTS.DTH && channel?.dthInfo?.channelResolution"
                  class="badge badge-info mr-1"
                >
                  {{ channel.dthInfo.channelResolution }}
                </div>
                <div
                  *ngIf="targetComponent !== 'schedules'"
                  class="badge badge-info mr-1"
                >
                  {{ channel.channelType }}
                </div>
                <div *ngIf="
                    targetComponent === 'schedules' &&
                    channel.channelType === 'HYBRID'
                  " class="badge badge-info mr-1">
                  HYBRID
                </div>
                <ng-container>
                  <div class="switcher" style="height: 15px;" class="badge badge-info mr-1" *ngIf="channel.dthInfo.isSlave === false">MASTER</div>
                  <div class="switcher" style="height: 15px;" class="badge badge-info mr-1" *ngIf="channel.dthInfo.isSlave === true">SLAVE</div>
                </ng-container>
              </div>
              
            </div>
          </div>
          <div class="row">
            <div class="col ml-1 station-id">
              <!-- <span class="text-muted" *ngIf="
                  channel.masterSource === undefined &&
                  channel.sourceId !== undefined
                ">
                SID: {{ channel.sourceId }}
              </span> -->
              <span class="text-muted">
                SID: {{ channel.sourceId }} {{channel.gracenoteId ? '| GN:' : ''}} {{channel.gracenoteId}}
              </span>
            </div>
            <div style="margin-right: 5px;" *ngIf="isChannel && channel.channelTarget.toLowerCase() === CONSTANTS.DTH && channel.dthInfo.isSlave !== true">
              <i container="body" ngbTooltip="Link Slave"
                class="ml-3 fa fa-plus-circle" (click)="openMasterSlaveModal(channel)">
              </i>
            </div>
          </div>
        </div>
        <div class="col-1 channel-info">
          <div class="row">
            <div class="col ml-1 d-flex channel-name">
              <div class="switcher ml-auto">
                <label container="body" [ngbTooltip]="channel.processingType.toUpperCase()"
                  *ngIf="targetComponent === 'schedules'">
                  <input #inputEl z-index="999999" type="checkbox" id="{{ channel.id }}"
                    [checked]="channel.processingType.toUpperCase() === 'AUTO'"
                    (change)="autoPublishOnChange(inputEl.checked, channel)" />
                  <span><small></small></span>
                </label>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="targetComponent === 'provider'">
    <div id="providerList" class="panel-body channel-wrapper" cdkDropList cdkDropListConnectedTo="mappingList">
      <div *ngFor="let provider of channels; let i = index" cdkDrag [cdkDragDisabled]="!!provider?.isMapped"
        [cdkDragData]="provider" (cdkDragStarted)="onDragStart($event, i)">
        <div id="providerItem-{{ i }}" class="provider-item mt-1 d-flex row">
          <div class="col-2 channel-logo">
            <svg class="att-logo mt-1" aria-label="VLS home">
              <path
                d="M24.184 2.256A15.818 15.818 0 0 0 16 0c-3.023 0-5.89.847-8.298 2.323-.72.445-1.128.8-1.128 1.258 0 1.35 3.154 2.8 8.747 2.8 5.534 0 9.828-1.589 9.828-3.119 0-.365-.319-.62-.965-1.006m2.382 1.778c.067.104.1.218.1.369 0 1.777-5.438 4.922-14.096 4.922-6.36 0-7.551-2.36-7.551-3.862 0-.537.205-1.086.658-1.643.025-.034.004-.046-.025-.021-.826.7-1.585 1.488-2.252 2.34-.318.402-.516.758-.516.972 0 3.111 7.8 5.367 15.09 5.367 7.77 0 11.238-2.536 11.238-4.767 0-.797-.31-1.262-1.103-2.164a18.898 18.898 0 0 0-1.518-1.535c-.025-.02-.042-.004-.025.022M1.543 9.136c-.47.931-.99 2.504-1.145 3.317-.067.348-.038.515.084.775.981 2.084 5.946 5.418 17.526 5.418 7.065 0 12.554-1.736 13.443-4.906.163-.583.171-1.199-.038-2.03-.235-.926-.675-2.008-1.048-2.767-.013-.025-.034-.02-.03.009.139 4.155-11.446 6.834-17.291 6.834-6.331 0-11.614-2.524-11.614-5.71 0-.306.063-.613.142-.931.009-.03-.016-.034-.03-.009m11.242 13.28c-8.189 0-12.05-3.816-12.75-6.42C.025 15.958 0 15.966 0 16c0 .876.088 2.008.239 2.759.071.365.369.94.805 1.396 1.983 2.067 6.927 4.965 15.488 4.965 11.665 0 14.332-3.887 14.877-5.166.39-.914.591-2.566.591-3.954 0-.336-.008-.604-.021-.868 0-.042-.025-.046-.034-.004-.583 3.128-10.549 7.287-19.161 7.287m4.302 7.04c3.937 0 8.264-1.073 10.85-3.2.71-.578 1.385-1.35 1.988-2.385.348-.596.688-1.304.965-2 .013-.033-.009-.05-.034-.013-2.407 3.544-9.375 5.753-16.57 5.753-5.086 0-10.558-1.627-12.7-4.734-.021-.03-.042-.017-.03.017 1.996 4.243 8.05 6.562 15.531 6.562m-10.898-.818A15.983 15.983 0 0 0 16 32c4.042 0 7.727-1.501 10.54-3.966.034-.03.018-.05-.016-.03-1.262.843-4.86 2.684-10.524 2.684-4.922 0-8.034-1.1-9.79-2.08-.034-.017-.046.008-.022.03"
                fill="#009FDB" fill-rule="evenodd"></path>
            </svg>
          </div>
          <div class="col-10 provider-info">
            <div class="row">
              <div class="col ml-1 d-flex provider-name">
                <strong class="mt-1" container="body" ngbTooltip="{{ provider.name }}">
                  {{ provider.name | uppercase }}
                </strong>
                <label class="mt-1 ml-1 mb-0">
                  (PrgSvc Id: {{ provider.channelId }})
                </label>
                <div class="switcher ml-auto">
                  <div class="badge badge-info mr-1" *ngIf="provider.platform === 'Gracenote'">
                    Gracenote
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col ml-1 provider-id">
                <div class="float-left">
                  <div class="badge badge-secondary mr-1" *ngIf="provider.sid !== undefined">
                    SID: {{ provider?.sourceId }}
                  </div>
                </div>
                <div class="float-right">
                  <div class="badge badge-info mr-1" *ngIf="provider.platform == 'OTT'">
                    OTT
                  </div>
                  <div class="badge badge-info mr-1" *ngIf="provider.platform == 'DTH'">
                    DTH
                  </div>
                  <div class="badge badge-danger mr-1" *ngIf="provider?.isMapped">
                    Mapped
                  </div>
                  <div class="badge badge-success mr-1">
                    {{ provider?.broadcast }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="targetComponent === 'transmit'">
    <div class="panel-body channel-wrapper">
      <div id="channelList" class="channel-item mt-1 d-flex row channel-small" *ngFor="let channel of channels"
        (click)="channel.checked = !channel.checked">
        <div class="badge badge-info channel-name my-auto ml-1" ngbTooltip="{{ channel.name }}">
          {{ channel.name }}
        </div>
        <div class="switcher ml-auto my-1 mr-1">
          <span class="align-middle ml-2" (click)="$event.stopPropagation()">(SID: {{ channel.serviceId }})</span>
          <div class="
              badge badge-success-outlined
              channel-platform
              my-auto
              ml-2
              rounded-3
            ">
            {{ channel.channelTarget?.toUpperCase() }}
          </div>
          <label class="ml-2 mb-0" for="{{ channel.id }}">
            <input type="checkbox" id="{{ channel.id }}" [checked]="channel.checked" />
            <span><small></small></span>
          </label>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="panel-footer mb-2">
    <div *ngIf="targetComponent === 'transmit'" class="float-right">
      <button class="btn btn-sm btn-outline-dark mr-3 mt-2" (click)="onMappingSubmit()" [disabled]="disableButton">
        Transmit
      </button>
    </div>
    <div class="footer-count">
      <label *ngIf="targetComponent === 'service'"> {{ channels?.length }} Total Service Channels </label>
      <label *ngIf="targetComponent === 'provider'"> {{ channels?.length }} Total Provider Channels </label>
    </div>
  </div>
</div>
<ng-template #masterSourceSelection>
  <div class="modal-header">
    <h4 class="modal-title">Master Source Selection</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label>Platform</label>
          <ng-select class="dropdown-channel" [items]="channelFilters" bindLabel="name" bindValue="abbrev"
            placeholder="Select Platform" (clear)="clearFilter()" (change)="onMasterMappingFilterChange($event)">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label>Service Channel</label>
          <ng-select class="dropdown-channel" [items]="masterSourceChannels" bindLabel="name" bindValue="id"
            placeholder="Select Service Channel" [(ngModel)]="selectedMasterSourceId">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.id }}">
                {{ item.name }} {{ item.channelType }} (SID:
                {{ item.sourceId }})
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button [disabled]="showLoader" class="btn btn-dark" (click)="mappingMasterSource()">
      <i *ngIf="showLoader" class="fas fa-spinner fa-spin"></i> Submit
    </button>
  </div>
</ng-template>