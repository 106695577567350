import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor(
  ) { }

  verifyPlatform(platform : String){
    if(environment.hasOwnProperty(AppConstants.PLATFORM) && environment[AppConstants.PLATFORM].toLowerCase() ===  platform.toLowerCase()){
      return true 
    }
    else{
      return false
    }
  }
}
