<div class="players-panel">
  <div class="panel-header players-header">
      <div class="d-flex justify-content-start">
      <h6>Players</h6>
    </div>
    <div class="players-search input-group mt-1">
      <input
        type="search"
        class="form-control"
        placeholder="Search players"
        [(ngModel)]="searchString"
        (ngModelChange)="searchChange($event, scrollPlayers)"
	      (keyup)="searchKeyup($event, scrollPlayers)"
      />
      <div class="input-group-append">
        <span class="input-group-text">
          <i class="fa fa-search" (click) = "searchPlayers()"></i>
        </span>
      </div>
    </div>
  </div>
  <div
    #scrollPlayers
    class="panel-body players-wrapper"
    cdkDropList
    id="playerList"
    (scroll)="onScroll($event)"
  >
    <ng-container *ngIf="showLoader">
      <div class="loading">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-container>
    <div
      *ngFor="let team of teams; let i = index"
      class="d-flex justify-content-between p-1 flex-column"
    >
      <div
        id="teamItem-{{ i }}"
        class="players-item d-block"
      >
        <div class="player-name d-flex">
          <strong class="text-truncate">
            {{ getTeamName(team.names) || CONSTANTS.NO_TEAM_PLAYER_NAME }}
          </strong>
        </div>
        <div class="player-name d-flex" style="align-items: center">
          <div *ngIf="team.abbreviation" class="text-muted text-truncate mr-1">
            Code: {{ team.abbreviation}}
          </div>
          <div *ngIf="!team.abbreviation" class="text-muted text-truncate mr-1">
            No Code
          </div>
          <div class="ml-auto mr-2">
            <button type="button" class="btn btn-link" style="padding: 0" (click)="showTeamPlayers(team)">
              <i class="fas fa-caret-down"></i>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="team.personDTOs">
        <div
            *ngFor="let player of team.personDTOs; let j = index"
            class="d-flex justify-content-between p-2 flex-column"
          >
            <div
              id="playerItem-{{ j }}"
              class="episode-series-item d-block"
              (click)="viewPlayer(player.id)"
              [class.active]="player.id === selectedPlayerId"
            >
              <div class="player-name d-flex">
                <strong class="text-truncate">
                  {{ getPlayerName(player.names) || CONSTANTS.NO_PLAYER_NAME }}
                </strong>
                <div class="ml-auto" style="flex: none">
                  <span
                    [className]="
                      'badge badge-' +getStatusLabel(player).style
                    "
                    >{{ getStatusLabel(player).code }}</span>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  <div class="panel-footer mb-2 d-flex justify-content-end">
    <div class="btn-group mr-2 p-1">
      <button class="btn btn-att py-0 px-2" (click)="createNewPlayer()">
        Create Player
      </button>
    </div>
  </div>
</div>