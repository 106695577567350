<div class="version-panel">
  <div class="panel-header">
    <div class="d-flex justify-content-start">
      <h6>{{ 'vls.versions.title' | translate }}</h6>
    </div>
  </div>
  <div class="panel-body version-wrapper">
    <ngb-accordion
      #acc="ngbAccordion"
      activeIds="{{ versionData.metadataVersionIDs }}"
    >
      <ng-container *ngFor="let metadata of versionData.metadataVersions">
        <ngb-panel id="{{ metadata.id }}">
          <ng-template ngbPanelHeader>
            <div class="header-panel d-flex justify-content-start">
              <button ngbPanelToggle class="btn">{{ metadata.name }}</button>
              <i
                (click)="lockContent()"
                class="pointer fa {{
                  versionData.contentLock ? 'fa-lock' : 'fa-unlock'
                }} ml-auto mr-3 text-white"
                *ngIf="
                (pageName !== 'channels' && metadata.id === 'masterentity') || (dthPlatform && pageName === 'programs')
                "
              >
              </i>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <div
              class="version-item d-flex"
              [class.active]="metadataVersion.id === selectedVersion"
              *ngFor="let metadataVersion of metadata.versions"
              (click)="onItemClick(metadataVersion.id, metadata.id)"
            >
              <div
                *ngIf="metadata.id !== 'masterentity'"
                container="body"
                ngbTooltip="Updated: {{metadataVersion?.updatedDate}}"
              >
                {{ metadataVersion.id }}
              </div>
              <div
                *ngIf="metadata.id === 'masterentity'"
                container="body"
                ngbTooltip="Updated: {{metadataVersion?.updatedDate}}"
              >
                Version {{ metadataVersion.version }}
              </div>
              <div class="version-status ml-auto d-flex active">
                <span
                  *ngIf="metadataVersion.provider"
                  class="ml-1 badge badge-info"
                >
                  {{ metadataVersion.provider === 'GRACENOTE' ? 'TMS' : metadataVersion.provider}}
                </span>
                <span
                  *ngIf="(metadataVersion.published && pageName !== 'schedules') ||
                  (metadataVersion.published && pageName === 'schedules' && metadataVersion?.scheduledPublishedVersion === metadataVersion.version) "
                  class="ml-1 badge badge-success"
                >
                  PUB
                </span>
                <span
                  *ngIf="metadataVersion.language && pageName !== 'schedules'"
                  class="ml-1 badge badge-success"
                >
                  {{ metadataVersion.language }}
                </span>
                <span
                  *ngIf="!metadataVersion.published && pageName === 'schedules' && metadataVersion.provider !== 'GRACENOTE'"
                  class="ml-1 badge badge-warning"
                >
                  {{metadataVersion.status === 'NEW' ? 'NEW' : 'SAVED'}}
                </span>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
      </ng-container>
    </ngb-accordion>
  </div>
  <div class="panel-footer mb-2 d-flex justify-content-end"></div>
</div>
