<div class="container-fluid bg-white pt-3 position-relative">
  <div class="d-flex justify-content-end">
    <div class="form-group form-inline">
      <div ngbDropdown class="d-inline-block ml-1" placement="bottom-right">
        <button
          class="btn btn-light btn-sm"
          id="dropdownFilterChannel"
          ngbDropdownToggle
        >
          Channel
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownFilterChannel">
          <div class="px-3 py-2">
            <input
              type="text"
              class="input-search w-100 mb-1"
              placeholder="Search..."
              [(ngModel)]="searchChannel"
              (ngModelChange)="searchChange('channels', $event, scrollChannels)"
              (keyup)="searchKeyup('channels', $event, scrollChannels)"
            />
            <div
              class="text-muted text-truncate text-right mb-2"
              style="font-size: 10px"
            >
              Results: {{channels.length - 1 > 0 ? channels.length - 1 : 0}}/{{totalElemChannels || 0}}
            </div>
            <div
              #scrollChannels
              class="scrollable-menu"
              (scroll)="onScroll('channels', $event)"
            >
              <div
                class="d-flex justify-content-start align-items-baseline mb-1"
                *ngFor="let channel of channels; let i = index"
              >
                <input
                  id="{{ channel.id }}"
                  class="form-check-input ml-2"
                  type="checkbox"
                  [checked]="channel.value"
                  (change)="
                    channel.value = !channel.value;
                    onFilterChange('channels', i)
                  "
                />
                <label
                  class="form-check-label text-filter"
                  for="{{ channel.name }}"
                  >{{ channel.name }}</label
                >
              </div>
            </div>
          </div>
          <div
            *ngIf="isLoading"
            class="d-flex justify-content-center align-items-center loading"
          >
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
      <div ngbDropdown class="d-inline-block ml-1" placement="bottom-right">
        <button
          class="btn btn-light btn-sm"
          id="dropdownFilterService"
          ngbDropdownToggle
        >
          Service Provider Category
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownFilterService">
          <div class="px-3 py-2">
            <input
              type="text"
              class="input-search w-100 mb-2"
              placeholder="Search.."
              [(ngModel)]="searchCategory"
              (ngModelChange)="searchChange('serviceCategories', $event, scrollCategories)"
              (keyup)="searchKeyup('serviceCategories', $event, scrollCategories)"
            />
            <div
              class="text-muted text-truncate text-right mb-2"
              style="font-size: 10px"
            >
            Results: {{serviceCategories.length - 1 > 0 ? serviceCategories.length - 1 : 0}}/{{totalElemCategories || 0}}
            </div>
            <div
              #scrollCategories
              class="scrollable-menu"
              (scroll)="onScroll('serviceCategories', $event)"
            >
              <div
                class="d-flex justify-content-start align-items-baseline mb-1"
                *ngFor="let service of serviceCategories; let i = index"
              >
                <input
                  id="{{ service.id }}"
                  class="form-check-input ml-2"
                  type="checkbox"
                  [checked]="service.value"
                  (change)="
                    service.value = !service.value;
                    onFilterChange('serviceCategories', i)
                  "
                />
                <label
                  class="form-check-label text-filter"
                  for="{{ service.name }}"
                  >{{ service.name }}</label
                >
              </div>
            </div>
          </div>
          <div
            *ngIf="isLoading"
            class="loading d-flex justify-content-center align-items-center"
          >
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
      <div ngbDropdown class="d-inline-block ml-1" placement="bottom-right">
        <button
          class="btn btn-light btn-sm"
          id="dropdownFilterType"
          ngbDropdownToggle
        >
          Type
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownFilterType">
          <div class="px-3 py-2">
            <div class="pub-filter">
              <div
                class="d-flex justify-content-start align-items-baseline mb-1"
                *ngFor="let type of types; let i = index"
              >
                <input
                  id="{{ type.name }}"
                  class="form-check-input ml-2"
                  type="checkbox"
                  [checked]="type.value"
                  (change)="
                    type.value = !type.value; onFilterChange('types', i)
                  "
                />
                <label
                  class="form-check-label text-filter"
                  for="{{ type.name }}"
                  >{{ type.name }}</label
                >
              </div>
            </div>
          </div>
          <div
            *ngIf="isLoading"
            class="loading d-flex justify-content-center align-items-center"
          >
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col">
      <canvas
        baseChart
        height="70px"
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType"
      >
      </canvas>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col d-flex justify-content-center font-weight-bold">
      PUBLISHED = TRUE
    </div>
    <div class="col d-flex justify-content-center font-weight-bold">
      PUBLISHED = FALSE
    </div>
  </div>
  <div class="row mt-4">
    <div class="col">
      <table class="table table-bordered total-data-table">
        <thead>
          <tr>
            <th></th>
            <th>All Assets</th>
            <th>Yesterday Assets</th>
            <th>Last Week Assets</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let data of tablePublished"
            [ngClass]="data.name === 'TRUE' && 'light-blue font-weight-bold'"
          >
            <td>{{ data.name }}</td>
            <td>{{ data.assetAll }}</td>
            <td>{{ data.assetYesterday }}</td>
            <td>{{ data.assetLastWeek }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col">
      <table class="table table-bordered total-data-table">
        <thead>
          <tr>
            <th></th>
            <th>All Assets</th>
            <th>Yesterday Assets</th>
            <th>Last Week Assets</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let data of tableUnpublished"
            [ngClass]="data.name === 'FALSE' && 'light-blue font-weight-bold'"
          >
            <td>{{ data.name }}</td>
            <td>{{ data.assetAll }}</td>
            <td>{{ data.assetYesterday }}</td>
            <td>{{ data.assetLastWeek }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  </div>