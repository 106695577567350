<div class="panel-header d-flex justify-content-start">
  <div class="d-flex">
    <div class="ltz d-flex justify-content-start">
      <h6>{{request.timezone ? request.timezone?.key?.split(' ')[0] : ''}}</h6>
      <div class="input-group">
        <select class="offset"
                [disabled]="isSchedulesLoading"
                *ngIf="showTimezoneDropDown"
                [(ngModel)]="request.timezone"
                (ngModelChange)="updateTimezone($event)">
          <option *ngFor="let x of offsetOptions"
                  [ngValue]="x"
                  [title]="x.tooltip"
                  [selected]="request.timezone?.key === x.key">
            {{ x.key }}
          </option>
        </select>
      </div>
      <i class="fa fa-clock" container="body"
         ngbTooltip="{{ showTimezoneDropDown ? 'Hide' : 'Change' }} local timezone."
         (click)="toggleRenderTimezoneDropdown()">
      </i>
    </div>
    <h6>{{ request?.channel?.sourceId }}</h6>
  </div>
  <div class="panel-actions mt-1 ml-auto mr-3">
    <form class="form-inline">
      <div class="form-group schedule-date-picker">
        <div class="input-group">
          <input name="dp" class="form-control" placeholder="yyyy-mm-dd" outsideDays="hidden" ngbDatepicker
                 [(ngModel)]="request.date"
                 [disabled]="isSchedulesLoading"
                 #d="ngbDatepicker"
                 (dateSelect)="updateScheduleDate(d._inputValue)"
                 [dayTemplate]="templateDay"/>
          <div class="input-group-append">
            <button (click)="d.toggle()" class="btn btn-light far fa-calendar-alt btn-icon"></button>
          </div>
        </div>
        <ng-template #templateDay let-date let-focused="focused">
                <span class="custom-day"
                      [class.focused]="focused"
                      [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)"
                      (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null">
                  {{ date.day }}
                </span>
        </ng-template>
      </div>
      <i container="body" ngbTooltip="{{ shouldHideProgramPanel ? 'Show' : 'Hide' }} Programs" class="ml-3"
         [ngClass]=" shouldHideProgramPanel ? 'fa fa-plus-circle' : 'fa fa-minus-circle'"
         (click)="toggleProgramPanel()">
      </i>
      <div class="form-group ml-3 mr-2">
        <input *ngIf="hasEvent" type="checkbox" [disabled]="isSchedulesLoading"
               [indeterminate]="checkAllIndeterminate"
               [checked]="checkAll" id="checkAll"
               (change)="onCheckAllScheduleEvents($event)"/>
      </div>
    </form>
  </div>
</div>
