import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild,} from '@angular/core'
import {ProgramsService} from '../programs.service'
import {UserService} from '../../users/users.service'
import {ConfirmationModalComponent} from 'src/app/components/confirmation-modal/confirmation-modal.component'
import {NgbModal, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap'
import {Observable, of, Subject} from 'rxjs'
import {catchError, debounceTime, distinctUntilChanged, switchMap,} from 'rxjs/operators'
import {ConfirmationModalService} from '../../../components/confirmation-modal/confirmation-modal.service'
import {AppConstants} from '../../../app.constants'
import {VenuesService} from '../../venues/venues.service'
import {TeamService} from '../../world-cup-team/team.service'
import {PlatformService} from 'src/app/services/platform.service'
import cleanDeep from 'clean-deep'

@Component({
  selector: 'app-program-form',
  templateUrl: './program-form.component.html',
  styleUrls: ['./program-form.component.scss'],
})
export class ProgramFormComponent implements OnInit, OnChanges {
  @Output() programId: EventEmitter<string> = new EventEmitter()
  @Output() newProgramCreated: EventEmitter<string> = new EventEmitter()
  @Output() isCopyToMaster: EventEmitter<any> = new EventEmitter()
  @Input() program: Program
  @Input() genres: Genre[]
  @Input() ratings: Rating[]
  @Input() mpaaList: Rating[]
  @Input() usaParental: Rating[]
  @Input() ratingsAdvisories: any[]
  @Input() brazilRatings: Rating[]
  @Input() selectedProvider: string
  @Input() seasonsAndEpisodes: any[]
  @Input() oldProgram: string
  @Input() publishVersion: number
  @Input() seasonTeams: any[]
  @Input() stages: any[]

  @ViewChild('packageSearch', {static: true}) packageSearch: NgbTypeahead
  packageClick$ = new Subject<string>()
  packageFocus$ = new Subject<string>()

  @ViewChild('duplicateModal', {static: false}) duplicateModal: any
  @ViewChild('duplicateProgConfirmModal', {static: true}) duplicateProgConfirmModal: any
  programTypes: string[]
  isLoading: boolean
  showLoader: boolean
  active: number
  activeCast: number
  navImage: number
  previewUrl: string
  selectedImageCategory = 'Iconic'
  celebritySearchString: string
  packageNames: string[]

  teams: Team[]
  teamList: {
    id: number
    name: string
  }[]
  teamNames: string[]
  venues: Venue[]
  venueList: {
    id: number
    name: string
  }[]
  venueNames: string[]
  seriesTypeCode: string[]
  selectedTitle: number[]
  selectedDesc: number[]
  release: Release
  award: Award
  keyword: Keyword
  season: any
  castOrCrew: CastOrCrew
  selectedCelebrity: any
  extraInfo: ExtraInfo
  selectedRecipient: any
  categories: any[]
  years: any[]
  minYear: number
  maxYear: number
  display: boolean
  selectedCategory: any
  countries: countries[]
  medium: string[]
  releaseType: string[]
  otherType: string
  otherMedium: string
  isTypeInputHidden: boolean
  isMediumInputHidden: boolean
  programDetails: Program
  file: any
  listRole: string[]
  otherRole: string
  isRoleInputHidden: boolean
  isRemove: boolean
  removeCelebrity: CastOrCrew
  market: string
  languageList: any
  imageCategories: string[]
  image: ProgramImage
  isSky: boolean
  systems: externalRefs[]
  externalRef: externalRefs
  refNames: any
  duplicatePrograms: any []
  showDuplicateProgram: boolean
  isPublish: boolean
  venuesV2: any
  readonly CONSTANTS = AppConstants
  tcsActions: any[]
  dthPlatform : boolean

  constructor(
    private programService: ProgramsService,
    private userService: UserService,
    private modalService: NgbModal,
    private confirmModalService: ConfirmationModalService,
    private venueService: VenuesService,
    private teamService: TeamService,
    private platformService: PlatformService
  ) {
  }

  ngOnInit() {
    this.display = false
    this.minYear = 1950
    this.maxYear = new Date().getFullYear()
    this.teamNames = []
    this.teamList = []
    this.venueNames = []
    this.venueList = []
    this.active = 1
    this.activeCast = 1
    this.navImage = 1
    this.previewUrl = 'assets/images/noImage.jpg'
    this.showLoader = true
    this.isLoading = false
    this.program = this.programService.programDefaults() as any
    this.seriesTypeCode = ['SH', 'EP', 'SP', 'SE']
    this.selectedTitle = [0, 1]
    this.selectedDesc = [0, 1]
    this.getTeams()
    this.getVenues()
    this.getMarket()
    this.season = 1
    this.packageNames = []
    this.isSky = location.href.includes('sky')

    this.release = this.programService.releaseDefaults()
    this.award = this.programService.awardsDefaults()
    this.keyword = this.programService.keywordsDefaults()
    this.castOrCrew = this.programService.castOrCrewDefaults()
    this.countries = this.programService.getCountries()
    this.categories = this.programService.getCategories()
    this.releaseType = this.programService.getReleaseType()
    this.medium = this.programService.getReleaseMedium()
    this.languageList = this.programService.languageList()
    this.listRole = this.programService.getRoleCast()
    this.removeCelebrity = this.programService.castOrCrewDefaults()
    this.externalRef = this.programService.externalRefsDefault()
    this.isTypeInputHidden = true
    this.isMediumInputHidden = true
    this.program.language = 'es'
    this.removeCelebrity = this.programService.castOrCrewDefaults()
    this.imageCategories = this.programService.getImageCategories()
    this.image = this.programService.imageDefaults() as any
    this.isRoleInputHidden = true
    this.isRemove = false

    this.years = []
    for (let i = this.maxYear; i >= this.minYear; i--) {
      this.years.push(i)
    }

    this.getSystems()
    this.showDuplicateProgram = false
    this.duplicatePrograms = this.programService.programDefaults() as any
    this.getVenuesV2()
    this.tcsActions = this.programService.tcsConfigurations()
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetDefaults()
  }

  resetDefaults() {
    /* Reset form validation */
    const invalidInputs = document.querySelectorAll('.is-invalid')
    invalidInputs.forEach((input) => input.classList.remove('is-invalid'))

    /* Reset tab default active */
    this.active = 1
    this.activeCast = 1
    this.navImage = 1
    this.resetImage()
    this.closeModal()
  }

  resetImage() {
    this.image = this.programService.imageDefaults() as any
    if (document.getElementById('inputLabel')) {
      document.getElementById('inputLabel').innerText = ''
    }
  }

  getMarket() {
    this.programService.getMarket().subscribe((data) => {
      this.market = data.response
      this.program.language = this.program.market === 'sky' ? 'pt' : 'es'
    })
  }

  genreOnChange() {
    this.program.genreIds = this.program.genres.map(({id}) => id)
  }

  advisoryOnChange() {
    this.program.advisories = this.program.advisoryNames.map(({id}) => id)
  }

  resetGenres(genres) {
    this.program.genres = this.genres
      .filter((genre) => (genres || []).includes(genre.id))
      .map((g) => g.name)
  }

  stringToArray(field, strField, extraInfo = null) {
    const arrStr = this.program[strField].replace(' ', '').split(',')
    if (extraInfo) {
      this.program[extraInfo][field] = arrStr
    } else {
      this.program[field] = arrStr
    }
  }

  saveProgram(isPublish) {
    this.isLoading = true

    if (!this.program?.isHighLight || this.program?.parentProgramId === '') {
      delete this.program?.parentProgramId
    }

    if((this.program.type !== this.CONSTANTS.SPORT_EPISODE && this.program.type !== this.CONSTANTS.EPISODE) || !this.isDthPlatform()){
      delete this.program['dthInfo']
    }

    this.isPublish = isPublish
    // VLS-2197: Publish version program if version number greater than current version number
    // structure schema follow Program interface, so we don't need to check data equality regardless
    if (JSON.stringify(this.program) === this.oldProgram) {
      if (isPublish) {
        // if no change and click "Publish"
        if (parseInt(JSON.stringify(this.program.version)) <= this.publishVersion) {
          // if no change and version number not greater than current version number
          this.showAlert('Warning', `No changes`)
        } else {
          // if no change but version number greater than current version number
          // call api update program, add field publishVersionChange is true
          this.programDetails = Object.assign({}, this.program)
          this.buildProgramTitles()
          this.buildProgramDescription()
          this.buildExtraInfo()
          this.getSportsInfo()

          const payload = this.buildPayload(this.program)

          this.programService.getDuplicateProgram(payload).toPromise().then(data => {
            this.duplicatePrograms = data
            let invalidFields = this.isValid()
            if (invalidFields.length === 0) {
              if (!this.duplicatePrograms.length) {
                const modal = this.modalService.open(ConfirmationModalComponent, {
                  size: 'lg',
                  centered: true,
                  backdrop: 'static',
                })
                modal.componentInstance.title = 'Submit Changes'
                modal.componentInstance.message = 'Are you sure you want to continue?'
                modal.componentInstance.isAlert = false
                modal.result.then((result) => {
                  if (result) {
                    this.isLoading = true
                    this.programDetails.publish = isPublish;
                    if (!isPublish) {
                      this.program.images = this.program.images
                        .filter(image => image.id === null)
                        .map(img => {
                          return {
                            ...img,
                            published: false
                          }
                        })
                    }
                    if (!this.programDetails.isSports || (this.programDetails.isHighLight && !this.programDetails.parentProgramId)) {
                      this.programDetails.sportsInfo = null;
                    }
                    this.programDetails.genres = this.programDetails.genreIds
                    this.programDetails.publishVersionChange = true;
                    this.cleanProgramData()
                    this.programService.save(cleanDeep(this.programDetails, {NaNValues: true})).subscribe(
                      (response) => {
                        this.resetGenres(response.genres)
                        this.showAlert('Success', `Successfully publish version`)
                        this.program.id = response.id
                        this.programId.emit(response.id)

                        if (!this.programDetails.programId) {
                          this.newProgramCreated.emit(response.id)
                        }
                      },
                      (error) => {
                        this.resetGenres(this.program.genreIds)
                        if (error.error.errors[0] === this.CONSTANTS.PARENT_NOT_FOUND) {
                          this.showAlert('Error', this.CONSTANTS.MESSAGE_PARENT_NOT_FOUND.replace("$1", this.program.parentProgramId))
                        } else {
                          this.showAlert('Error', error.error.errors[0])
                        }
                      }
                    )
                  }
                })
              } else {
                this.buildDuplicateProgramInfo(this.duplicatePrograms)
                this.modalService.open(this.duplicateProgConfirmModal, {
                  ariaLabelledBy: 'modal-basic-title',
                  size: 'lg',
                  backdrop: true,
                  keyboard: false,
                  centered: true,
                })
              }
            } else {
              // display alert and focus to error
              this.showAlertValidation(this.CONSTANTS.WARNING, invalidFields)
            }
          })
          this.isLoading = false
        }
      } else {
        // if no change and click "Quick Save"
        this.showAlert('Warning', `No changes`)
      }
    } else {
      // if data changed, call api update program for create new program version and update program
      this.programDetails = Object.assign({}, this.program)

      this.buildProgramTitles()
      this.buildProgramDescription()
      this.buildExtraInfo()
      this.getSportsInfo()

      const payload = this.buildPayload(this.program)
      this.programService.getDuplicateProgram(payload).toPromise().then(data => {
        this.duplicatePrograms = data
        let invalidFields = this.isValid()
        if (invalidFields.length === 0) {
          if (!this.duplicatePrograms.length) {
            const modal = this.modalService.open(ConfirmationModalComponent, {
              size: 'lg',
              centered: true,
              backdrop: 'static',
            })
            modal.componentInstance.title = 'Submit Changes'
            if (this.program?.isHighLight && JSON.parse(this.oldProgram)?.parentProgramId !== this.program?.parentProgramId) {
              modal.componentInstance.message = this.CONSTANTS.MESSAGE_CHANGE_PARENT_CONFIRM
            } else {
              modal.componentInstance.message = this.CONSTANTS.MESSAGE_CONFIRM
            }
            modal.componentInstance.isAlert = false
            modal.result.then((result) => {
              if (result) {
                this.isLoading = true
                this.programDetails.publish = isPublish;

                if (!isPublish) {
                  this.program.images = this.program.images
                    .filter(image => image.id === null)
                    .map(img => {
                      return {
                        ...img,
                        published: false
                      }
                    })
                }
                if (!this.programDetails.isSports || (this.programDetails.isHighLight && !this.programDetails.parentProgramId)) {
                  this.programDetails.sportsInfo = null;
                }
                this.programDetails.genres = this.programDetails.genreIds
                //VLS-2197: only version change is false when create a new version
                this.programDetails.publishVersionChange = false;
                this.cleanProgramData()
                this.programService.save(cleanDeep(this.programDetails, {NaNValues: true})).subscribe(
                  (response) => {
                    this.resetGenres(response.genres)
                    this.showAlert('Success', `Successfully save program`)
                    this.program.id = response.id
                    this.programId.emit(response.id)

                    if (!this.programDetails.programId) {
                      this.newProgramCreated.emit(response.id)
                    }
                  },
                  (error) => {
                    this.resetGenres(this.program.genreIds)
                    if (error.error.errors[0] === this.CONSTANTS.PARENT_NOT_FOUND) {
                      this.showAlert('Error', this.CONSTANTS.MESSAGE_PARENT_NOT_FOUND.replace("$1", this.program.parentProgramId))
                    } else {
                      this.showAlert('Error', error.error.errors[0])
                    }
                  }
                )
              }
            })
          } else {
            this.buildDuplicateProgramInfo(this.duplicatePrograms)
            this.modalService.open(this.duplicateProgConfirmModal, {
              ariaLabelledBy: 'modal-basic-title',
              size: 'lg',
              backdrop: true,
              keyboard: false,
              centered: true,
            })
          }
        } else {
          // display alert and focus to error
          this.showAlertValidation(this.CONSTANTS.WARNING, invalidFields)
        }
        this.isLoading = false
      })
    }
  }

  buildPayload(program: Program) {
    const newProgramTitles = program.titles?.map(({value}) => value)
    return {
      id: program.programId || '',
      titles: newProgramTitles || [],
      origAirDate: program.origAirDate || '',
      releaseYear: program.releaseYear || ''
    }
  }

  getExternalRefs(externalRefs, key) {
    var extraInfo = {};
    if (externalRefs && externalRefs.length) {
      extraInfo = externalRefs.find((info) =>
        info.refName === key
      );
    }

    if (!extraInfo) {
      return {}
    }

    return extraInfo
  }

  buildDuplicateProgramInfo(programs) {
    programs?.forEach((program) => {
      const titles = program.titles
      const descriptions = program.descriptions
      let titleLength = this.programService.getTitleDescMaxLength(titles, this.CONSTANTS.EN)
      let descLength = this.programService.getTitleDescMaxLength(descriptions, this.CONSTANTS.EN);

      if (program.episodeAltMappings) {
        program.alternateEpisodeNumber = program.episodeAltMappings[0]?.episodeNumber
        program.alternateSeasonNumber = program.episodeAltMappings[0]?.seasonNumber
      }
      program.sportSeason = program.sportsInfo?.sportSeason?.value
      program.rootId = this.getExternalRefs(program.externalRefs, 'rootId')
      program.optaId = this.getExternalRefs(program.externalRefs, 'optaId')
      program.countriesOfOrigin = (program.countries || []).join(', ')
      program.englishDescription = this.programService.getDesc(
        descriptions,
        this.CONSTANTS.EN,
        descLength
      )

      program.spanishDescription = this.programService.getDesc(
        descriptions,
        this.CONSTANTS.ES,
        descLength
      )

      program.englishTitle = this.programService.getTitle(
        titles,
        this.CONSTANTS.EN,
        titleLength
      )

      program.spanishTitle = this.programService.getTitle(
        titles,
        this.CONSTANTS.ES,
        titleLength
      )
      program.originalTitle = this.programService.getOriginalTitle(
        titles,
        program.originalAudiolang
      )
    })
  }

  saveProgWithConfirmed(isPublish) {
      this.modalService.dismissAll()
      this.isLoading = true
      this.programDetails.publish = isPublish;
      if((this.program.type !== this.CONSTANTS.SPORT_EPISODE && this.program.type !== this.CONSTANTS.EPISODE) || !this.isDthPlatform()){
        delete this.program['dthInfo']
      }
      if (!isPublish) {
        this.program.images = this.program.images
          .filter(image => image.id === null)
          .map(img => {
            return {
              ...img,
              published: false
            }
          })
      }
      if (!this.programDetails.isSports) {
        this.programDetails.sportsInfo = null;
      }
      this.programDetails.genres = this.programDetails.genreIds
      if (JSON.stringify(this.program) === this.oldProgram) {
        this.programDetails.publishVersionChange = true;
      } else {
        this.programDetails.publishVersionChange = false;
      }
      this.cleanProgramData()
      this.programService.save(this.programDetails).subscribe(
        (response) => {
          this.resetGenres(response.genres)
          if (isPublish) {
            this.showAlert('Success', `Successfully publish version`)
          } else {
            this.showAlert('Success', 'Successfully save program')
          }
          this.program.id = response.id
          this.programId.emit(response.id)

          if (!this.programDetails.programId) {
            this.newProgramCreated.emit(response.id)
          }
        },
        (error) => {
          this.resetGenres(this.program.genreIds)
          this.showAlert('Error', error.error.errors[0])
        }
      )
  }

  getSportsInfo() {
    this.programDetails.sportsInfo.gameDate =
      this.programDetails.sportsInfo?.gameDate || null

    this.programDetails.sportsInfo.gameTime =
      this.programDetails.sportsInfo?.gameTime || null

    this.programDetails.sportsInfo.gameTimezone =
      this.programDetails.sportsInfo?.gameTimezone || null

    this.programDetails.sportsInfo.playoffRound =
      this.programDetails.sportsInfo?.playoffRound || null

    this.programDetails.sportsInfo.gameNumber =
      this.programDetails.sportsInfo?.gameNumber || null

    //VLS-2907: fixing unable to create new program
    this.programDetails.sportsInfo.sportSeason.type =
      this.programDetails.sportsInfo?.sportSeason?.type || null

    this.programDetails.sportsInfo.sportSeason.value =
      this.programDetails.sportsInfo?.sportSeason?.value || null

    if (this.programDetails.sportsInfo.venue) {
      this.programDetails.sportsInfo.venue.names =
        this.programDetails.sportsInfo?.venue?.names?.length > 0
          ? this.programDetails.sportsInfo?.venue?.names
          : null
    }

    this.programDetails.sportsInfo.venue = this.programDetails.sportsInfo?.venue
      ? this.removeEmptyOrNull(this.programDetails.sportsInfo.venue)
      : null

    if (this.program.homeTeam && !!this.program.homeTeam?.id) {
      this.program.homeTeam.isHome =
        this.program.homeTeam && !!this.program.homeTeam?.id ? true : null
    }

    if (this.program.awayTeam && !!this.program.awayTeam?.id) {
      this.program.awayTeam.isHome =
        this.program.awayTeam && !!this.program.awayTeam?.id ? false : null
    }

    this.program.homeTeam = this.program.homeTeam
      ? this.removeEmptyOrNull(this.program.homeTeam)
      : null

    this.program.awayTeam = this.program.awayTeam
      ? this.removeEmptyOrNull(this.program.awayTeam)
      : null

    this.programDetails.sportsInfo.teams = []

    if (this.program.homeTeam) {
      this.programDetails.sportsInfo.teams.push(this.program?.homeTeam)
    }

    if (this.program.awayTeam) {
      this.programDetails.sportsInfo.teams.push(this.program?.awayTeam)
    }
  }

  removeEmptyOrNull(obj: { [x: string]: any }) {
    return Object.keys(obj).forEach((k) => {
      if (obj[k] == null || obj[k] === '') {
        delete obj[k]
      }
    })
  }

  buildExtraInfo() {
    if (this.programDetails.url != '' && this.programDetails.url != undefined) {
      this.addExtraInfo('url', this.programDetails.url)
    }
    if (
      this.programDetails.pictureFormats != undefined &&
      this.programDetails.pictureFormats != ''
    ) {
      this.addExtraInfo('pictureFormats', this.programDetails.pictureFormats)
    }
    if (
      this.programDetails.soundMixes != undefined &&
      this.programDetails.soundMixes != ''
    ) {
      this.addExtraInfo('soundMixes', this.programDetails.soundMixes)
    }
    if (
      this.programDetails.externalLinks != undefined &&
      this.programDetails.externalLinks != ''
    ) {
      this.addExtraInfo('externalLinks', this.programDetails.externalLinks)
    }
  }

  addExtraInfo(key: string, value: string) {
    const index = this.program.extraInfos.findIndex((info) => info.key == key)
    if (index >= 0) {
      this.program.extraInfos.splice(index)
      if (value != '') {
        this.extraInfo = {
          key,
          system: 'System',
          value: [value],
          episodeInfo: null,
        }
        this.program.extraInfos.push(this.extraInfo)
      }
    } else {
      if (value != '') {
        this.extraInfo = {
          key,
          system: 'System',
          value: [value],
          episodeInfo: null,
        }
        this.program.extraInfos.push(this.extraInfo)
      }
    }
  }

  formatHeaderTitle(englishEpisode, spanishEpisode) {
    const englishEpisodeTitle = this.program[englishEpisode]
    const spanishEpisodeTitle = this.program[spanishEpisode]

    if ((englishEpisodeTitle?.value === '' && spanishEpisodeTitle?.value === '')) {
      return this.program['originalTitle'].value || ''
    } else {
      return (
        (englishEpisodeTitle && englishEpisodeTitle.value) ||
        (spanishEpisodeTitle && spanishEpisodeTitle.value)
      )
    }
  }

  checkEpisode() {
    var isEpisode = false
    if (this.programDetails.market == 'sky') {
      isEpisode =
        this.programDetails?.portugueseEpisodeTitle?.value != '' ||
        this.programDetails.englishEpisodeTitle?.value != '' ||
        (this.programDetails?.otherEpisodeTitle && this.programDetails?.otherEpisodeTitle?.value != '') ||
        this.programDetails.episodeTitleAvailable
    } else {
      isEpisode =
        this.programDetails?.spanishEpisodeTitle?.value != '' ||
        this.programDetails.englishEpisodeTitle?.value != '' ||
        (this.programDetails?.otherEpisodeTitle && this.programDetails?.otherEpisodeTitle?.value != '') ||
        this.programDetails.episodeTitleAvailable
    }
    return isEpisode
  }

  generateProgramTitle(titleValue, obj, lang, type, length) {
    // if current program have no titles (by ingestion flow or updating manually),
    // need to create empty titles
    this.programDetails[obj] = this.programDetails[obj] ? this.programDetails[obj] : []
    if (this.programDetails[obj]) {
      if (titleValue.value != '') {
        if (this.programDetails[obj].length > 0) {
          var newData = true
          this.programDetails[obj].forEach((data, i) => {
            if (
              data.lang == titleValue.lang &&
              data.length == titleValue.length &&
              data.type == titleValue.type
            ) {
              this.programDetails[obj][i].value = titleValue.value
              this.programDetails[obj][i].lang = lang
              newData = false
            }
          })

          if (newData) {
            titleValue.lang = lang
            titleValue.length = length
            titleValue.type = type
            this.programDetails[obj].push(titleValue)
          }
        } else {
          titleValue.lang = lang
          titleValue.length = length
          titleValue.type = type
          this.programDetails[obj].push(titleValue)
        }
      } else {
        this.programDetails[obj].forEach((data, i) => {
          if (
            data.lang == titleValue.lang &&
            data.length == titleValue.length &&
            data.type == titleValue.type
          ) {
            this.programDetails[obj].splice(i, 1);
          }
        });
      }
    }
  }

  buildProgramTitles() {
    var obj = this.checkEpisode() ? 'parentTitle' : 'titles'
    this.generateProgramTitle(
      this.programDetails.originalTitle,
      obj,
      this.programDetails.originalAudiolang,
      'original',
      120
    )
    this.generateProgramTitle(
      this.programDetails.englishTitle,
      obj,
      'en',
      'full',
      120
    )
    this.generateProgramTitle(
      this.programDetails.englishShortTitle,
      obj,
      'en',
      'red',
      100
    )
    this.generateProgramTitle(
      this.programDetails.spanishTitle,
      obj,
      'es',
      'full',
      120
    )
    this.generateProgramTitle(
      this.programDetails.spanishShortTitle,
      obj,
      'es',
      'red',
      70
    )
    this.generateProgramTitle(
      this.programDetails.portugueseTitle,
      obj,
      'pt',
      'full',
      120
    )
    this.generateProgramTitle(
      this.programDetails.portugueseShortTitle,
      obj,
      'pt',
      'red',
      70
    )
    if (this.programDetails?.otherLanguage) {
      this.generateProgramTitle(
        this.programDetails.otherTitle,
        obj,
        this.programDetails.otherLanguage,
        'full',
        120
      )
      this.generateProgramTitle(
        this.programDetails.otherShortTitle,
        obj,
        this.programDetails.otherLanguage,
        'red',
        70
      )
    }
    if (
      this.programDetails.type === 'EP' ||
      this.programDetails.type === 'SE'
    ) {
      if (this.checkEpisode()) {
        this.programDetails.episodeTitleAvailable = true
        this.generateProgramTitle(
          this.programDetails.portugueseEpisodeTitle,
          'titles',
          'pt',
          'full',
          120
        )
        this.generateProgramTitle(
          this.programDetails.spanishEpisodeTitle,
          'titles',
          'es',
          'full',
          120
        )
        this.generateProgramTitle(
          this.programDetails.englishEpisodeTitle,
          'titles',
          'en',
          'full',
          120
        )
        if (this.programDetails?.otherLanguage) {
          this.generateProgramTitle(
            this.programDetails.otherEpisodeTitle,
            'titles',
            this.programDetails.otherLanguage,
            'full',
            120
          )
        }
      } else {
        this.programDetails.episodeTitleAvailable = false
      }
    } else {
      this.programDetails.episodeTitleAvailable = null
    }
  }

  buildProgramDescription() {

    this.generateProgramTitle(
      this.programDetails.englishDescription,
      'descriptions',
      'en',
      'plot',
      500
    );

    this.generateProgramTitle(
      this.programDetails.englishShortDescription,
      'descriptions',
      'en',
      'plot',
      250
    );

    this.generateProgramTitle(
      this.programDetails.spanishDescription,
      'descriptions',
      'es',
      'plot',
      500
    );

    this.generateProgramTitle(
      this.programDetails.spanishShortDescription,
      'descriptions',
      'es',
      'plot',
      250
    );

    this.generateProgramTitle(
      this.programDetails.portugueseDescription,
      'descriptions',
      'pt',
      'plot',
      500
    );

    this.generateProgramTitle(
      this.programDetails.portugueseShortDescription,
      'descriptions',
      'pt',
      'plot',
      250
    );

    if (this.programDetails?.otherLanguage) {
      this.generateProgramTitle(
        this.programDetails.otherDescription,
        'descriptions',
        this.programDetails.otherLanguage,
        'plot',
        500
      );

      this.generateProgramTitle(
        this.programDetails.otherShortDescription,
        'descriptions',
        this.programDetails.otherLanguage,
        'plot',
        250
      );
    }
  }

  cleanProgramData() {
    if (this.program.id === '') {
      delete this.program.id
      // eslint-disable-next-line no-underscore-dangle
      delete this.program._id
    }
    // this.removeTransientFields()
  }

  removeTransientFields() {
    this.programService.transientFields().forEach((field) => {
      delete this.program[field]
    })
  }

  checkTitleByMarket(field, englishField, otherTitle) {
    let fieldsToValidate = []
    if (
      this.programDetails[englishField].value === '' &&
      this.programDetails[field].value === ''
    ) {

      if (this.programDetails.otherLanguage && this.programDetails[otherTitle].value === '') {
        fieldsToValidate.push(otherTitle);
        fieldsToValidate.push(englishField)
        fieldsToValidate.push(field)
      }
      if (!this.programDetails.otherLanguage) {
        fieldsToValidate.push(englishField)
        fieldsToValidate.push(field)
      }
    }

    return fieldsToValidate
  }

  isValid() {
    const inValid = []
    const programType = this.programDetails.type
    // VLS-2186: Temporarily remove Original Title validation
    const fieldsToValidate = this.program?.episodeTitleAvailable
      ? []
      : ['originalAudiolang']

    if (programType === 'EP' || programType === 'SE') {
      if (this.programDetails.market === 'ssla') {
        fieldsToValidate.push.apply(
          fieldsToValidate,
          this.checkTitleByMarket('spanishEpisodeTitle', 'englishEpisodeTitle', 'otherEpisodeTitle')
        )
      } else {
        fieldsToValidate.push.apply(
          fieldsToValidate,
          this.checkTitleByMarket(
            'portugueseEpisodeTitle',
            'englishEpisodeTitle',
            'otherEpisodeTitle'
          )
        )
      }

      fieldsToValidate.push('origAirDate')
      //VLS-2859: add required field
      if (!this.program.isHighLight) {
        fieldsToValidate.push('gameDate')
        fieldsToValidate.push('gameTime')
      }
    } else {
      // VLS-2186: At least one title of any language
      if (this.programDetails.market === 'ssla') {
        fieldsToValidate.push.apply(
          fieldsToValidate,
          this.checkTitleByMarket('spanishTitle', 'englishTitle', 'NA')
        )
      } else {
        fieldsToValidate.push.apply(
          fieldsToValidate,
          this.checkTitleByMarket('portugueseTitle', 'englishTitle', 'NA')
        )
      }
    }

    if (programType === 'SH' || programType === 'SV' || programType === 'SP') {
      // VLS-2236: original air date or release year is required
      if (!this.programDetails.origAirDate && !this.programDetails.releaseYear) {
        fieldsToValidate.push('origAirDate')
        fieldsToValidate.push('releaseYear')
      }
      //VLS-2859: add required field
      fieldsToValidate.push('gameDate')
      fieldsToValidate.push('gameTime')
    }

    if (programType === 'MV') {
      fieldsToValidate.push('releaseYear')
    }

    fieldsToValidate.forEach((field) => {
      let fieldToValidate = this.programDetails[field]
      if (this.programDetails.sportsInfo) {
        if (field === 'gameDate' || field === 'gameTime') {
          fieldToValidate = this.programDetails.sportsInfo[field]
        }
      }
      if (typeof this.program[field] === 'object') {
        fieldToValidate = this.programDetails[field]?.value
      }
      if (!this.program._id && !fieldToValidate) {
        const inputField = document.getElementById(field)
        if (inputField) {
          if (inputField.nextSibling) {
            inputField.parentNode.removeChild(inputField.nextSibling)
          }
          inValid.push(field)
          inputField.insertAdjacentHTML(
            'afterend',
            '<div class="invalid-feedback">Required field</div>'
          )
          inputField.classList.add('is-invalid')
        }
      }
    })
    return inValid
  }

  onFieldChange(event, field = 'en', main = false) {
    const {id, value} = event.target
    if (value) {
      document.getElementById(id).classList.remove('is-invalid')
    }
    let lang = 'english'

    if (main) {
      lang = this.program.market == 'sky' ? 'portuguese' : 'spanish'
    }

    // VLS-2236: For series program if releaseYear or origAirDate is available then remove validations
    if (field === "SERIES") {
      document.getElementById("releaseYear").classList.remove('is-invalid')
      document.getElementById("origAirDate").classList.remove('is-invalid')
    } else if (field !== 'en') {
      const partnerId = lang.concat(field)
      if (document.getElementById(partnerId)) {
        document.getElementById(partnerId).classList.remove('is-invalid')
      }

      if (field === "EpisodeTitle" && document.getElementById("englishEpisodeTitle")) {
        document.getElementById("englishEpisodeTitle").classList.remove('is-invalid')
      }

      if (field === "EpisodeTitle" && document.getElementById("otherEpisodeTitle")) {
        document.getElementById("otherEpisodeTitle").classList.remove('is-invalid')
      }

      if (field === "gameDate" && document.getElementById("gameDate")) {
        document.getElementById("gameDate").classList.remove('is-invalid')
      }

      if (field === "gameTime" && document.getElementById("gameTime")) {
        document.getElementById("gameTime").classList.remove('is-invalid')
      }
    }
  }

  showAlert(type, message) {
    this.isLoading = false
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'sm',
      centered: true,
      backdrop: 'static',
    })
    modalRef.componentInstance.title = type
    modalRef.componentInstance.message = message
    modalRef.componentInstance.isAlert = true
  }

  showAlertValidation(type: string, invalidFields: string[]) {
    this.isLoading = false
    let mappings = []
    invalidFields?.forEach(element => {
      mappings.push(this.CONSTANTS.PROGRAM_VALIDATION_MAPPING[element])
    });
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size: 'sm',
      centered: true,
      backdrop: 'static',
    })
    modalRef.componentInstance.title = type
    modalRef.componentInstance.message = `${mappings.join(', ')} ${mappings.length === 1 ? 'is' : 'are'} required!`
    modalRef.componentInstance.isAlert = true
    modalRef.result.then((result) => {
      if (result) {
        if (invalidFields.toString().includes(this.CONSTANTS.TEXT_GAME)) {
          this.active = 5
        }
        document.getElementById(invalidFields[0]).focus();
      }
    })
  }

  setImageCategory(category: string) {
    this.selectedImageCategory = category
    this.image.category = category
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.image.imageFile = event.target.files[0]
      document.getElementById('inputLabel').innerText =
        this.image.imageFile.name
      let fr = new FileReader()
      fr.onload = () => {
        const img = new Image()
        img.onload = () => {
          this.image.height = img.height.toString()
          this.image.width = img.width

          if (this.program?.imageSizes !== undefined) {
            this.image.ratio = this.program.imageSizes[this.navImage - 1]
          } else {
            let gcd = this.gcd(this.image.width, this.image.height)
            let left = +this.image.height / gcd;
            this.image.ratio = this.image.width / gcd + ':' + left;

            this.program.images.push(this.image);
            const grouped = this.groupBy(this.program.images, 'ratio');
            this.program.imageSizes = Object.keys(grouped)
            this.program.programImages = grouped
          }
        }

        img.src = fr.result.toString() // This is the data URL
        this.image.imageURL = fr.result.toString()
      }
      fr.readAsDataURL(event.target.files[0])
    }
  }

  groupBy(xs, key) {
    return (xs || []).reduce((rv, x) => {
      ;(rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  gcd(a, b) {
    if (b == 0) return a
    return this.gcd(b, a % b)
  }

  openRatingModal(ratingModal) {
    this.modalService.open(ratingModal, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
    })
  }

  closeModal() {
    this.modalService.dismissAll()
    this.release = this.programService.releaseDefaults()
    this.award = this.programService.awardsDefaults()
    this.keyword = this.programService.keywordsDefaults()
    this.castOrCrew = this.programService.castOrCrewDefaults()
    this.removeCelebrity = this.programService.castOrCrewDefaults()
    this.image = this.programService.imageDefaults() as any
    this.externalRef = this.programService.externalRefsDefault()
    this.celebritySearchString = ''
    this.selectedCelebrity = null
    this.isTypeInputHidden = true
    this.isMediumInputHidden = true
    this.isRoleInputHidden = true
    this.otherRole = ''
    this.isRemove = false
    this.selectedImageCategory = 'Iconic'
    if (document.getElementById('inputLabel'))
      document.getElementById('inputLabel').innerText = ''
    this.selectedCategory = null
    this.selectedRecipient = null
    this.listRole = this.programService.getRoleCast()
  }

  ratingOnChange() {
    this.program.ratings = []
    const mpaa = this.program.mpaaRatings
    const usaParental = this.program.usaParentalRatings
    const brazilRatings = this.program.brazilRatings

    if (!!mpaa) {
      this.program.ratings.push({id: mpaa})
    }
    if (!!usaParental) {
      this.program.ratings.push({id: usaParental})
    }
    if (!!brazilRatings) {
      this.program.ratings.push({id: brazilRatings})
    }
    const selectedRatings = this.ratings.filter(
      (rating) =>
        this.program.ratings.find(
          (programRating) => programRating.id == rating.id
        ) != null
    )
    this.program.ratingString = selectedRatings
      .map((rating) => `${rating.code}: ${rating.rating}`)
      .join(', ')
  }

  openModal(modalName, entity = null, field = null, filter = null, id = null) {
    if (field) {
      if (entity !== 'castOrCrew') {
        this[entity] = Object.assign(
          {},
          this.program[field].find((data, index) => index === id)
        )
      }
    }

    if (entity === 'release') {
      this.release.index = id
      if (!this.releaseType.includes(this.release.type)) {
        this.otherType = this.release.type
        this.release.type = 'Others'
        this.isTypeInputHidden = false
      }

      if (!this.medium.includes(this.release.medium)) {
        this.otherType = this.release.medium
        this.release.medium = 'Others'
        this.isMediumInputHidden = false
      }
    }

    if (entity === 'award') {
      this.award.index = id
      this.selectedRecipient = this.program.credits.find((val) => {
        return val.personId === this[entity].recipientId
      })
      this.selectedCategory = this.categories.find((val) => {
        return val.categoryName === this[entity].category
      })
      this.selectedCategory = this.selectedCategory ?? this.categories[0]
    }

    if (entity === 'externalRef') {
      this.externalRef.index = id
      const selectedSystem = this.systems?.find((system) => {
        return system.system === this.externalRef.system
      })
      this.refNames = selectedSystem.refName
    }

    this.modalService.open(modalName, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    })
  }

  openDeleteModal(field, filter, id) {
    const modal = this.confirmModalService.createModal({
      title: 'Confirm removal',
      message: `Are you sure you want to remove this?`,
      isAlert: false,
      isConfirm: true,
    })
    modal.result.then((res) => {
      if (res) {
        this.program[field] = this.program[field].filter(
          (data, index) => index !== id
        )
      }
    })
  }

  addItem(fieldName, entity = null) {
    if (!this.program[fieldName]) {
      this.program[fieldName] = []
    }

    if (fieldName === 'images') {
      const isDTH = this.platformService.verifyPlatform(AppConstants.DTH)
      const isResize16x9 = entity.width.toString() == AppConstants.WIDTH_RATIO_16x9 && entity.height.toString() == AppConstants.HEIGHT_RATIO_16x9 ? true : false
      const isResize2x3 = entity.width.toString() == AppConstants.WIDTH_RESIZE_RATIO_2x3 && entity.height.toString() == AppConstants.HEIGHT_RESIZE_RATIO_2x3 ? true : false
      const isDimension2x3 = entity.width.toString() == AppConstants.WIDTH_DIMENSION_RATIO_2x3 && entity.height.toString() == AppConstants.HEIGHT_DIMENSION_RATIO_2x3 ? true : false
      if (isDTH && ((entity.ratio == AppConstants.RATIO_16x9 && !isResize16x9) || (entity.ratio == AppConstants.RATIO_2x3 && !isResize2x3 && !isDimension2x3))) {
        this.showAlert(
          AppConstants.WARNING,
          AppConstants.IMAGE_DENIED_MESSAGE)
        this.resetImage()
        return
      }
      entity.published = false
      this.program[fieldName].push(entity)
      if (this.program.programImages[entity.ratio]) {
        this.program.programImages[entity.ratio].unshift(entity)
      } else {
        this.program.programImages = {
          ...this.program.programImages,
          [entity.ratio]: [entity]
        }
      }
      this.resetImage()
    } else if (fieldName === 'groupedCastAndCrew') {
      if (entity.role === 'Others') {
        entity.role = this.otherRole
      }
      const hasPerson = this.program.credits?.find(
        (data) =>
          data.personId === entity.personId &&
          data.role === entity.role &&
          data.type === entity.type
      )
      if (hasPerson) {
        this.showAlert(
          'Error',
          `This celebrity already exists. Please try again!`
        )
      } else {
        if (entity.type.toLowerCase() === 'crew') {
          this.program?.groupedCrew.push({
            ...entity,
            ord: this.program?.groupedCrew.length + 1
          })
        } else {
          this.program?.groupedCast.push({
            ...entity,
            ord: this.program?.groupedCast.length + 1
          })
        }

        this.program.credits = [
          ...this.program.groupedCast,
          ...this.program.groupedCrew,
        ]

        this.closeModal()
      }
    } else {
      if (fieldName === 'releases') {
        if (entity.medium === 'Others') {
          entity.medium = this.otherMedium
        }

        if (entity.type === 'Others') {
          entity.type = this.otherType
        }
      }

      entity?.index != null
        ? (this.program[fieldName][entity.index] = entity)
        : this.program[fieldName].unshift(entity)

      this.closeModal()
    }
  }

  formatter = (x: { names: any }) =>
    (x.names[0].firstName || '') + ' ' + (x.names[0].lastName || '')

  inputFormatBandListValue(value: any) {
    if (value.names)
      return (
        (value.names[0].firstName || '') + ' ' + (value.names[0].lastName || '')
      )
    return value
  }

  searchCelebrity = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) =>
        this.programService.searchCelebrities(term).pipe(
          catchError(() => {
            return of([])
          })
        )
      )
    )

  selectedItem(item) {
    this.celebritySearchString =
      (item.item.names[0].firstName || '') +
      ' ' +
      (item.item.names[0].lastName || '')
    this.selectedCelebrity = item.item
    this.castOrCrew.castName = this.celebritySearchString
    this.castOrCrew.personId = this.selectedCelebrity.id
    this.castOrCrew.firstName = this.selectedCelebrity.names[0].firstName || ''
    this.castOrCrew.lastName = this.selectedCelebrity.names[0].lastName || ''
  }

  selectCelebrity(castOrCrew, index) {
    this.removeCelebrity = castOrCrew
    this.removeCelebrity.index = index
    this.isRemove = true
  }

  removePicture(image) {
  }

  getVenues() {
    if (!this.venueNames.length) {
      this.programService.getVenues().subscribe((data) => {
        this.venues = data.response
        this.venueList = []
        this.venueNames = this.venues.map((venue) => {
          const venueName = venue.venueBrands?.venueBrand[0].name[0].value
          this.venueList.push({
            id: venue.venueId,
            name: venueName,
          })
          return venueName
        })
      })
    }
  }

  getVenuesByMarket(venues, isSky: boolean) {
    const venueList = []
    venues.forEach((venue) => {
      if (!isSky) {
        const esVenue = (venue.names || []).find(
          (g) => g.lang === this.CONSTANTS.ES || g.lang === this.CONSTANTS.EN
        )
        if (esVenue && esVenue.value != '') {
          venueList.push({
            id: venue.id,
            value: esVenue.value,
            names: venue.names
          })
        }
        this.venuesV2 = venueList
      } else {
        const ptVenue = (venue.names || []).find(
          (g) => g.lang === this.CONSTANTS.PT || g.lang === this.CONSTANTS.PT_BR || g.lang === this.CONSTANTS.EN
        )
        if (ptVenue && ptVenue != '') {
          venueList.push({
            id: venue.id,
            value: ptVenue.value,
            names: venue.names
          })
        }
      }
      this.venuesV2 = venueList
    })
  }

  getTeams() {
    if (!this.teamNames.length) {
      this.programService.getTeams().subscribe((data) => {
        this.teams = data.response
        this.teamList = []
        this.teamNames = this.teams.map((team) => {
          const teamName = team.teamBrands?.teamBrand[0].officialName
          this.teamList.push({
            id: team.teamId,
            name: teamName,
          })
          return teamName
        })
      })
    }
  }

  getVenuesV2() {
    const searchData = {
      searchString: ''
    }
    const params: PaginationParams = {
      page: 0,
      size: 500
    }
    this.venueService.searchVenues(searchData, params).subscribe(data => {
      if (data.response) {
        this.getVenuesByMarket(data.response, this.isSky)
      }
    })
  }

  getHeaders(data = []) {
    if (data.length) {
      return Object.keys(data[0])
    }
  }

  onTitleDescChange(event) {
    const {id, value} = event.currentTarget
    const idName = id.split('-')[0]
    const newValue = parseInt(value, 10)
    const arr = this[idName]
    const arrLen = arr.length
    if (newValue !== 0 && arrLen === 2) {
      arr.pop()
    }
    if (arrLen < 2) {
      arr.push(newValue)
    }
    this[idName] = arr
  }

  setTitleDesc(selector, field) {
    const copiedTitles = []
    const data = this.program[field]
    const elSelector = 'input[name="' + selector + '"]:checked'
    ;(document.querySelectorAll(elSelector) as any).forEach((el) => {
      const valueLen = parseInt(el.value, 10)
      const title = data.find(
        (d) => d.length === valueLen || d.size === valueLen
      )
      copiedTitles.push(title)
    })
    return copiedTitles
  }

  onSeasonChange() {
    this.programService
      .getSeasonsAndEpisodes(this.program.programId, this.season)
      .subscribe((data: any) => {
        this.seasonsAndEpisodes = data.response
      })
  }

  copyToMaster() {
    this.programService.setLengthValues()
    this.selectedProvider = 'masterentity'
    const titles = this.setTitleDesc('selectedTitle', 'titles')
    const descriptions = this.setTitleDesc('selectedDesc', 'descriptions')

    const englishTitle = this.programService.getTitle(
      titles,
      'en',
      'availableTitleLenEn'
    )
    const englishShortTitle = this.programService.getShortDesc(
      titles,
      'en',
      'availableTitleLenEn'
    )
    const englishDescription = this.programService.getDesc(
      descriptions,
      'en',
      'availableDescLenEn'
    )
    const englishShortDescription = this.programService.getShortDesc(
      descriptions,
      'en',
      'availableDescLenEn'
    )

    const spanishTitle = this.programService.getTitle(
      titles,
      'es',
      'availableTitleLenEs'
    )
    const spanishShortTitle = this.programService.getShortDesc(
      titles,
      'es',
      'availableTitleLenEs'
    )
    const spanishDescription = this.programService.getDesc(
      descriptions,
      'es',
      'availableDescLenEs'
    )
    const spanishShortDescription = this.programService.getShortDesc(
      descriptions,
      'es',
      'availableDescLenEs'
    )

    this.program = {
      ...(this.programService.programDefaults() as any),
      type: this.program.type,
      programType: this.program.programType,
      titles,
      descriptions,
      englishTitle,
      englishShortTitle,
      englishDescription,
      englishShortDescription,
      spanishTitle,
      spanishShortTitle,
      spanishDescription,
      spanishShortDescription,
    }
    this.isCopyToMaster.emit(true)
  }

  deletedCastAndCrew(listName, i) {
    // get the list by name
    const list = this.program[listName]

    if (list) {
      // removes the item from the list
      list.splice(i, 1)

      // re-order the items from the list
      if (list && list.length > 0) {
        list.forEach((credit, index) => {
          credit.ord = index + 1;
        })
      }

      // update the credits
      this.program.credits = [
        ...this.program.groupedCrew,
        ...this.program.groupedCast
      ]
    }
  }

  showOtherInput(value, field) {
    if (field === 'type') {
      this.isTypeInputHidden = true
      this.otherType = ''
      if (value === 'Others') {
        this.isTypeInputHidden = false
      }
    } else if (field === 'medium') {
      this.isMediumInputHidden = true
      this.otherMedium = ''
      if (value === 'Others') {
        this.isMediumInputHidden = false
      }
    } else if (field === 'role') {
      this.isRoleInputHidden = true
      this.otherRole = ''
      if (value === 'Others') {
        this.isRoleInputHidden = false
      }
    }
  }

  pickImage(castAndCrew) {
    let input = document.createElement('input')
    input.type = 'file'
    input.onchange = (_) => {
      // you can use this method to get file and perform respective operations
      let files = Array.from(input.files)
      if (files.length > 0) {
        castAndCrew.imageFile = files[0]
        var reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onload = (_event) => {
          castAndCrew.imageURL = reader.result
        }
      }
    }
    input.click()
  }

  recipientOnChange() {
    if (this.selectedRecipient != null) {
      this.award.recipientId = this.selectedRecipient['personId']
      this.award.recipient = this.selectedRecipient['castName']
    } else {
      this.award.recipientId = ''
      this.award.recipient = ''
    }
  }

  categoryOnChange() {
    if (this.selectedCategory != null) {
      this.award.categoryId = this.selectedCategory['categoryId']
      this.award.category =
        this.selectedCategory['categoryName'] != 'Others'
          ? this.selectedCategory['categoryName']
          : ''
    } else {
      this.award.categoryId = ''
      this.award.category = ''
    }
  }

  changeListRoles(type) {
    if (type === 'cast') {
      this.listRole = this.programService.getRoleCast()
    } else {
      this.castOrCrew.role = this.programService.getRoleCrew()[1]
      this.listRole = this.programService.getRoleCrew()
    }
  }

  publishedImage(image) {
    image.published = true

    if (!this.program.publishedImages) {
      this.program.publishedImages = [];
    }

    const currentPublishedIndex = this.program.publishedImages.findIndex(
      ({ratio}) => ratio === image.ratio
    )
    currentPublishedIndex >= 0
      ? (this.program.publishedImages[currentPublishedIndex] = image)
      : this.program.publishedImages.push(image)
    this.program.programImages[image.ratio] = (
      this.program.programImages[image.ratio] as any
    ).map((each) => {

      return {
        ...each,
        published: this.isPublishedImage(each, image)
      }
    })

    if (image.id) {
      const alreadyPub = this.program.images.find(({ratio, id}) => ratio === image.ratio && id !== null)
      if (alreadyPub) {
        // VLS-2236: make sure new image always saved to db
        this.program.images = this.program.images.filter(({ratio, id}) => ratio !== image.ratio || id === "")
      }
      this.program.images.push(image)
    }
  }

  deleteImage(image, index) {
    // remove from image list
    this.program.programImages[image.ratio].splice(index, 1);

    const i = this.program.images.findIndex(img => {
      return img.imageFile && img.imageFile.name == image.imageFile.name
    })
    // remove from to save images
    this.program.images.splice(i, 1);

    if (!this.program.publishedImages) {
      this.program.publishedImages = [];
    }
    const currentPublishedIndex = this.program.publishedImages.findIndex(
      ({ratio}) => ratio === image.ratio
    )
    const pubImg = this.program?.publishedImages[currentPublishedIndex]?.imageFile;
    if (pubImg && pubImg.name == image.imageFile.name) {
      //remove from published image
      this.program.publishedImages.splice(currentPublishedIndex, 1);
    }

  }

  isPublishedImage(storedImage, selectedImage) {
    const storedImageName = storedImage.imageFile?.name ? storedImage.imageFile?.name : storedImage.id;
    const selectedImagename = selectedImage.imageFile?.name ? selectedImage.imageFile?.name : selectedImage.id;

    return storedImage.id == selectedImage.id && storedImageName == selectedImagename;
  }

  onImageError(event) {
    event.target.src = this.previewUrl;
  }

  /**
   * VLS-2215 functionality to hide and unhide input fields
   * @param field
   */
  showHideInformation(field: string) {
    if(field){
      this.program[field] = !this.program[field]
    }
  }

  /**
   * Alternate Episode & Alternate Season Computed
   * @return episodeAltMapping
   */
  get episodeAltMappingComputed(): episodeAltMapping {
    if (this.program?.episodeAltMappings && this.program.episodeAltMappings.length > 0) {
      return this.program.episodeAltMappings[0]
    }

    return {
      channelId: null,
      episodeNumber: null,
      providerId: null,
      region: null,
      regionWide: null,
      seasonNumber: null,
    };
  }

  getSystems() {
    this.systems = this.programService.getSystems('programs') as any
  }

  onSystemChange(event) {
    this.refNames = this.systems.find((system) => {
      return system.system === event.target.value
    }).refName
    if (this.refNames) {
      this.externalRef.refName = this.refNames[0]
    }
  }

  async parentProgramIdOnChange() {
    let response = null
    if (this.program.parentProgramId?.length == 14 && this.program.parentProgramId?.startsWith(this.CONSTANTS.EPISODE)) {
      response = await this.getSportsInfoByProgramId(this.program.parentProgramId)
    }
    this.program.sportsInfo = response?.sportsInfo
    this.program.highLightExternalRefs?.forEach((item) => {
      if (!response?.externalRefs?.find(other => item.system == other.system && item.refName == other.refName && item.id == other.id)) {
        response?.externalRefs?.push(item)
      }
    })
    this.program.externalRefs = response?.externalRefs ? response?.externalRefs : this.program.highLightExternalRefs
    this.program.sportsInfo = this.buildSportsInfoForHighLight() as SportsInfo
    this.program.isWorldCup = response?.isWorldCup
    let venueNames;
    if (response?.sportsInfo?.venue) {
      venueNames = response?.sportsInfo?.venue.names || []
    }

    this.program.homeTeam = {
      id: '',
      team: '',
      isHome: true
    }

    this.program.awayTeam = {
      id: '',
      team: '',
      isHome: false
    }

    if (response?.isWorldCup && response?.sportsInfo?.match?.teams) {
      this.getTeamInfo(response?.sportsInfo?.match.teams)
    }
    if (response?.sportsInfo?.teams && !response?.isWorldCup) {
      this.getTeamInfo(response?.sportsInfo?.teams)
    }

    this.program.isEnVenueHide = true
    this.program.isEsVenueHide = true
    this.program.isPtVenueHide = true

    if (this.program.sportsInfo) {
      const matchId = this.program.sportsInfo?.matchId

      this.teamService.getTeamsByMatchId(matchId).subscribe((data) => {
        if (data.response) {
          this.buildSeasonTeams(data.response)
        } else {
          this.seasonTeams = []
        }
      })

      if (matchId != '' && matchId) {
        this.programService.getStagesByMatchId(matchId).subscribe((data) => {
          if (data.response && data.response.length > 0) {
            this.buildStages(data.response)
          } else {
            this.stages = []
          }
        })
      }
    }

    let venueValue
    if (!this.program.sportsInfo?.venue?.value) {
      if (!this.isSky && this.program.sportsInfo?.venue?.names) {
        venueValue = this.program.sportsInfo.venue.names.find(name =>
          name.lang === this.CONSTANTS.ES || name.lang === this.CONSTANTS.EN
        )
      }

      if (this.isSky && this.program.sportsInfo?.venue?.names) {
        venueValue = this.program.sportsInfo.venue.names.find(name =>
          name.lang === this.CONSTANTS.PT_BR || name.lang === this.CONSTANTS.PT || name.lang === this.CONSTANTS.EN
        )
      }
    }

    if (venueValue) {
      this.program.sportsInfo.venue.value = venueValue.value
    }
  }

  async getSportsInfoByProgramId(programId: string) {
    const dataVersions = await this.programService.getProgramVersions(programId).toPromise()
    if (dataVersions?.response) {
      const publishedVersion = dataVersions.response?.masterEntity?.filter((version) => version?.published === true)[0]
      if (publishedVersion?.id) {
        const dataVersion = await this.programService.getProgramByVersion(publishedVersion?.id, this.CONSTANTS.MASTERENTITY_PROVIDER).toPromise()
        return dataVersion?.response ? dataVersion?.response : null
      }
    }
    return null
  }

  buildSportsInfoForHighLight() {
    return {
      gameDate: this.program.sportsInfo?.gameDate || null,
      gameTime: this.program.sportsInfo?.gameTime || null,
      gameTimezone: this.program.sportsInfo?.gameTimezone || null,
      playoffRound: this.program.sportsInfo?.playoffRound || null,
      playoffRoundId: this.program.sportsInfo?.playoffRoundId || null,
      gameNumber: this.program.sportsInfo?.gameNumber || null,
      sportSeason: {
        type: this.program.sportsInfo?.sportSeason?.type || null,
        value: this.program.sportsInfo?.sportSeason?.value || null
      },
      venue: this.program.sportsInfo?.venue || {id: '', names: [], value: ''},
      matchId: this.program.sportsInfo?.matchId || null,
      teams: this.program.sportsInfo?.teams || null,
      groupName: this.program.sportsInfo?.groupName || null
    }
  }

  getTeamInfo(teams = []) {
    this.program.homeTeam = teams?.find((team) => {
      return team.isHome === true
    })

    this.program.awayTeam = teams?.find((team) => {
      return team.isHome === false
    })

    if (teams.length >= 1 && !this.program.homeTeam && teams[0]?.isHome === null) {
      this.program.homeTeam = teams[0]
    }

    if (teams.length >= 1 && teams[1]?.isHome === null && !this.program.awayTeam) {
      this.program.awayTeam = teams[1]
    }

    if (!this.program.homeTeam) {
      this.program.homeTeam = {team: ''}
    }

    if (!this.program.awayTeam) {
      this.program.awayTeam = {team: ''}
    }
  }

  buildVenueName(program: Program, lang: string) {
    lang = lang.split('-')[0]
    if (program.sportsInfo?.venue) {
      return program.sportsInfo.venue.names?.filter(name => name.lang === lang).map(({value}) => value).join(',')
    }
  }

  onPlayoffRoundChange(event) {
    if (event && this.program.sportsInfo) {
      if (event.playoffRoundId) {
        this.program.sportsInfo.playoffRoundId = event.playoffRoundId
      }

      if (event.playoffRound) {
        this.program.sportsInfo.playoffRound = event.playoffRound
      }

      this.program.sportsInfo.groupName = event.groupName || ''
    }
  }

  buildSeasonTeams(teams) {
    const teamList = []
    teams.forEach((team) => {
      if (!this.isSky) {
        const esTeam = (team.names || []).find(
          (g) => g.lang === this.CONSTANTS.ES || g.lang === this.CONSTANTS.EN
        )
        if (esTeam && esTeam.value != '') {
          teamList.push({
            id: team.id,
            team: esTeam.value,
          })
        }
      }
      if (this.isSky) {
        const ptTeam = (team.names || []).find(
          (g) => g.lang === this.CONSTANTS.PT || g.lang === this.CONSTANTS.PT_BR || g.lang === this.CONSTANTS.ES
        )
        if (ptTeam && ptTeam.value != '') {
          teamList.push({
            id: team.id,
            team: ptTeam.value,
          })
        }
      }
    })
    this.seasonTeams = teamList
  }

  buildStages(stages) {
    this.stages = []
    stages.forEach(obj => {
      if (obj.hasOwnProperty('stageName')) {
        this.stages.push({
          playoffRoundId: obj.id,
          playoffRound: obj.stageName,
          groupName: obj.groupName
        })
      }
    })
  }

  onTCSActionCheck(field: string, event: Event) {
    const checkbox = event.target as HTMLInputElement;
    // Display popup when user click on checkbox
    if (field === "publishParent") {
      const modal = this.confirmModalService.createModal({
        title: "Confirm",
        message: this.program.dthInfo[field] == true ? AppConstants.UNCHECK_PARENT_INFO_MESSAGE : AppConstants.USE_PARENT_INFO_MESSAGE,
        isConfirm: true,
        isAlert: false
      })
      modal.result.then((result) => {
        if (result) {
          // Change publishParent value
          this.program.dthInfo[field] = checkbox.checked
        }else {
          // If not confirmed, revert the checkbox state
          checkbox.checked = this.program.dthInfo[field]
        }
      })
    }else{
      this.program.dthInfo[field] = !this.program.dthInfo[field]
    }
  }

  isDthPlatform(){
    return this.platformService.verifyPlatform(AppConstants.DTH)
  }
}
