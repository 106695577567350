<div class="user-roles">
    <div class="row no-gutters">
      <div class="col-3 p-1">
        <div class="panel-header roles-header">
          <div class="d-flex justify-content-start">
            <h6>Roles</h6>
          </div>
        </div>
        <div class="panel-body">
          <div
            *ngFor="let role of roles; let i = index"
            class="d-flex justify-content-between p-1"
          >
            <div
              id="roleItem-{{ i }}"
              class="role-item d-block"
              (click)="onItemClick(role)"
              [class.active]="role.id === selectedRole.id"
            >
              <div class="program-name d-flex">
                <strong class="text-truncate">
                  {{ role.name }}
                </strong>
              </div>
              <div class="text-muted text-truncate">
                {{ role.label || 'No description' }}
              </div>
            </div>
          </div>
        </div>
        <div class="panel-footer mb-2 d-flex justify-content-between p-1">
          <button
            class="btn btn-sm btn-att ml-auto"
            (click)="addNewRole(roleModal, 'role')"
          >
            Add Role
          </button>
        </div>
      </div>
      <div class="col p-1">
        <div class="panel-header d-flex">
          <div class="d-flex justify-content-start">
            <h6>{{ selectedRole.label }} Permission</h6>
          </div>
        </div>
        <div class="panel-body">
          <div class="pages-list p-1">
            <table class="table table-striped">
              <thead class="bg-dark">
                <tr>
                  <th scope="col" >
                  </th>
                  <th scope="col" *ngFor="let action of userActions">
                    {{ action.name }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container>
                  <tr class="cursor-pointer" *ngFor="let page of selectedRole?.accessPage">
                    <td>{{ page.name }}</td>
                    <td *ngFor="let action of userActions">
                      <input type="checkbox" [checked]="page?.actions?.indexOf(action.name) >= 0"/>
                    </td>
          
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        <div class="panel-footer mb-2 d-flex justify-content-between p-1">
          <button
            class="btn btn-sm btn-att ml-auto"
            (click)="addNewRole(roleModal, 'sitePage')"
          >
            Add Site Page
          </button>
        </div>
      </div>
    </div>
  </div>
  <ng-template #roleModal>
    <div class="modal-header">
      <h4 class="modal-title">{{ modal.headerName }}</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="closeModal()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Name</label>
            <input
              autocomplete="off"
              id="name"
              type="text"
              name="name"
              [(ngModel)]="modal.name"
              class="form-control"
              required
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label>Description</label>
            <input
              autocomplete="off"
              id="description"
              type="text"
              name="description"
              [(ngModel)]="modal.description"
              class="form-control"
              required
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <label>{{ modal.selectName }}</label>
          <ng-select
            [items]="modal.selectItems"
            [multiple]="true"
            [hideSelected]="true"
            [closeOnSelect]="true"
            [searchable]="true"
            [(ngModel)]="modal.accessPage"
            bindLabel="name"
          >
          </ng-select>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        (click)="submit()"
        [disabled]="showLoader"
        class="btn btn-sm btn-att"
      >
        <i *ngIf="showLoader" class="fas fa-spinner fa-spin"></i> Save
      </button>
    </div>
  </ng-template>