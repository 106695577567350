<div class="version-panel">
  <div class="panel-header">
    <div class="d-flex justify-content-start">
      <h6>{{ 'vls.versions.title' | translate }}</h6>
    </div>
  </div>
  <div class="panel-body version-wrapper">
    <ng-container *ngIf="versionsLoadingSubject|async">
      <div class="loading">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-container>
    <div class="{{!(versionsLoadingSubject|async)? '':'d-none'}}">
      <ngb-accordion #acc="ngbAccordion" activeIds="{{['First schedule', 'Second schedule']}}">
        <ng-container *ngFor="let schedule of schedulesSubject|async; let scheduleIndex = index">
          <ngb-panel id="{{scheduleIndex===0?'First schedule': 'Second schedule'}}" *ngIf="schedule.versions?.length">
            <ng-template ngbPanelHeader>
              <div
                class="header-panel-schedule d-flex justify-content-start {{scheduleIndex===0? 'header-panel-1st-schedule':'header-panel-2nd-schedule'}}">
                <button ngbPanelToggle class="btn">
                  {{(scheduleIndex === 0 ? 'First schedule' : 'Second schedule') + ' ' + (requestSubject.getValue()?.timezone ? requestSubject.getValue()?.timezone?.key?.split(' ')[1] : '')}}
                </button>
                <i class="pointer fa {{schedule.contentLock ? 'fa-lock' : 'fa-unlock'}} ml-auto mr-3 text-white"
                   (click)="lockContent(schedule)"
                > </i>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="version-item d-flex" [class.active]="isSelectedVersion(version.id)"
                   *ngFor="let version of schedule.versions"
                   (click)="onSelectVersion(schedule, version)">
                <div container="body" ngbTooltip="Updated: {{version?.updatedDate}}">
                  Version {{ version.version }}
                </div>
                <div class="version-status ml-auto d-flex active">
                <span *ngIf="version.provider" class="ml-1 badge badge-info">
                  {{ version.provider === 'GRACENOTE' ? 'TMS' : version.provider}}
                </span>
                  <span *ngIf="schedule.scheduledPublishedVersion === version.version"
                        class="ml-1 badge badge-success">
                  PUB
                </span>
                  <span *ngIf="!version.published && version.provider !== 'GRACENOTE'"
                        class="ml-1 badge badge-warning">
                  {{version.status === 'NEW' ? 'NEW' : 'SAVED'}}
                </span>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ng-container>
      </ngb-accordion>
    </div>
  </div>
  <div class="panel-footer mb-2 d-flex justify-content-end"></div>
</div>
