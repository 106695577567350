<div class="row schedule-event">
  <div class="col-12" *ngIf="event?.shouldDisplay(selectedTimeZone, selectedDate)">
    <div class="d-flex justify-content-start mb-1"
         cdkDrag
         cdkDragLockAxis="y">
      <div class="custom-placeholder" *cdkDragPlaceholder></div>
      <div id="{{event.displayId(index)}}"
           [class.box-shadowed]="true"
           class="{{eventClass()}}">
        <div class="row">
          <div class="col-8 d-flex justify-content-start">
            <div class="event-time d-flex ml-1 mt-1" id="schedule-eventTime-{{event.formattedStartDate()}}">
              <ng-container [ngTemplateOutlet]="scheduleTimeTemplate"
                            [ngTemplateOutletContext]="{disabled: false, timezone: selectedTimeZone.value, duration: event.durationInMinute()}">
              </ng-container>
              <ng-container [ngTemplateOutlet]="scheduleTimeTemplate" *ngIf="selectedTimeZone.value!=='GMT'"
                            [ngTemplateOutletContext]="{disabled: true, timezone: 'GMT', duration: event.durationInMinute()}">
              </ng-container>
              <ng-template #scheduleTimeTemplate let-timezone="timezone" let-disabled="disabled" let-duration="duration">
                <div class="d-flex justify-content-start start-end-date">
                  <input type="text" inputmode="numeric" pattern="[0-9]*" maxlength="2"
                         id="hour-{{event.displayId(index)}}"
                         class="form-control input-time"
                         value="{{event.hour(timezone)}}"
                         [disabled]="disabled"
                         (keyup)="hourUpdate.next($event)"/>
                  <span class="minute-divider">:</span>
                  <input type="text" inputmode="numeric" pattern="[0-9]*" maxlength="2"
                         id="min-{{event.displayId(index)}}"
                         class="form-control input-time"
                         value="{{event.min()}}"
                         [disabled]="disabled"
                         (keyup)="minuteUpdate.next($event)"/>
                  <input type="text" value="Min" class="form-control input-time input-min" disabled/>
                  <input type="text" inputmode="numeric" pattern="[0-9]*" maxlength="3"
                         id="duration-{{event.displayId(index)}}"
                         class="form-control input-time input-duration"
                         value="{{duration}}"
                         [disabled]="disabled"
                         (keyup)="durationUpdate.next($event)"/>
                </div>
              </ng-template>
            </div>
            <div container="body"
                 ngbTooltip="{{'vls.schedules.tooltips.clickMoreInfo' | translate}}"
                 class="d-flex event-title mt-1 ml-1"
                 (click)="isCollapsed = !isCollapsed"
                 cdkDragHandle>
              <strong>{{getTitleDescValue(event.programDetails, 'titles')}}</strong>
            </div>
          </div>
          <div class="col-4 d-flex justify-content-start mt-1">
            <div class="ml-auto d-flex action-icons mt-1">
              <span *ngIf="event.programDetails && event.publishParent" class="sh-info badge badge-secondary">
                Using SH info
              </span>
              <span *ngIf="event.programDetails && event.programDetails?.seasonNumber" class="badge badge-light mr-1">
                sN: {{ event.programDetails?.seasonNumber }}
              </span>
              <span *ngIf="event.programDetails && event.programDetails?.episodeNumber" class="badge badge-light mr-1">
                eN: {{ event.programDetails?.episodeNumber }}
              </span>
              <div [className]="'ml-1 badge badge-' +getProgramTypeStyle(event.programDetails?.programType)"
                   ngbTooltip="{{event.programDetails?.programType}}"
                   container="body">
                {{ programTypeBadge(event.programDetails?.programType)}}
              </div>
              <i container="body" ngbTooltip="{{'vls.schedules.tooltips.clone' | translate}}"
                 (click)="onCloningSchedule()" class="far fa-clone ml-3">
              </i>
              <i container="body" ngbTooltip="{{'vls.schedules.tooltips.remove' | translate}}"
                 class="far fa-window-close ml-3" (click)="onDeletingEvent()"></i>
              <input class="remove-schedule ml-3 mr-2" type="checkbox"
                     id="checkbox-{{event.formattedStartDate('GMT','YYYMMMDDHHmmss')}}"
                     (change)="onEventChecked($event.target.checked, event)"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex justify-content-start chips mt-1 ml-1">
            <div *ngIf="selectedTimeZone.value!=='GMT'">
              <span 
                class="start-date badge badge-secondary{{event.blackout ? '' : '-outlined'}}">
                {{ event.formattedEndDate(selectedTimeZone.value) }}
              </span>
            </div>
            <div>
              <span class="start-date badge badge-secondary{{event.blackout ? '' : '-outlined'}}">{{ event.formattedEndDate() }}
              </span>
            </div>
            <div class="d-flex event-desc">
              <div class="ratings d-flex align-items-baseline">
                <span class="font-weight-bold">{{'vls.programs.form.fields.ratings' | translate}}:</span>
                <div>
                  <ng-container *ngIf="event.ratings && event.ratings.length">
                    <div class="ml-1 badge badge-success{{event.blackout ? '' : '-outlined'}}"
                         *ngFor="let rating of event.displayRatings(ratings)">
                      {{ rating }}
                    </div>
                  </ng-container>
                  <span *ngIf="!event.displayRatings(ratings)?.length"><em>&nbsp;---</em></span>
                </div>
              </div>
              <div class="ml-1 qualifiers d-flex align-items-baseline">
                <span class="font-weight-bold">{{'vls.programs.form.fields.qualifiers'|translate}}:</span>
                <div>
                  <ng-container *ngIf="event.displayQualifiers()">
                    <div class="ml-1 badge badge-secondary{{event.blackout ? '' : '-outlined'}}"
                         *ngFor="let qualifier of event.displayQualifiers()">
                      {{ qualifier }}
                    </div>
                  </ng-container>
                  <span *ngIf="!event.displayQualifiers()?.length"> <em>&nbsp;---</em></span>
                </div>
              </div>
              <div class="ml-1 d-flex" *ngIf="event.blackout">
                <span class="font-weight-bold">Blackout:</span>
                <span class="ml-1 badge badge-secondary{{event.blackout ? '' : '-outlined'}}"
                      *ngFor="let region of event.displayRegions(regions)">
                  {{ region }}
                </span>
                <span *ngIf="event.labels && event.labels?.length"
                      class="ml-1 badge badge-danger">Mobile Blackout</span>
              </div>
            </div>
          </div>
        </div>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="more-info mt-2 mb-1">
          <div class="row">
            <div class="col d-flex">
              <div class="program-image">
                <img [src]="getProgramImage()" alt="" (error)="onImageError($event)"/>
              </div>
              <div class="ml-2 program-info">
                <div class="row">
                  <div class="col-6">
                    <strong>{{'vls.programs.form.fields.showTitle' | translate}}: </strong>
                    <p>
                      {{getParentTitle(event)}}
                    <p>
                  </div>
                  <div class="col-6">
                    <strong> Program ID: </strong>
                    <p><em>{{ event.programDetails?.programId }}</em></p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <strong>{{'vls.programs.form.fields.description' | translate}}:</strong>
                    <p><em>{{getTitleDescValue(event.programDetails, 'descriptions', false)}}</em></p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <strong class="label">{{'vls.programs.form.fields.releaseYear' | translate}}:</strong>
                    <p *ngIf="event && event.programDetails">
                      <em>{{event.programDetails?.releaseYear || event.programDetails?.origAirDate || 'N/A'}}</em>
                    </p>
                  </div>
                  <div class="col-4">
                    <strong>{{'vls.programs.form.fields.genres' | translate}}:</strong>
                    <p><em>{{getGenresNames(event)}}</em></p>
                  </div>
                  <div class="col-4 d-flex justify-content-end">
                    <a class="btn btn-sm btn-att btn-more-info mr-2" [class.disabled] = "!event.programDetails?.programId "
                       target="_blank"
                       href="/programs/{{ event.programDetails?.programId }}">
                      {{'vls.schedules.form.buttons.moreInfo' | translate}}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
