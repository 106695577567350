<div class="venues-panel">
  <div class="panel-header venues-header">
    <div class="d-flex justify-content-start">
      <h6>Venues</h6>
    </div>
    <div class="venues-search input-group mt-1">
      <input
        type="search"
        class="form-control"
        placeholder="Search venues"
        [(ngModel)]="searchString"
        (ngModelChange)="searchChange($event, scrollVenues)"
        (keyup)="searchKeyup($event, scrollVenues)"
      />
      <div class="input-group-append">
        <span class="input-group-text">
          <i class="fa fa-search"></i>
        </span>
      </div>
    </div>
  </div>
  <div
    #scrollVenues
    class="panel-body venues-wrapper"
    cdkDropList
    id="venueList"
    (scroll)="onScroll($event)"
  >
    <ng-container *ngIf="showLoader">
      <div class="loading">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-container>
    <div
      *ngFor="let venue of venues; let i = index"
      cdkDrag
      [cdkDragData]="venue"
      (cdkDragStarted)="onDragStart($event, i)"
      class="d-flex justify-content-between p-1 flex-column"
    >
      <div
        id="venueItem-{{ i }}"
        class="venues-item d-block"
        (click)="viewVenue(venue.id)"
        [class.active]="venue.id === selectedVenueId"
      >
        <div class="venue-name d-flex">
          <strong class="text-truncate">
            {{ getName(venue.names) || CONSTANTS.NO_VENUE_NAME }}
          </strong>
        </div>
        <div class="venue-name d-flex" style="align-items: center">
          <div class="text-muted text-truncate mr-1">
            City: {{ getName(venue.cities) || CONSTANTS.NO_CITY }}
          </div>
          <div class="text-muted text-truncate mr-3">
            ({{ getCountry(venue.countryCode) || CONSTANTS.NO_COUNTRY }})
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-footer mb-2 d-flex justify-content-end">
    <div class="btn-group mr-2 p-1">
      <button class="btn btn-att py-0 px-2" (click)="createNewVenue()">
        Create Venue
      </button>
    </div>
  </div>
</div>
