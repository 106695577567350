export const AppConstants = {
    MASTERENTITY_PROVIDER: 'masterentity',
    // channel platform
    OTT: 'ott',
    DTH: 'dth',
    // ratio
    RATIO_16x9: '16:9',
    RATIO_2x3: '2:3',
    // resized image
    WIDTH_RATIO_16x9: '255',
    HEIGHT_RATIO_16x9: '143',
    WIDTH_RESIZE_RATIO_2x3: '200',
    HEIGHT_RESIZE_RATIO_2x3: '300',
    WIDTH_DIMENSION_RATIO_2x3: '240',
    HEIGHT_DIMENSION_RATIO_2x3: '360',
    // language
    INSENSITIVE_EN: 'english',
    INSENSITIVE_ES: 'spanish',
    INSENSITIVE_PT: 'portuguese',
    ENGLISH: 'English',
    PORTUGUESE: 'Portuguese',
    SPANISH: 'Spanish',
    PT: 'pt',
    ES: 'es',
    EN: 'en',
    PT_BR: 'pt-BR',
    // program type
    MOVIE: 'MV',
    SERIES: 'SH',
    SHOW: 'SV',
    SPORT_SERIES: 'SP',
    EPISODE: 'EP',
    SPORT_EPISODE: 'SE',
    // bulk copy
    MIN_OF_COPY: 1,
    MAX_OF_COPY: 30,
    // full program type
    FULL_EPISODE: 'EPISODE',
    // title constant
    TITLES: 'titles',
    PARENT_TITLES: 'parentTitle',
    EPISODE_TITLES: 'episodeTitles',
    // others
    NO_EPISODE_TITLE: 'No Episode Title',
    //field name constants Bulk Copy
    NUMBER_OF_COPY: 'numOfCopies',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    ORIGINAL_CHANEL_NAME: 'origChannelNames',
    ORIGINAL_CHANEL_ID: 'origChannelId',
    DESTINATION_CHANEL_ID: 'destChannelId',
    DESTINATION_CHANEL_NAME: 'destChannelNames',
    DESTINATION_START_DATE: 'destStartDate',
    PUBLISHED: 'published',
    //Message Constants Bulk Copy
    MESSAGE_INVALID_START_DATE: 'Start date must be before end date',
    MESSAGE_INVALID_END_DATE: 'End date must be after start date',
    INVALID_FIELD_INPUT: 'is-invalid',
    SUBMIT_CHANGES: 'Submit Changes',
    CONFIRMATION_CHANGES: 'Confirmation Changes',
    MESSAGE_CONFIRM: 'Are you sure you want to continue?',
    MESSAGE_CHANGE_PARENT_CONFIRM: 'You are about to change the parent game linking. Are you sure you want to continue?',
    MESSAGE_ERROR: 'Submission Failed',
    MESSAGE_SUCCESS: 'Schedules Copy successfully saved',
    // Player
    GENDERS: ['Male', 'Female'],
    TYPES: ['Person', 'Animal', 'Group'],
    PLAYER_TYPES: ['player', 'referee', 'coach', 'staff', 'assistant coach'],
    STATUS: ['active', 'retired', 'died'],
    FOOT: ['left', 'right', 'both'],
    POSITIONS: ['Goalkeeper', 'Defender', 'Midfielder', 'Attacker', 'Coach'],
    OPTA_PLAYERID: 'optaPlayerId',
    NO_TEAM_PLAYER_NAME: 'Unknown Team',
    NO_PLAYER_NAME: 'No Player Name',
    PLAYER_ID_PREFIX: 'PS',
    DATE_FORMAT: 'YYYY-MM-DD',
    // Venues
    OPTA: 'opta',
    OPTA_VENUEID: 'optaVenueId',
    NO_VENUE_NAME: 'No Venue Name',
    //IMAGE CONSTANTS
    IMAGE_BANNER: 'assets/images/noImage.jpg',
    NO_CITY: 'No City',
    NO_COUNTRY: 'No Country',
    BASE_URL: 'http://tmsimg.com/',
    //API error
    PARENT_NOT_FOUND: 'The parentProgramId does not exist.',
    //Messages <-> API error
    MESSAGE_PARENT_NOT_FOUND: 'The parent program $1 does not exist.',
    //field name constants ECAD
    MONTH_PERIOD: 'monthPeriod',
    // APG Report
    GO: 'GO',
    EPO: 'EPO',
    BIO: 'BIO',
    // Message Schedule
    MESSAGE_SLAVE_CHANNEL_MODAL: 'This channel is a slave of channel sourceId. Please go to channel sourceId to view the schedules!',
    WARNING: 'Warning',
    SUCCESS: 'Success',
    ERROR: 'Error',
    MESSAGE_PROCESS_FILE_SUCCESS: 'File is processed successfully.',
    MESSAGE_PROCESS_FILE_FAIL: 'Cannot connect to APG right now',
    MESSAGE_LINK_SLAVE_MODAL: 'Do you want to link these channels?',
    ALERT: 'Alert',
    SUBMIT_REQUEST: 'Submit request',
    MESSAGE_LINKED_SLAVE_SUCCESS: 'Linked slave channel to master channel successfully!', 
    //environments
    VALID_QUALIFIERS: 'validQualifiers',
    LANG_FILTERS: 'langFilters',
    //Platform
    PLATFORM: 'platform',
    ACCESS_DENIED_MESSAGE: 'Program mapping screen is not available for DTH',
    IMAGE_DENIED_MESSAGE: "In this aspect ratio, the size of image is incorrect, please resize image before uploading!",
    //TCS Config
    USE_EPISODE_TITLE: "Use Episode Title",
    USE_SERIES_INFO: "Use Series Info",
    DEFAULT_LANG: 'defaultLang',
    USE_PARENT_INFO_MESSAGE: "Our application will send SH information instead for all episodes. Would you like to proceed?",
    UNCHECK_PARENT_INFO_MESSAGE: "Our application will send the Episode information instead of SH information. Would you like to proceed?",
    // Use for program validation
    TEXT_GAME: 'game',
    PROGRAM_VALIDATION_MAPPING: {
        'originalAudiolang': 'Original Audio',
        'spanishEpisodeTitle': 'Spanish Episode Title',
        'englishEpisodeTitle': 'English Episode Title',
        'portugueseEpisodeTitle': 'Portuguese Episode Title',
        'otherEpisodeTitle': 'Other Episode Title',
        'spanishTitle': 'Spanish Title',
        'englishTitle': 'English Title',
        'portugueseTitle': 'Portuguese Title',
        'otherTitle': 'Other Title',
        'origAirDate': 'Original Air Date',
        'releaseYear': 'Release Year',
        'gameDate': 'Game Date',
        'gameTime': 'Game Time'
    },
    // Asset tab id
    ASSET_STATS: 'asset-stats',
    ALL: 'Select All',
    // Asset url constant
    WIDE_VINE : 'Widevine',
    PLAY_READY: 'Playready',
    FAIR_PLAY: 'Fairplay',
    //Program type name
    MOVIE_NAME: 'Movie',
    SERIES_NAME: 'Series',
    SHOW_NAME: 'Show',
    SPORT_SERIES_NAME: 'Sport',
    EPISODE_NAME: 'Episode',
    SPORT_EPISODE_NAME: 'Episode',
    //Program subtype
    EPISODE_SUBTYPE: 'Series Episode',
    SPORT_SERIES_SUBTYPE: 'Sport Series',
    SPORT_EPISODE_SUBTYPE: 'Sport Episode',
    //Rating type
    MPAA_RATING: 'MPAA',
    TVPG_RATING: 'TVPG',
    DJCTQ_RATING: 'DJCTQ',
    //Rating list
    MPAA_RATING_LIST: ['PG-13', 'TV14', 'R', 'N-17'],
    PARENTAL_RATING_LIST: ['G', 'TVG', 'TVPG', 'TVMA'],
    BR_RATING_LIST: ['L', '10', '12', '14', '16', '18'],
    //Provider
    GRACENOTE_PROVIDER: 'gracenote',
    BLIM_PROVIDER: 'blim',
    GN: 'GN',
    VLS: 'VLS',
    DMS: 'DMS',
    //Asset status
    UNPUBLISHED: 'Unpublished',
    NOT_AVAILABLE: 'Not Available',
    //Image preview url
    IMAGE_PREVIEW_URL: 'assets/images/noImage.jpg',
    //Date time type
    DATETIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
    //Assets report columns
    TYPE_COLUMN: 'Type',
    NAME_COLUMN: 'Name',
    SHOW_NAME_COLUMN: 'Show Name',
    SEASON_NUMBER_COLUMN: 'S#',
    EPISODE_NUMBER_COLUMN: 'E#',
    GENRE_COLUMN: 'Genre',
    PROVIDER_COLUMN: 'Provider',
    CATEGORY_COLUMN: 'Category',
    CHANNEL_COLUMN: 'Channel',
    PROGRAM_MAPPER_ID_COLUMN: 'Program Mapper ID',
    VRIO_ID_COLUMN: 'Vrio ID',
    PROGRAM_ID_COLUMN: 'Program ID',
    DMS_ID_COLUMN: 'DMS ID',
    TMS_ID_COLUMN: 'TMS ID',
    SERIES_TMS_ID_COLUMN: 'Series TMS ID',
    ASSET_ID_COLUMN: 'Asset ID',
    REGION_COLUMN: 'Region',
    PUB_STATUS_COLUMN: 'Pub Status',
    CREATED_DATE_COLUMN: 'Created Date',
    LICENSE_START_COLUMN: 'License Start',
    LICENSE_END_COLUMN: 'License End',
    // Assets report tab label
    ALL_ASSETS_LABEL: 'All Assets',
    DUPLICATE_ASSETS_LABEL: 'Duplicate Assets',
    ASSETS_WO_GENRES_LABEL: 'Assets Without Genres',
    ASSETS_WO_RELEASE_YEAR_LABEL: 'Assets Without Release Year',
    ASSETS_WO_URLS_LABEL: 'Assets Without URLs',
    ASSETS_WO_RATINGS_LABEL: 'Assets Without Ratings',
    ASSETS_WO_EPISODES_LABEL: 'Assets Without Episodes',
    ASSETS_WO_SHOWS_LABEL: 'Assets Without Shows',
    // Assets report tab id
    ALL_ASSETS_ID: 'ALL_ASSETS',
    DUPLICATE_ASSETS_ID: 'DUP_ASSETS',
    ASSETS_WO_GENRES_ID: 'WT_GENRES',
    ASSETS_WO_RELEASE_YEAR_ID: 'WT_RELEASE_YEAR',
    ASSETS_WO_URLS_ID: 'WT_URLS',
    ASSETS_WO_RATINGS_ID: 'WT_RATINGS',
    ASSETS_WO_EPISODES_ID: 'WT_EPISODES',
    ASSETS_WO_SHOWS_ID: 'WT_SHOWS'
};
