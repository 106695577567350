<section class="login">
  <div class="container">
    <div class="row">
      <div class="col-md-4 login-section">
        <h2>VLS</h2>
        <form class="login-form">
          <div class="alert alert-danger" *ngIf='errorMessage'>
            {{errorMessage}}
          </div>
          <div class="form-group">
            <label for="username">Username</label>
            <input
              id="username"
              type="text"
              [(ngModel)]="username"
              class="form-control login-input"
              placeholder="Enter username"
              autocomplete="off"
              required
              name="username"
            />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input
              id="password"
              type="password"
              class="form-control login-input"
              [(ngModel)]="password"
              placeholder="Enter password"
              autocomplete="off"
              name="password"
              required
            />
          </div>
          <button
            [disabled]="showLoader"
            class="btn btn-login btn-outline-dark"
            (click)="login()">
            <i *ngIf="showLoader" class="fas fa-spinner fa-spin"></i> Login
          </button>
        </form>
      </div>
      <div class="col-md-8 banner-section"></div>
    </div>
  </div>
</section>
