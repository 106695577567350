<div class="programs-panel">
  <div class="panel-header programs-header">
    <div class="d-flex justify-content-start">
      <h6>{{ 'vls.programs.pageTitle' | translate }}</h6>
      <div class="ml-auto d-flex">
        <div class="program-filters" *ngFor="let filter of programFilters">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id="{{ filter.name }}"
              [checked]="filter.value"
              (change)="filter.value = !filter.value; getParams.page = 1; getPrograms()"
            />
            <label class="form-check-label" for="{{ filter.name }}">{{
              filter.abbrev
            }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="programs-search input-group mt-1">
      <input
        type="search"
        class="form-control"
        placeholder="Search programs"
        [(ngModel)]="searchString"
        (ngModelChange)="searchChange($event)"
        (keydown.enter)="searchKeyup($event)"
      />
      <div class="input-group-append">
        <span class="input-group-text" (click)="searchPrograms()">
          <i class="fa fa-search"></i>
        </span>
      </div>
    </div>
  </div>
  <div
    class="panel-body programs-wrapper"
    cdkDropList
    id="programList"
    [cdkDropListConnectedTo]=dropList
    (scroll)="onScroll($event)"
  >
    <ng-container *ngIf="showLoader">
      <div class="loading">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-container>
    <div
      *ngFor="let program of programs; let i = index"
      cdkDrag
      [cdkDragData]="program"
      (cdkDragStarted)="onDragStart($event, i)"
      class="d-flex justify-content-between p-1 flex-column"
    >
      <div
        id="programItem-{{ i }}"
        class="programs-item d-block"
        (click)="viewProgram(program.programId)"
        (dblclick)="addProgram(program.programId)"
        [class.active]="program.programId === selectedProgramId"
      >
        <div class="program-name d-flex">
          <strong class="text-truncate">
            {{ program.name }}
            <span *ngIf="program.seriesTitle">
              | {{ program.seriesTitle }}</span
            >
            <span *ngIf="program.releaseYear || program.origAirYear">
              ({{ program.releaseYear || program.origAirYear }})</span
            >
          </strong>
          <div class="ml-auto" style="flex: none">
            <span *ngIf="program.season" class="badge badge-light mr-1">
              sN: {{ program.season }}
            </span>
            <span *ngIf="program.episode" class="badge badge-light mr-2">
              eN: {{ program.episode }}
            </span>
            <span [className]="'badge badge-' + getProgramTypeStyle(program.programType)">
              {{ program.type }}
            </span>
          </div>
        </div>
        <div class="program-name d-flex" style="align-items: center">
          <div class="text-muted text-truncate mr-3">
            {{ program.description || 'No description' }}
          </div>
          <div class="ml-auto" style="flex: none; margin-left: 10px">
            <button
              type="button"
              class="btn btn-link"
              style="padding: 0"
              *ngIf="program.type === 'SH'"
              (click)="showSeriesEpisode(program)"
            >
              <i class="fas fa-caret-down"></i>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="program.listEpisode && program.type === 'SH'">
        <div *ngFor="let seasonItem of program.groupedEpisode | keyvalue">
          <div class="d-flex flex-row">
            <div class="text-muted text-truncate mr-3 p-1" style="font-size: 14px">
              {{ program.isGroupedByStage ? 'Match date: ' : 'Season: '}}
              {{ seasonItem.key }}
            </div>
            <div class="ml-auto mr-2">
              <button type="button" class="btn btn-link" (click)="collapse.toggle()"
                [attr.aria-expanded]="!seasonItem.value.isCollapsed" aria-controls="collapseExample" style="padding: 0">
                <i class="expanded-icon fas fa-caret-down"></i>
              </button>
            </div>
          </div>
          <div #collapse="ngbCollapse" [(ngbCollapse)]="seasonItem.value.isCollapsed">
            <div *ngFor="let episode of seasonItem.value.results; let i = index" cdkDrag [cdkDragData]="episode"
              (cdkDragStarted)="onDragStart($event, i)" class="d-flex justify-content-between p-2 flex-column">
              <div id="episodeItem-{{ i }}" class="episode-series-item d-block" (click)="viewProgram(episode.programId)"
                (dblclick)="addProgram(episode.programId)" [class.active]="episode.programId === selectedProgramId">
                <div class="program-name d-flex">
                  <strong class="text-truncate">
                    {{ episode.name }}
                    <span *ngIf="episode.seriesTitle">
                      | {{ episode.seriesTitle }}</span>
                    <span *ngIf="episode.releaseYear || episode.origAirYear">
                      ({{ episode.releaseYear || episode.origAirYear }})</span>
                  </strong>
                  <div class="ml-auto" style="flex: none">
                    <span *ngIf="episode.season" class="badge badge-light mr-1">sN: {{ episode.season }}</span>
                    <span *ngIf="episode.episode" class="badge badge-light mr-2">eN: {{ episode.episode }}</span>
                    <span [className]="
                                  'badge badge-' +getProgramTypeStyle(episode.programType)
                                ">{{ episode.type }}</span>
                  </div>
                </div>
                <div class="program-name d-flex" style="align-items: center">
                  <div class="text-muted text-truncate mr-3">
                    {{ episode.description || 'No description' }}
                  </div>
                </div>
                <div class="program-name d-flex" style="align-items: center">
                  <strong class="text-muted text-truncate mr-3" *ngIf="episode.groupName">
                    {{ episode.groupName }}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="panel-footer mb-2 d-flex justify-content-end">
    <div *ngIf="isProgramPage" ngbDropdown class="btn-group mr-2 p-1">
      <button class="btn btn-att py-0 px-2" ngbDropdownToggle>
        {{ 'vls.programs.form.buttons.createProgram' | translate }}
      </button>
      <div class="dropdown-menu" ngbDropdownMenu>
        <button
          *ngFor="let rsType of programTypes"
          class="dropdown-item"
          (click)="onTypeClick(rsType)"
        >
          {{ 'vls.programs.programType.' + rsType.code | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
